import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

// Custom styled LinearProgress
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D879A5",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "rgb(114, 31, 75)",
  },
}));

const CustomizedProgressBars = ({ isMobile }) => {
  const state = useSelector((state) => state);
  const { currentRoute } = state;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const newProgress = currentRoute
      .map((route) => route.progress)
      .reduce((prev, next) => prev + next, 0);
    setProgress(newProgress);
  }, [currentRoute]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: isMobile ? "5%" : "1%",
        width: "85%",
      }}
    >
      <Box sx={{ width: isMobile ? "50%" : "20%", display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <BorderLinearProgress variant="determinate" value={progress} />
        </Box>
        <Box
          sx={{
            minWidth: 35,
            backgroundColor: "rgb(114, 31, 75)",
            padding: "2px 8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 5,
          }}
        >
          <Typography variant="body2" style={{ color: "white", fontWeight: "bold" }}>
            {`${Math.round(progress)}%`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomizedProgressBars;
