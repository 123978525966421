import React from "react";
import { Container, Tooltip, Typography, Grid } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Footer from "../FooterComponent/Footer";

const Articles = ({ isMobile }) => {
  const navigate = useNavigate();

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 2 } }, // Adjust the duration for slow loading
  };

  const handleImgClick = () => {
    navigate("/");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/others/Background.jpg`})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={8}>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 1 }}
            >
              <div onClick={handleImgClick} style={{ cursor: "pointer" }}>
                <Tooltip title="Navigate to Main Page" arrow>
                  <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
                    alt="Overlay"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: isMobile ? "110px" : "135px",
                      height: isMobile ? "110px" : "135px",
                      zIndex: 1,
                    }}
                  />
                </Tooltip>
              </div>
              <Typography
                variant={isMobile ? "h3" : "h1"}
                align="center"
                gutterBottom
                sx={{
                  fontWeight: 600,
                  mt: isMobile ? 25 : 10,
                  px: 2,
                }}
              >
                Coming Soon
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
        <Grid container direction="column" justifyContent="flex-end " alignItems="center">
          <div
            style={{
              width: "auto",
              display: "flex",
              justifyContent: "center",
              marginTop: 350,
            }}
          >
            <Footer />
          </div>
          &nbsp;
        </Grid>
      </Container>
    </motion.div>
  );
};

export default Articles;
