/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useRef, useContext } from "react";
import { motion } from "framer-motion";
import { Box, Grid, Tooltip } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../Header";
import AnalysisButton from "./AnalysisButton";
import { setRoutingData, SetRoutingPages, setActiveSteps, setStep } from "../../Redux/rootSlice";
import CustomizedProgressBars from "./CustomizedProgressBars";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { persistor } from "../../Redux/store";
import AuthContext from "../../AuthContext";

const Analysis = ({ isMobile }) => {
  const state = useSelector((state) => state);
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const { step, routingPages } = useSelector((state) => state);
  const scrollbarRef = useRef(null);
  const { setPurge, purge } = useContext(AuthContext);

  useEffect(() => {
    if (scrollbarRef.current) {
      const container = scrollbarRef.current._container;
      if (container) {
        container.scrollTop = 0;
      }
    }
  }, [routingPages]);

  const onBackButtonEvent = useCallback(
    (e) => {
      e.preventDefault();
      if (window.confirm("Are you sure you want to leave the analysis test?")) {
        navigate("/home");
        persistor.purge();
      } else {
        window.history.pushState(null, null, window.location.pathname);
      }
    },
    [navigate],
  );

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [onBackButtonEvent]);

  const handleImgClick = () => {
    if (window.confirm("Are you sure you want to leave the analysis test?")) {
      reduxDispatch(setRoutingData({}));
      reduxDispatch(setActiveSteps(0));
      reduxDispatch(SetRoutingPages(0));
      navigate("/home");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/others/Analysis.jpg)`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
        <Box
          sx={{
            position: "fixed",
            top: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            zIndex: 1,
          }}
        >
          <Header />
        </Box>
        <Tooltip title="Navigate to Home Page" arrow>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
            alt="Overlay"
            onClick={handleImgClick}
            style={{
              cursor: "pointer",
              position: "fixed",
              top: 0,
              left: 0,
              width: isMobile ? "110px" : "135px",
              height: isMobile ? "110px" : "135px",
              zIndex: 3,
            }}
          />
        </Tooltip>
        <div style={{ position: "fixed", top: isMobile ? "8%" : "3%", width: "100%", zIndex: 2 }}>
          <CustomizedProgressBars isMobile={isMobile} />
        </div>
        <div
          style={{
            position: "fixed",
            top: isMobile ? "16%" : "12%",
            width: "100%",
            bottom: 0,
            overflowY: "auto",
          }}
        >
          <PerfectScrollbar ref={scrollbarRef}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Outlet />
              <AnalysisButton isMobile={isMobile} />
            </Grid>
          </PerfectScrollbar>
        </div>
    </motion.div>
  );
};

export default Analysis;
