/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { motion } from "framer-motion";
import mediaData from "./Media.json";
import { Box, Card, CardMedia, Grid, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useScroll } from "../../../ScrollContext";
import Header from "../../Header";
import Footer from "../Footer";

const scrollVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

const MediaList = ({ isMobile }) => {
  const navigate = useNavigate();
  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/others/Background.jpg`})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Header />
      </Box>

      <Tooltip title="Navigate to Home Page" arrow>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
          alt="Company Logo"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: isMobile ? "110px" : "135px",
            height: isMobile ? "110px" : "135px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
      </Tooltip>

      <div
        style={{
          padding: "20px",
          marginTop: isMobile ? "40%" : "12%",
          marginBottom: isMobile ? "15%" : "2%",
        }}
      >
        {mediaData?.map((section, sectionIndex) => (
          <div key={section.id}>
            <Box
              sx={{
                textAlign: "center",
                backgroundColor: "#721F4B",
                color: "white",
                borderRadius: "3px",
                fontWeight: "bold",
                fontSize: isMobile ? "1.5rem" : "2rem",
                padding: 1,
                mb: 2,
              }}
            >
              {section.title}
            </Box>

            {section.items.map((item, itemIndex) => (
              <motion.div
                key={`item-${section.id}-${itemIndex}`}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.3 }}
                variants={scrollVariants}
                transition={{ duration: 0.5 }}
              >
                <Grid
                  container
                  // direction={itemIndex % 2 === 0 ? "row-reverse" : "row"}
                  alignItems="center"
                  justifyContent="center" // remove when description implemented
                  // spacing={0}
                  sx={{ my: 8 }}
                >
                  <Grid item xs={12} sm={8} container justifyContent="center">
                    <Card
                      sx={{
                        borderRadius: "20px",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 1)",
                        height: isMobile ? "auto" : "100%",
                        width: isMobile ? "100%" : "70%",
                        objectFit: "cover",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CardMedia component="img" image={item.imageUrl} alt={item.description} />
                    </Card>
                  </Grid>

                  {/* <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        textAlign: "justify",
                        margin: "0 auto",
                        mx: isMobile ? undefined : 15,
                        fontWeight: 600,
                      }}
                      paragraph
                    >
                      {item.description}
                    </Typography>
                  </Grid> */}
                </Grid>
              </motion.div>
            ))}
          </div>
        ))}
      </div>
      <Footer isMobile={isMobile} />
    </motion.div>
  );
};

export default MediaList;
