import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Grid, Button } from "@mui/material";
import { styled } from "@mui/system";
import { usePDF } from "react-to-pdf";
import logo from "../../assets/images/logo192.png"; // Adjust the path as needed

const Header = styled(Box)({
  backgroundColor: "#8C0430",
  color: "#FFF",
  padding: "12px",
  textAlign: "center",
  width: "100vw",
});

const Footer = styled(Box)({
  backgroundColor: "#8C0430",
  color: "#FFF",
  padding: "8px",
  textAlign: "center",
  fontSize: "0.8rem",
  width: "100vw",
});

const SectionTitle = styled(Typography)({
  color: "#4A0E4E",
  fontWeight: "bold",
  marginBottom: "12px",
  fontSize: "1.2rem",
});

const SubSectionTitle = styled(Typography)({
  color: "#C77D00",
  fontWeight: "bold",
  fontSize: "1rem",
  marginTop: "12px",
  marginBottom: "4px",
});

const DownloadButton = styled(Button)({
  backgroundColor: "#4A0E4E",
  color: "#FFF",
  "&:hover": {
    backgroundColor: "#3A0B3E",
  },
  position: "fixed",
  bottom: "20px",
  left: "20px",
  fontSize: "0.9rem",
  padding: "6px 12px",
});

const ECGAnalysisReport = () => {
  const [reportData, setReportData] = useState(null);
  const { toPDF, targetRef } = usePDF({
    filename: "ecg-analysis-report.pdf",
    page: { format: 'A4' },
    method: "save",
  });

  useEffect(() => {
    const storedReport = localStorage.getItem("ecgAnalysisReport");
    if (storedReport) {
      const parsedReport = JSON.parse(storedReport);
      setReportData(parsedReport);
    }
  }, []);

  if (!reportData) {
    return <Typography>No ECG report data available.</Typography>;
  }

  const { allopathic_analysis, ayurvedic_analysis } = reportData.analysis;
  const { image } = reportData;
  const formatAnalysis = () => {
    return (
      <>
        <Box mb={2}>
          <SectionTitle>Allopathic Analysis</SectionTitle>
          {Object.entries(allopathic_analysis).map(([key, value]) => (
            <Box key={key} mb={1}>
              <SubSectionTitle>{key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}:</SubSectionTitle>
              <Typography 
                variant="body2" 
                sx={{ 
                  wordWrap: 'break-word', 
                  overflowWrap: 'break-word',
                  hyphens: 'auto'
                }}
              >
                {value}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box mb={2}>
          <SectionTitle>Ayurvedic Analysis</SectionTitle>
          <SubSectionTitle>Condition:</SubSectionTitle>
          <Typography 
            variant="body2"
            sx={{ 
              wordWrap: 'break-word', 
              overflowWrap: 'break-word',
              hyphens: 'auto'
            }}
          >
            {ayurvedic_analysis.condition}
          </Typography>
          
          <SubSectionTitle>Dosha Vruddhi (Increase):</SubSectionTitle>
          {Object.entries(ayurvedic_analysis.dosha_vruddhi_increase).map(([dosha, value]) => (
            value && (
              <Typography key={dosha} variant="body2">
                {dosha.charAt(0).toUpperCase() + dosha.slice(1)}: {value}
              </Typography>
            )
          ))}
          
          <SubSectionTitle>Dosha Kshaya (Decrease):</SubSectionTitle>
          {Object.entries(ayurvedic_analysis.dosha_kshaya_decrease).map(([dosha, value]) => (
            value && (
              <Typography key={dosha} variant="body2">
                {dosha.charAt(0).toUpperCase() + dosha.slice(1)}: {value}
              </Typography>
            )
          ))}
        </Box>
      </>
    );
  };

  return (
    <Box
      ref={targetRef}
      sx={{
        width: "100vw",
        minHeight: "100vh",
        backgroundColor: "#FFF9E6",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Header>
        <img src={logo} alt="7D Living Logo" style={{ height: "60px" }} />
        <Typography variant="h5" sx={{ mt: 1, fontWeight: "bold" }}>
          ECG Analysis Report
        </Typography>
      </Header>

      <Box sx={{ flexGrow: 1, width: "100%", p: 3 }}>
        <Paper elevation={3} sx={{ p: 3, backgroundColor: "#FFFAF0", position: "relative", width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <img src={image} alt="ECG" style={{ width: "100%", borderRadius: "8px" }} />
            </Grid>
            <Grid item xs={12} md={8}>
              <Box>
                <SectionTitle>ECG Analysis Results:</SectionTitle>
                {formatAnalysis()}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Footer>
        <Typography>&copy; 2023 7D Living. All rights reserved.</Typography>
      </Footer>

      <DownloadButton onClick={() => toPDF()}>Download PDF</DownloadButton>
    </Box>
  );
};

export default ECGAnalysisReport;
