/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Paper,
  Button,
  Box,
  Tooltip,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Divider,
  IconButton,
  Grid,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import axios from "axios";
import { toast } from "react-toastify";
import { DNA } from "react-loader-spinner";
import { useScroll } from "../../../ScrollContext";
import Header from "../../Header";
import careers from "./Careers.json";
import Footer from "../Footer";

const CareerDetail = ({ isMobile }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { scrollToTop } = useScroll();

  const [selectedCareer, setSelectedCareer] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [applicantName, setApplicantName] = useState("");
  const [applicantPhone, setApplicantPhone] = useState("");
  const [applicantEmail, setApplicantEmail] = useState("");
  const [applicantAddress, setApplicantAddress] = useState("");
  const [applicantInformation, setApplicantInformation] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const fileInputRef = useRef(null);
  const maxFileSize = 20 * 1024 * 1024; // 20 MB in bytes

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const career = careers?.find((c) => c.id === parseInt(id));

  if (!career) {
    return <div>Career not found</div>;
  }

  const handleApplyClick = (career) => {
    setSelectedCareer(career);
    setOpenDialog(true);
  };

  const handleDialogClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;

    setOpenDialog(false);
    setSelectedCareer(null);
    setApplicantName("");
    setApplicantPhone("");
    setApplicantEmail("");
    setApplicantAddress("");
    setApplicantInformation("");
    setFile(null);
  };

  const fileSelected = (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      if (selectedFile.size > maxFileSize) {
        alert("The file is too large. Please upload a file that is 20 MB or smaller.");
        event.target.value = ""; // Clear the file input
        setFile(null);
      } else {
        setFile(selectedFile);
      }
    } else {
      console.error("No file selected");
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCancel = () => {
    setFile("");
  };

  const handleSend = async () => {
    if (!selectedCareer) {
      toast.error("Please select a career field");
      return;
    }

    if (!applicantName) {
      toast.error("Please provide your full name");
      return;
    }

    if (!applicantPhone || applicantPhone.length < 10) {
      toast.error("Please ensure that the mobile number is correct");
      return;
    }

    if (!validateEmail(applicantEmail)) {
      toast.error("Invalid email address. Please check and try again");
      return;
    }

    if (!applicantAddress) {
      toast.error("Please provide your address");
      return;
    }

    if (!applicantInformation) {
      toast.error("Please provide some information about yourself");
      return;
    }

    if (!file) {
      toast.error("Please upload your resume");
      return;
    }

    const formData = new FormData();
    formData.append("resume", file);
    formData.append(
      "userDetail",
      JSON.stringify({
        field: selectedCareer.title,
        name: applicantName,
        phone: applicantPhone,
        email: applicantEmail,
        address: applicantAddress,
        info: applicantInformation,
      }),
    );

    setLoading(true);
    setButtonDisable(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/others/post/careers`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        },
      );

      if (response.status === 200) {
        toast.success("Applied successfully");
        handleDialogClose();
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        switch (status) {
          case 400:
            toast.error("Invalid input. Please check your data");
            break;
          case 404:
            toast.error("The resource could not be found");
            break;
          case 409:
            toast.error("Conflict with existing data");
            break;
          case 500:
            toast.error("Server error. Please try again later");
            break;
          default:
            toast.error("An error occurred. Please try again");
        }
      } else {
        toast.error("An unknown error occurred. Please try again later");
      }
    }

    setLoading(false);
    setButtonDisable(false);
  };

  const renderList = (heading, items) => {
    if (!items || items.length === 0) return null;
    return (
      <>
        <strong>{heading}:</strong>
        <ul>{items && items.map((item, idx) => <li key={idx}>{item}</li>)}</ul>
      </>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/others/Background.jpg`})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Header />
      </Box>

      <Tooltip title="Navigate to Home Page" arrow>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
          alt="Company Logo"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: isMobile ? "110px" : "135px",
            height: isMobile ? "110px" : "135px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
      </Tooltip>

      <Container maxWidth="xl" sx={{ mt: isMobile ? "40%" : "12%", mb: isMobile ? "15%" : "2%" }}>
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "#721F4B",
            color: "white",
            borderRadius: "3px",
            fontWeight: "bold",
            fontSize: isMobile ? "1.5rem" : "2rem",
            padding: 1,
            mb: 2,
          }}
        >
          {career?.title}
        </Box>

        <Paper
          style={{
            padding: isMobile ? "20px" : "50px",
            borderRadius: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
          }}
        >
          <Typography variant={isMobile ? "subtitle2" : "subtitle1"} sx={{ fontWeight: 600 }}>
            {renderList("Location", [career?.location])}
            {renderList("Experience", [career?.experience])}
            {renderList("Education", [career?.education])}
            {renderList("Project Description", [career?.project_description])}
            {renderList("Responsibilities", career?.responsibilities)}
            {renderList("Skills Must Have", career?.skills_must_have)}
            {renderList("Nice to Have", career?.nice_to_have)}
          </Typography>
          <Tooltip title="Apply for the role" arrow>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleApplyClick(career)}
              size="large"
              sx={{
                background: "#2B2A29",
                borderRadius: "40px",
                fontSize: isMobile ? "1rem" : "1.3rem",
                fontWeight: "bold",
                border: "2px solid white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
            >
              Apply
            </Button>
          </Tooltip>
        </Paper>
        <Footer isMobile={isMobile} />
      </Container>

      <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="md">
        <DialogTitle sx={{ fontSize: isMobile ? "1rem" : "1.2rem", fontWeight: "bold" }}>
          Apply for {selectedCareer?.title}
        </DialogTitle>

        <Divider sx={{ bgcolor: "black" }} />

        <PerfectScrollbar style={{ paddingRight: "14px" }}>
          {loading ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <DNA
                    visible={true}
                    height={isMobile ? "120" : "200"}
                    width={isMobile ? "200" : "250"}
                    color="#2B2A29"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                  />
                </Grid>
              </Grid>
            </motion.div>
          ) : (
            <DialogContent>
              <TextField
                label="Full Name"
                placeholder="Enter Full Name"
                fullWidth
                required
                value={applicantName}
                onChange={(e) =>
                  /^[a-zA-Z ]*$/.test(e.target.value) && setApplicantName(e.target.value)
                }
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    fontWeight: "bold",
                  },
                }}
                InputProps={{
                  style: { fontSize: isMobile ? "0.8rem" : "1rem", fontWeight: 600 },
                }}
                autoComplete="off"
                sx={{
                  my: 1,
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
              />
              <TextField
                label="Phone Number"
                placeholder="Enter Phone Number"
                fullWidth
                required
                value={applicantPhone}
                onChange={(e) =>
                  /^[0-9]{0,10}$/.test(e.target.value) && setApplicantPhone(e.target.value)
                }
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    fontWeight: "bold",
                  },
                }}
                InputProps={{
                  style: { fontSize: isMobile ? "0.8rem" : "1rem", fontWeight: 600 },
                }}
                autoComplete="off"
                sx={{
                  my: 2,
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
              />
              <TextField
                label="Address"
                placeholder="Enter Address"
                fullWidth
                required
                multiline
                minRows={1}
                maxRows={20}
                value={applicantAddress}
                onChange={(e) => setApplicantAddress(e.target.value)}
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    fontWeight: "bold",
                  },
                }}
                InputProps={{
                  style: { fontSize: isMobile ? "0.8rem" : "1rem", fontWeight: 600, minHeight: 20 },
                }}
                autoComplete="off"
                sx={{
                  my: 1,
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
              />
              <TextField
                label="Email"
                placeholder="Enter Email"
                fullWidth
                required
                value={applicantEmail}
                onChange={(e) => setApplicantEmail(e.target.value.toLowerCase().replace(/\s/g, ""))}
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    fontWeight: "bold",
                  },
                }}
                InputProps={{
                  style: { fontSize: isMobile ? "0.8rem" : "1rem", fontWeight: 600 },
                }}
                autoComplete="off"
                sx={{
                  my: 2,
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
              />
              <TextField
                label="Information"
                placeholder="Enter Information"
                fullWidth
                required
                multiline
                minRows={1}
                maxRows={40}
                value={applicantInformation}
                onChange={(e) => setApplicantInformation(e.target.value)}
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    fontWeight: "bold",
                  },
                }}
                InputProps={{
                  style: { fontSize: isMobile ? "0.8rem" : "1rem", fontWeight: 600, minHeight: 20 },
                }}
                autoComplete="off"
                sx={{
                  my: 1,
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
              />

              <Paper elevation={12} sx={{ padding: "20px", mt: 2 }}>
                <Grid container alignItems="center">
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="file/*"
                    style={{ display: "none" }}
                    onChange={fileSelected}
                  />
                  <Button
                    component="label"
                    variant="contained"
                    role={undefined}
                    onClick={handleClick}
                    sx={{ mr: 2, fontWeight: "bold" }}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Resume
                  </Button>
                  {file ? (
                    <Typography
                      variant={isMobile ? "body2" : "body1"}
                      sx={{ mt: isMobile ? 2 : undefined, fontWeight: 600 }}
                    >
                      {file.name}
                    </Typography>
                  ) : (
                    <Typography
                      variant={isMobile ? "body2" : "body1"}
                      fontWeight="600"
                      color="black"
                    >
                      No File Choosen
                    </Typography>
                  )}
                  <Box
                    sx={{
                      marginLeft: "auto",
                      alignSelf: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip title="Remove Selected File" arrow>
                      <IconButton
                        edge="end"
                        aria-label="add"
                        onClick={handleCancel}
                        sx={{ color: "black" }}
                        size="large"
                      >
                        <DeleteIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Paper>
            </DialogContent>
          )}
        </PerfectScrollbar>

        <Divider sx={{ bgcolor: "black" }} />

        <DialogActions>
          <Button
            onClick={handleDialogClose}
            color="error"
            variant="contained"
            endIcon={<CloseIcon />}
            disabled={buttonDisable}
            sx={{ fontWeight: "bold" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSend}
            color="primary"
            variant="contained"
            endIcon={<SendIcon />}
            disabled={buttonDisable}
            sx={{ fontWeight: "bold" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </motion.div>
  );
};

export default CareerDetail;
