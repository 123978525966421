import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography, Tooltip, CardContent, Grid, Card } from "@mui/material";
import { motion } from "framer-motion";
import Header from "../../Header";

const CookiePolicy = ({ isMobile }) => {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/others/Background.jpg`})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Header />
      </Box>

      <Tooltip title="Navigate to Home Page" arrow>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
          alt="Company Logo"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: isMobile ? "110px" : "135px",
            height: isMobile ? "110px" : "135px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
      </Tooltip>

      <Container maxWidth="xl" sx={{ mt: isMobile ? "40%" : "12%", mb: isMobile ? "15%" : "2%" }}>
        <Card sx={{ borderRadius: "20px", backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
          <Grid container>
            <Grid item xs={12}>
              <CardContent sx={{ ml: 3 }}>
                <Typography variant={isMobile ? "h4" : "h2"} sx={{ fontWeight: "bold" }}>
                  Cookie Policy
                </Typography>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  Welcome to the cookie policy of Desi LLM PVT. LTD., a Limited Liability Company
                  (doing business as: 7D Living Platform or Company) having registration credentials
                  as U86901KA2024PTC186441 in Belagavi, Karnataka, India (referred to as "we", "us",
                  or "our").
                </Typography>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  This cookie policy explains how we use cookies and other similar technologies on
                  our website. By using our website, you consent to the use of cookies and other
                  similar technologies as described in this policy. We may update this policy from
                  time to time to reflect changes in our practices and services, so we recommend
                  that you check this page periodically for the latest information.
                </Typography>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  We take your privacy seriously and are committed to protecting your personal data.
                  This cookie policy should be read alongside our privacy policy, which explains how
                  we collect, use, and protect your personal data. If you have any questions about
                  this cookie policy or our privacy policy, please contact us at
                  support@7dliving.com.
                </Typography>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  We respect your privacy and we are committed to ensuring that you are adequately
                  informed on how you can manage your cookies.
                </Typography>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "bold" }}
                >
                  What are Cookies?
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  Cookies are small data files. When you visit a website, the website sends the
                  cookie to your computer. Your computer stores it in a file located inside your web
                  browser.
                </Typography>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  Cookies do not transfer viruses or malware to your computer, because the data in a
                  cookie does not change when it travels back and forth, it has no way to affect how
                  your computer runs. Instead, they act more like logs (i.e. they record user
                  activity and remember tasteful information), and get updated every time you visit
                  a website.
                </Typography>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  We may obtain information about you by accessing cookies sent by our website.
                  Different types of cookies keep track of different activities. For example,
                  session cookies are used only when a person is actively navigating a website. Once
                  you leave the website, the session cookie disappears. For a more detailed list of
                  cookies we use, please see the relevant section below.
                </Typography>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "bold" }}
                >
                  Why are Cookies useful?
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  We use functional cookies to analyze how visitors and clients use our website, as
                  well as track and improve our website’s performance and function. This allows us
                  to provide a high-quality customer experience by quickly identifying and fixing
                  any issues that may arise. For example, we might use cookies to keep track of
                  which website pages are most popular and which method of linking between website
                  pages is most effective.
                </Typography>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  Another use of cookies is to store your login sessions, meaning that when you log
                  in to your account to deposit funds, a “session cookie” is set so that the website
                  remembers that you have already logged in. If the website did not set this cookie,
                  you will be asked for your login details on each new page as you progress through
                  the funding process.
                </Typography>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  In addition, functional cookies, for example, are used to allow us to remember
                  your preferences and identify you as a user, ensure your information is secure and
                  operate more reliably and efficiently. For example, cookies save you the trouble
                  of typing in your username every time you access our trading platform, and recall
                  your preferences.
                </Typography>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  Here are some of the functions our cookies provide us with:
                </Typography>
                <ul>
                  <Typography
                    variant={isMobile ? "subtitle2" : "subtitle1"}
                    sx={{ fontWeight: "600", textAlign: "left", my: 2 }}
                  >
                    <li>
                      Verifying your identity and detecting the country you are currently visiting
                      from;
                    </li>
                  </Typography>
                  <Typography
                    variant={isMobile ? "subtitle2" : "subtitle1"}
                    sx={{ fontWeight: "600", textAlign: "left", my: 2 }}
                  >
                    <li>Checking browser type and device;</li>
                  </Typography>
                  <Typography
                    variant={isMobile ? "subtitle2" : "subtitle1"}
                    sx={{ fontWeight: "600", textAlign: "left", my: 2 }}
                  >
                    <li>Tracking which site the user was referred from.</li>
                  </Typography>
                </ul>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  Types of Cookies we use
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  1. Functional cookies: These cookies are essential for the running of our website.
                  Without these cookies our website would not function properly. These are saved
                  temporarily as login information and expire once the browser is closed.
                </Typography>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  2. Analytical cookies: The information provided by analytical cookies allows us to
                  analyze patterns of visitor and client behavior and we use that information to
                  enhance the overall experience or identify areas of the website which may require
                  maintenance. The information is anonymous (i.e. it cannot be used to identify you
                  and does not contain personal information such as your name and email address),
                  and it is only used for statistical purposes. Behavioral cookies are similar to
                  analytical and remember that you have visited the website and use that information
                  to provide you with content which is tailored to your interests.
                </Typography>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  Manage Cookies
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  You can delete cookies any time you want by using the settings in your web
                  browser. You can also choose to disable cookies from your web browser, but this
                  would mean that our website and other websites that you access may not function
                  properly. If you do this, a potential result is that you may not be able to sign
                  in. Further information on deleting or controlling cookies can be found at
                  www.aboutcookies.org.
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </motion.div>
  );
};

export default CookiePolicy;
