import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step: 0,
  activeSteps: 0,
  routingPages: 0,
  routingData: [],
  questions: [],
  selectedAnswers: {},
  routingIds: {},
  testType: "",
  currentRoute: [],
  nextButton: false,
  topicCode: "",
  routePath: "",
  patientId: "",
};

const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setActiveSteps: (state, action) => {
      state.activeSteps = action.payload;
    },
    SetRoutingPages: (state, action) => {
      state.routingPages = action.payload;
    },
    setRoutingData: (state, action) => {
      state.routingData = action.payload;
    },
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setSelectedAnswers: (state, action) => {
      state.selectedAnswers = action.payload;
    },
    setRoutingIds: (state, action) => {
      state.routingIds = action.payload;
    },
    setTestType: (state, action) => {
      state.testType = action.payload;
    },
    setCurrentRoute: (state, action) => {
      state.currentRoute = action.payload;
    },
    setNextButton: (state, action) => {
      state.nextButton = action.payload;
    },
    setTopicCode: (state, action) => {
      state.topicCode = action.payload;
    },
    setRoutePath: (state, action) => {
      state.routePath = action.payload;
    },
    setPatientId: (state, action) => {
      state.patientId = action.payload;
    },
    resetState: () => initialState,
  },
});

export const {
  setStep,
  setActiveSteps,
  SetRoutingPages,
  setRoutingData,
  setQuestions,
  setSelectedAnswers,
  setRoutingIds,
  setTestType,
  setCurrentRoute,
  setNextButton,
  setTopicCode,
  setRoutePath,
  setPatientId,
  resetState,
} = rootSlice.actions;

export default rootSlice.reducer;
