import React from "react";
import { Grid, Tooltip, IconButton } from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";

const AppointmentTable = ({ appointments, onRescheduleClick, onDeleteClick, isMobile }) => {
  const columns = [
    {
      field: "id",
      headerName: "Sl No",
      width: 90,
      disableColumnMenu: true,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "date",
      headerName: "Date",
      width: isMobile ? 150 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "from",
      headerName: "From",
      width: isMobile ? 150 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "to",
      headerName: "To",
      width: isMobile ? 150 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "doctorName",
      headerName: "Doctor Name",
      width: isMobile ? 150 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      responsive: false,
      headerClassName: "custom-header-cell",
      renderCell: (params) => (
        <>
          <Grid container>
            <Grid item>
              <Tooltip title="Reschedule Appointment" arrow>
                <IconButton aria-label="edit" onClick={() => onRescheduleClick(params.row)}>
                  <ScheduleIcon color="success" fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel Appointment" arrow>
                <IconButton aria-label="delete" onClick={() => onDeleteClick(params.row)}>
                  <DeleteIcon color="error" fontSize="large" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),
    },
  ];

  const rowsWithHandlers =
    appointments &&
    appointments.map((row, index) => ({
      id: index + 1,
      date: row.date,
      from: row.from,
      to: row.to,
      doctorName: row.doctorName,
      appointmentId: row.appointmentId,
    }));

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        rows={rowsWithHandlers}
        columns={columns}
        autoHeight
        sx={{
          backgroundColor: "rgba(255, 255, 255, 1)",
          "& .MuiDataGrid-cell": {
            fontSize: "20px",
            fontWeight: 600,
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "20px",
            fontWeight: "bold",
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5]}
      />
    </div>
  );
};

export default AppointmentTable;
