/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Box, Container, Tooltip, Grid, Card, Button } from "@mui/material";
import { motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-toastify";
import { useScroll } from "../../../ScrollContext";
import Header from "../../Header";
import Footer from "../Footer";

const FeedBack = ({ isMobile }) => {
  const navigate = useNavigate();
  const { scrollToTop } = useScroll();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [feedBack, setFeedBack] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleSubmit = async () => {
    if (!name) {
      toast.error("Please provide your full name");
      return;
    }

    if (!phoneNumber || phoneNumber.length < 10) {
      toast.error("Please ensure that the mobile number is correct");
      return;
    }

    if (!validateEmail(email)) {
      toast.error("Invalid email address. Please check and try again");
      return;
    }

    if (!feedBack) {
      toast.error("Please provide feedback first than click on submit button");
      return;
    }

    const userDetails = {
      fullName: name,
      phoneNumber: phoneNumber,
      email: email,
      feedBack: feedBack,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/others/post/feedback`,
        userDetails,
        {
          withCredentials: true,
        },
      );

      if (response.status === 200) {
        toast.success("Your feedback has been submitted. Thank you!");

        setName("");
        setPhoneNumber("");
        setEmail("");
        setFeedBack("");
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        switch (status) {
          case 400:
            toast.error("Invalid input. Please check your data");
            break;
          case 404:
            toast.error("The resource could not be found");
            break;
          case 409:
            toast.error("Conflict with existing data");
            break;
          case 500:
            toast.error("Server error. Please try again later");
            break;
          default:
            toast.error("An error occurred. Please try again");
        }
      } else {
        toast.error("An unknown error occurred. Please try again later");
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/others/Background.jpg`})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Header />
      </Box>

      <Tooltip title="Navigate to Home Page" arrow>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
          alt="Company Logo"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: isMobile ? "110px" : "135px",
            height: isMobile ? "110px" : "135px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
      </Tooltip>

      <Container maxWidth="xl" sx={{ mt: isMobile ? "40%" : "12%", mb: isMobile ? "15%" : "2%" }}>
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "#721F4B",
            color: "white",
            borderRadius: "3px",
            fontWeight: "bold",
            fontSize: isMobile ? "1.5rem" : "2rem",
            padding: 1,
            mb: 2,
          }}
        >
          IF YOU HAVE ANY FEEDBACK SHARE WITH US !
        </Box>
        <Card
          sx={{
            my: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "20px",
          }}
        >
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={11} sx={{ mt: 2 }}>
              <TextField
                label="Full Name"
                placeholder="Enter Your Full Name"
                fullWidth
                required
                value={name}
                onChange={(e) => /^[a-zA-Z ]*$/.test(e.target.value) && setName(e.target.value)}
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    fontWeight: "bold",
                  },
                }}
                InputProps={{
                  style: { fontSize: isMobile ? "0.8rem" : "1rem", fontWeight: 600 },
                }}
                autoComplete="off"
                sx={{
                  my: 2,
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
              />
              <TextField
                label="Mobile Number"
                placeholder="Enter Your Mobile Number"
                fullWidth
                required
                value={phoneNumber}
                onChange={(e) =>
                  /^[0-9]{0,10}$/.test(e.target.value) && setPhoneNumber(e.target.value)
                }
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    fontWeight: "bold",
                  },
                }}
                InputProps={{
                  style: { fontSize: isMobile ? "0.8rem" : "1rem", fontWeight: 600 },
                }}
                autoComplete="off"
                sx={{
                  my: 2,
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
              />
              <TextField
                label="Email ID"
                placeholder="Enter Your Email ID"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase().replace(/\s/g, ""))}
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    fontWeight: "bold",
                  },
                }}
                InputProps={{
                  style: { fontSize: isMobile ? "0.8rem" : "1rem", fontWeight: 600 },
                }}
                autoComplete="off"
                sx={{
                  my: 2,
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
              />
              <TextField
                label="Feedback"
                placeholder="Enter Your Valuable Feedback"
                fullWidth
                multiline
                required
                minRows={10}
                maxRows={10}
                value={feedBack}
                onChange={(e) => setFeedBack(e.target.value)}
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    fontWeight: "bold",
                  },
                }}
                InputProps={{
                  style: { fontSize: isMobile ? "0.8rem" : "1rem", fontWeight: 600 },
                }}
                autoComplete="off"
                sx={{
                  my: 2,
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={8} sm={4} md={2.5} sx={{ mb: 2 }}>
              <Tooltip title="Submit Feedback" arrow>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  onClick={handleSubmit}
                  style={{
                    background: "#2B2A29",
                    borderRadius: "40px",
                    fontSize: isMobile ? "0.9rem" : "1.2rem",
                    fontWeight: "bold",
                    border: "2px solid white",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                >
                  Submit Feedback
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Card>
        <Footer isMobile={isMobile} />
      </Container>
    </motion.div>
  );
};

export default FeedBack;
