import React from "react";
import { Container, Box, Grid, Link, Typography, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Footer = ({ isMobile }) => {
  const navigate = useNavigate();

  const phoneNumber = "+91 7975827078";
  const instaID = "7dliving";
  const mailID = "contact@7dliving.com";

  return (
    <Container sx={{ mb: isMobile && 3 }}>
      <Box sx={{ py: 4 }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "inherit",
                justifyContent: isMobile ? "center" : "flex-start",
                gap: 2,
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/landingpage/Company-Square-Logo.png`}
                alt="Company Logo"
                style={{ width: "50%", height: "auto" }}
              />
              <Box>
                <Typography
                  fontSize={isMobile ? "1rem" : "1.2rem"}
                  sx={{ mt: 1, fontWeight: "bold" }}
                >
                  BRAND OF DESI LLM PRIVATE LIMITED
                </Typography>
                <Typography fontSize={isMobile ? "0.8rem" : "1rem"} sx={{ mt: 1, fontWeight: 600 }}>
                  CTS NO. 93, KRISHNAI BUILDING, RAGHUVENDRA SOCIETY VINAYAK NAGAR, BELAGAVI,
                  KARNATAKA 591108
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={4} sm={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Link
                component="button"
                underline="hover"
                onClick={() => navigate("/careers")}
                sx={{
                  fontWeight: "bold",
                  fontSize: isMobile ? "1rem" : "1.1rem",
                  color: "black",
                }}
              >
                Careers
              </Link>

              <Link
                component="button"
                underline="hover"
                onClick={() => navigate("/faq")}
                sx={{
                  fontWeight: "bold",
                  fontSize: isMobile ? "1rem" : "1.1rem",
                  color: "black",
                }}
              >
                FAQ
              </Link>

              <Link
                component="button"
                underline="hover"
                onClick={() => navigate("/media")}
                sx={{
                  fontWeight: "bold",
                  fontSize: isMobile ? "1rem" : "1.1rem",
                  color: "black",
                }}
              >
                Media
              </Link>

              <Link
                component="button"
                underline="hover"
                onClick={() => navigate("/feedback")}
                sx={{
                  fontWeight: "bold",
                  fontSize: isMobile ? "1rem" : "1.1rem",
                  color: "black",
                }}
              >
                Feedback
              </Link>
            </Box>
          </Grid>

          <Grid item xs={4} sm={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Link
                component="button"
                underline="hover"
                onClick={() => navigate("/privacypolicy")}
                sx={{
                  fontWeight: "bold",
                  fontSize: isMobile ? "1rem" : "1.1rem",
                  color: "black",
                }}
              >
                Privacy Policy
              </Link>

              <Link
                component="button"
                underline="hover"
                onClick={() => navigate("/termsandconditions")}
                sx={{
                  fontWeight: "bold",
                  fontSize: isMobile ? "1rem" : "1.1rem",

                  color: "black",
                }}
              >
                Terms Of Use
              </Link>

              <Link
                component="button"
                underline="hover"
                onClick={() => navigate("/cookiepolicy")}
                sx={{
                  fontWeight: "bold",
                  fontSize: isMobile ? "1rem" : "1.1rem",
                  color: "black",
                }}
              >
                Cookie Policy
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 3,
              }}
            >
              <Typography fontSize={isMobile ? "1.1rem" : "1.3rem"} sx={{ fontWeight: "bold" }}>
                Follow us on:
              </Typography>

              <Tooltip title="WhatsApp Us" arrow>
                <Link href={`tel:${phoneNumber}`} target="_blank" rel="noopener noreferrer">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/contact/WhatsApp.png`}
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                    }}
                    alt="WhatsApp Icon"
                  />
                </Link>
              </Tooltip>

              <Tooltip title="Follow us on Instagram" arrow>
                <Link
                  href={`https://www.instagram.com/${instaID}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/contact/Instagram.png`}
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                    }}
                    alt="Instagram Icon"
                  />
                </Link>
              </Tooltip>

              <Tooltip title="Email Us" arrow>
                <Link href={`mailto:${mailID}`} target="_blank" rel="noopener noreferrer">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/contact/Email.png`}
                    style={{ width: "50px", height: "50px", objectFit: "cover" }}
                    alt="Email Icon"
                  />
                </Link>
              </Tooltip>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography
              fontSize={isMobile ? "0.8rem" : "1rem"}
              align="center"
              fontWeight="bold"
              color="textSecondary"
            >
              © {new Date().getFullYear()} 7D Living. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Footer;
