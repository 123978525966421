/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Box,
  Container,
  Typography,
  Tooltip,
  CardContent,
  Grid,
  Card,
} from "@mui/material";
import { motion } from "framer-motion";
import { useScroll } from "../../../ScrollContext";
import Header from "../../Header";
import faq from "./FAQ.json";
import Footer from "../Footer";

const FAQSeries = ({ isMobile }) => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [filteredFaq, setFilteredFaq] = useState(faq);

  const { scrollToTop } = useScroll();

  useEffect(() => {
    setFilteredFaq(
      faq?.filter((item) => item.question.toLowerCase().includes(search.toLowerCase())),
    );
    scrollToTop();
  }, [search, faq]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/others/Background.jpg`})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Header />
      </Box>

      <Tooltip title="Navigate to Home Page" arrow>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
          alt="Company Logo"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: isMobile ? "110px" : "135px",
            height: isMobile ? "110px" : "135px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
      </Tooltip>

      <Container maxWidth="xl" sx={{ mt: isMobile ? "40%" : "12%", mb: isMobile ? "15%" : "2%" }}>
        <Card
          sx={{
            my: 2,
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <TextField
            margin="dense"
            type="text"
            label="Search For Question"
            placeholder="Enter Your Question"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputLabelProps={{
              style: {
                color: "black",
                fontSize: isMobile ? "0.7rem" : "0.9rem",
                fontWeight: "bold",
              },
            }}
            InputProps={{
              style: { fontSize: isMobile ? "0.7rem" : "0.9rem", fontWeight: 600 },
            }}
            autoComplete="off"
            sx={{
              mx: 2,
              "& .MuiOutlinedInput-root.Mui-focused": {
                borderColor: "black",
                "& fieldset": {
                  borderColor: "black",
                },
              },
            }}
            fullWidth
          />
        </Card>

        {filteredFaq?.length > 0 ? (
          <>
            <Grid container spacing={3}>
              {filteredFaq?.map((series) => (
                <Grid item xs={12} key={series.id}>
                  <Card sx={{ borderRadius: "20px", backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
                    <CardContent sx={{ ml: 3 }}>
                      <Typography
                        variant={isMobile ? "subtitle1" : "h6"}
                        sx={{ fontWeight: "bold" }}
                      >
                        {series?.question}
                      </Typography>
                      <Typography
                        variant={isMobile ? "subtitle2" : "subtitle1"}
                        sx={{ fontWeight: 600 }}
                      >
                        {series?.answer}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Footer isMobile={isMobile} />
          </>
        ) : (
          <>
            <Typography
              variant={isMobile ? "h5" : "h4"}
              sx={{
                textAlign: "center",
                my: 4,
                fontWeight: "bold",
                color: "#721F4B",
              }}
            >
              FAQs Unavailable for This Question
            </Typography>
            <Footer isMobile={isMobile} />
          </>
        )}
      </Container>
    </motion.div>
  );
};

export default FAQSeries;
