import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { motion } from "framer-motion";
import { CommonPaper } from "../CommonPaper";
import { AiOutlineSchedule } from "react-icons/ai";
import { GrScheduleNew } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

const Treatment = ({ isMobile }) => {
  const navigate = useNavigate();

  const handleAppointmentClick = (appointment) => {
    switch (appointment) {
      case "new":
        navigate("/new-appointment");

        break;
      case "upcoming":
        navigate("/upcoming-appointments");

        break;
      default:
        throw new Error("Invalid routing path");
    }
  };

  return (
    <CommonPaper
      isMobile={isMobile}
      style={{ marginBottom: isMobile ? "20%" : "2%", marginTop: isMobile ? "8%" : "2%" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          variant={isMobile ? "h5" : "h4"}
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          7D TREATMENT JOURNEY
        </Typography>
        <Typography
          variant={isMobile ? "body2" : "body1"}
          sx={{
            textAlign: "center",
            mt: 2,
            fontWeight: 600,
          }}
        >
          EXPERIENCE A TRANSFORMATIVE APPROACH TO HEALING WITH OUR COMPREHENSIVE PATHWAY, DESIGNED
          TO ADDRESS EVERY FACET OF YOUR HEALTH AND WELL-BEING
        </Typography>
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "#721F4B",
            color: "white",
            borderRadius: "3px",
            fontWeight: "bold",
            fontSize: isMobile ? "1.5rem" : "2rem",
            width: isMobile ? "calc(100% + 44px)" : "calc(100% + 84px)",
            margin: isMobile ? "-30px" : "-50px",
            marginTop: "1rem",
            marginBottom: "1rem",
            padding: 1,
          }}
        >
          <Typography
            variant={isMobile ? "h5" : "h4"}
            sx={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            TRACK YOUR TREATMENT PROCESS
          </Typography>
        </Box>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={10} md={8} lg={7}>
            <motion.div transition={{ duration: 0.3 }}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleAppointmentClick("new")}
                sx={{
                  borderRadius: "30px",
                  backgroundImage: "linear-gradient(to right, #700076, #450163)",
                  color: "white",
                  fontSize: isMobile ? "0.9rem" : "1.1rem",
                  padding: isMobile ? "8px 0" : "15px 0",
                  height: "40px",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundImage:
                      "linear-gradient(to right, rgba(112, 0, 118, 0.8), rgba(69, 1, 99, 0.8))",
                  },
                }}
                startIcon={<AiOutlineSchedule size={isMobile ? 24 : 32} />}
              >
                Schedule Appointment
              </Button>
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={10} md={8} lg={7}>
            <motion.div transition={{ duration: 0.3 }}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleAppointmentClick("upcoming")}
                sx={{
                  borderRadius: "30px",
                  backgroundImage: "linear-gradient(to right, #700076, #450163)",
                  color: "white",
                  fontSize: isMobile ? "0.9rem" : "1.1rem",
                  padding: isMobile ? "8px 0" : "15px 0",
                  height: "40px",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundImage:
                      "linear-gradient(to right, rgba(112, 0, 118, 0.8), rgba(69, 1, 99, 0.8))",
                  },
                }}
                startIcon={<GrScheduleNew size={isMobile ? 24 : 32} />}
              >
                Upcoming Appointments
              </Button>
            </motion.div>
          </Grid>
        </Grid>
      </Box>
    </CommonPaper>
  );
};

export default Treatment;
