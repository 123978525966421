import React, { useState, useReducer } from "react";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import FeedbackIcon from "@mui/icons-material/Feedback";
import HistoryIcon from "@mui/icons-material/History";
import NightlifeIcon from "@mui/icons-material/Nightlife";
import MedicalHistory from "./MedicalHistory";
import { useNavigate } from "react-router-dom";
import Complaint from "./Complaint";
import LifestyleInformation from "./LifestyleInformation";
import HealthMetrics from "./HealthMetrics";
import Header from "../Header";
import {
  styled,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  Box,
  Grid,
  Tooltip,
  Paper,
} from "@mui/material";
import { motion } from "framer-motion";
import IntakeFormButton from "./IntakeFormButton";

const CommonPaper = styled(Paper)(({ isMobile }) => ({
  position: "relative",
  maxWidth: isMobile ? "82%" : "60%",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: isMobile ? "30px" : "50px",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
}));

const initialState = {
  height: "",
  weight: "",
  bloodPressure: "",
  heartRate: "",
  complaint: "",
  pastHistory: "",
  pastSurgeries: "",
  chronicIllness: "",
  medicalConditions: "",
  allergies: "",
  occupation: "",
  workEnvironment: "",
  dailyDiet: {
    meals: [],
    dietaryPreferences: "",
  },
  exerciseHabits: {
    type: "",
    frequency: "",
    duration: "",
  },
  sleepPatterns: {
    averageHours: "",
    quality: "",
  },
  substanceUse: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_complaint":
      return { ...state, complaint: action.payload };
    case "set_past_history":
      return { ...state, pastHistory: action.payload };
    case "set_surgeries":
      return { ...state, pastSurgeries: action.payload };
    case "set_illness":
      return { ...state, chronicIllness: action.payload };
    case "set_medical_conditions":
      return { ...state, medicalConditions: action.payload };
    case "set_allergies":
      return { ...state, allergies: action.payload };
    case "set_occupation":
      return { ...state, occupation: action.payload };
    case "set_work_environment":
      return { ...state, workEnvironment: action.payload };
    case "set_daily_diet":
      return {
        ...state,
        dailyDiet: {
          ...state.dailyDiet,
          meals: action.payload,
        },
      };
    case "set_dietary":
      return {
        ...state,
        dailyDiet: {
          ...state.dailyDiet,
          dietaryPreferences: action.payload,
        },
      };
    case "set_excercise_type":
      return {
        ...state,
        exerciseHabits: {
          ...state.exerciseHabits,
          type: action.payload,
        },
      };
    case "set_excercise_frequency":
      return {
        ...state,
        exerciseHabits: {
          ...state.exerciseHabits,
          frequency: action.payload,
        },
      };
    case "set_excercise_duration":
      return {
        ...state,
        exerciseHabits: {
          ...state.exerciseHabits,
          duration: action.payload,
        },
      };
    case "set_average_hours":
      return {
        ...state,
        sleepPatterns: {
          ...state.sleepPatterns,
          averageHours: action.payload,
        },
      };
    case "set_quality_sleep":
      return {
        ...state,
        sleepPatterns: {
          ...state.sleepPatterns,
          quality: action.payload,
        },
      };
    case "set_substance_use":
      return { ...state, substanceUse: action.payload };
    case "set_height":
      return { ...state, height: action.payload };
    case "set_weight":
      return { ...state, weight: action.payload };
    case "set_blood_pressure":
      return { ...state, bloodPressure: action.payload };
    case "set_heart_rate":
      return { ...state, heartRate: action.payload };
    default:
      return state;
  }
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#ccc",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ ownerState }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsAccessibilityIcon />,
    2: <FeedbackIcon />,
    3: <HistoryIcon />,
    4: <NightlifeIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ["Health Metrics", "Complaint", "Medical History", "Lifestyle Information"];
const stepComponents = [HealthMetrics, Complaint, MedicalHistory, LifestyleInformation];

const IntakeForm = ({ isMobile }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [activeStep, setActiveStep] = useState(0);

  const handleImgClick = () => {
    navigate("/home");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/others/Intake-Form.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div onClick={handleImgClick} style={{ cursor: "pointer" }}>
        <Tooltip title="Navigate to Home Page" arrow>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
            alt="Overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: isMobile ? "110px" : "135px",
              height: isMobile ? "110px" : "135px",
              zIndex: 1,
            }}
          />
        </Tooltip>
      </div>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Header />
      </Box>

      <Grid container direction="column" justifyContent="center" alignItems="center">
        <CommonPaper isMobile={isMobile} style={{ marginTop: isMobile ? "40%" : "2%" }}>
          <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  sx={{
                    ".MuiStepLabel-label": {
                      fontSize: isMobile ? "1rem" : "1.4rem",
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </CommonPaper>

        <CommonPaper
          isMobile={isMobile}
          style={{ marginBottom: isMobile ? "20%" : "2%", marginTop: isMobile ? "8%" : "2%" }}
        >
          {React.createElement(stepComponents[activeStep], { isMobile, state, dispatch })}

          <IntakeFormButton
            state={state}
            dispatch={dispatch}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isMobile={isMobile}
          />
        </CommonPaper>
      </Grid>
    </motion.div>
  );
};
export default IntakeForm;
