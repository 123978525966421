import React, { useEffect } from "react";
import { Typography, Grid, Container } from "@mui/material";
import { motion } from "framer-motion";

const MobileView = ({
  state,
  dispatch,
  imgRef1,
  imgRef2,
  imgRef3,
  imgRef4,
  textRef1,
  textRef2,
  textRef3,
  textRef4,
  image1,
  image2,
  image3,
  image4,
}) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isVisible = entry.isIntersecting;
          const alt = entry.target.dataset.alt;

          // Dispatch action based on the alt attribute of the element
          switch (alt) {
            case "Evaluation":
              dispatch({ type: "set_isimage_visible1", payload: isVisible });
              break;
            case "7D Report":
              dispatch({ type: "set_isimage_visible2", payload: isVisible });
              break;
            case "7D Recommendation":
              dispatch({ type: "set_isimage_visible3", payload: isVisible });
              break;
            case "AI Assistance":
              dispatch({ type: "set_isimage_visible4", payload: isVisible });
              break;
            default:
              break;
          }

          // Dispatch action for text visibility
          const textAlt = entry.target.dataset.textAlt;
          switch (textAlt) {
            case "Evaluation":
              dispatch({ type: "set_istext_visible1", payload: isVisible });
              break;
            case "7D Report":
              dispatch({ type: "set_istext_visible2", payload: isVisible });
              break;
            case "7D Recommendation":
              dispatch({ type: "set_istext_visible3", payload: isVisible });
              break;
            case "AI Assistance":
              dispatch({ type: "set_istext_visible4", payload: isVisible });
              break;
            default:
              break;
          }
        });
      },
      { threshold: 0.1 },
    );

    if (imgRef1.current) observer.observe(imgRef1.current);
    if (imgRef2.current) observer.observe(imgRef2.current);
    if (imgRef3.current) observer.observe(imgRef3.current);
    if (imgRef4.current) observer.observe(imgRef4.current);

    if (textRef1.current) observer.observe(textRef1.current);
    if (textRef2.current) observer.observe(textRef2.current);
    if (textRef3.current) observer.observe(textRef3.current);
    if (textRef4.current) observer.observe(textRef4.current);

    return () => observer.disconnect();
  }, [dispatch, imgRef1, imgRef2, imgRef3, imgRef4, textRef1, textRef2, textRef3, textRef4]);

  const animationVariants = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.4 } },
    hidden: { opacity: 0, scale: 0.9, transition: { duration: 0.4 } },
  };
  return (
    <Container>
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
        {/* First Row */}
        <Grid item xs={12} sx={{ mx: 1 }}>
          <motion.div
            ref={imgRef1}
            initial="hidden"
            animate={state.isImgVisible1 ? "visible" : "hidden"}
            variants={animationVariants}
            data-alt="Evaluation" // Set a data attribute for identification
          >
            <img
              src={`${process.env.PUBLIC_URL}${image1}`}
              alt="Evaluation"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 1)",
                border: "2px solid #ccc",
              }}
            />
          </motion.div>
        </Grid>
        <Grid item xs={12} sx={{ mx: 1 }}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
            <motion.div
              ref={textRef1}
              data-text-alt="Evaluation"
              initial="hidden"
              animate={state.isTextVisible1 ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                  my: 2,
                }}
              >
                EVALUATION
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "black",
                  fontWeight: 600,
                  textAlign: "justify",
                  my: 2,
                }}
              >
                WELCOME TO OUR EVALUATION PAGE, WHERE YOU'LL BEGIN A JOURNEY TO EXPLORE YOUR HEALTH,
                GUIDED BY OUR AI BOT. THIS BOT LEVERAGES AYURVEDIC PRINCIPLES TO ASSESS YOUR UNIQUE
                CONSTITUTION AND OFFER PERSONALIZED WELLNESS STRATEGIES. AS YOUR VIRTUAL WELLNESS
                COMPANION, THE AI DELVES INTO AYURVEDIC WISDOM, PROVIDING INSIGHTS AND GUIDING YOU
                TOWARDS HOLISTIC HEALTH. EXPERIENCE THE BLEND OF ANCIENT KNOWLEDGE AND MODERN
                TECHNOLOGY IN THIS ENLIGHTENING EVALUATION PROCESS.
              </Typography>
            </motion.div>
          </Grid>
        </Grid>

        {/* Second Row */}
        <Grid item xs={12} sx={{ mx: 1 }}>
          <motion.div
            ref={imgRef2}
            initial="hidden"
            animate={state.isImgVisible2 ? "visible" : "hidden"}
            variants={animationVariants}
            data-alt="7D Report" // Set a data attribute for identification
          >
            <img
              src={`${process.env.PUBLIC_URL}${image2}`}
              alt="7D Report"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 1)",
                border: "2px solid #ccc",
              }}
            />
          </motion.div>
        </Grid>

        <Grid item xs={12} sx={{ mx: 1 }}>
          <motion.div
            ref={textRef2}
            data-text-alt="7D Report"
            initial="hidden"
            animate={state.isTextVisible2 ? "visible" : "hidden"}
            variants={animationVariants}
          >
            <Typography
              variant="h5"
              sx={{
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
                my: 2,
              }}
            >
              YOUR 7D REPORT
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: "black",
                textAlign: "justify",
                fontWeight: 600,
                my: 2,
              }}
            >
              INTRODUCING OUR INNOVATIVE 7D REPORT, MERGING TRADITIONAL HEALTH ASSESSMENTS WITH
              AYURVEDIC INSIGHTS. OUR AI CONDUCTS THOROUGH ANALYSES ACROSS SEVEN WELL-BEING ASPECTS,
              OFFERING A HOLISTIC VIEW. FROM ASSESSING PHYSICAL CONSTITUTION TO MENTAL AND EMOTIONAL
              BALANCE, EMBARK ON A TRANSFORMATIVE JOURNEY TO PEAK WELLNESS. EXPERIENCE THE FUSION OF
              ANCIENT WISDOM AND MODERN TECHNOLOGY AS THE 7D REPORT PROVIDES PERSONALIZED
              RECOMMENDATIONS FOR NURTURING MIND, BODY, AND SPIRIT. EMBRACE THIS HOLISTIC APPROACH
              FOR ENDURING VITALITY.
            </Typography>
          </motion.div>
        </Grid>

        {/* Third Row */}
        <Grid item xs={12} sx={{ mx: 1 }}>
          <motion.div
            ref={imgRef3}
            initial="hidden"
            animate={state.isImgVisible3 ? "visible" : "hidden"}
            variants={animationVariants}
            data-alt="7D Recommendation" // Set a data attribute for identification
          >
            <img
              src={`${process.env.PUBLIC_URL}${image3}`}
              alt="7D Recommendation"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 1)",
                border: "2px solid #ccc",
              }}
            />
          </motion.div>
        </Grid>
        <Grid item xs={12} sx={{ mx: 1 }}>
          <motion.div
            ref={textRef3}
            data-text-alt="7D Recommendation"
            initial="hidden"
            animate={state.isTextVisible3 ? "visible" : "hidden"}
            variants={animationVariants}
          >
            <Typography
              variant="h5"
              sx={{
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
                my: 2,
              }}
            >
              7D RECOMENDATIONS
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: "black",
                textAlign: "justify",
                fontWeight: 600,
                my: 2,
              }}
            >
              INTRODUCING OUR GROUNDBREAKING 7D REPORT, TRANSCENDING TRADITIONAL HEALTH EVALUATIONS
              WITH AYURVEDIC WISDOM. OUR AI OFFERS COMPREHENSIVE INSIGHTS INTO YOUR HEALTH,
              ANALYZING SEVEN WELL-BEING ASPECTS FROM PHYSICAL CONSTITUTION TO MENTAL STABILITY.
              EMBARK ON A TRANSFORMATIVE JOURNEY TO OPTIMAL WELLNESS, EXPERIENCING THE FUSION OF
              ANCIENT KNOWLEDGE AND MODERN TECHNOLOGY. THE 7D REPORT REVEALS PERSONALIZED STRATEGIES
              FOR NURTURING MIND, BODY, AND SPIRIT. EMBRACE THIS HOLISTIC APPROACH FOR ENDURING
              VITALITY.
            </Typography>
          </motion.div>
        </Grid>

        {/* Fourth Row */}
        <Grid item xs={12} sx={{ mx: 1 }}>
          <motion.div
            ref={imgRef4}
            initial="hidden"
            animate={state.isImgVisible4 ? "visible" : "hidden"}
            variants={animationVariants}
            data-alt="AI Assistance" // Set a data attribute for identification
          >
            <img
              src={`${process.env.PUBLIC_URL}${image4}`}
              alt="AI Assistance"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 1)",
                border: "2px solid #ccc",
              }}
            />
          </motion.div>
        </Grid>
        <Grid item xs={12} sx={{ mx: 1 }}>
          <motion.div
            ref={textRef4}
            data-text-alt="AI Assistance"
            initial="hidden"
            animate={state.isTextVisible4 ? "visible" : "hidden"}
            variants={animationVariants}
          >
            <Typography
              variant="h5"
              sx={{
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
                my: 2,
              }}
            >
              AI ASSISTANCE
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: "black",
                textAlign: "justify",
                fontWeight: 600,
                my: 2,
              }}
            >
              WELCOME TO OUR AI ASSISTANCE HUB, WHERE CUTTING-EDGE TECHNOLOGY MEETS PERSONALIZED
              WELLNESS SUPPORT. OUR AI BOT ACTS AS YOUR DEDICATED GUIDE ON THE PATH TO OPTIMAL
              HEALTH. USING ADVANCED ALGORITHMS TO INTERPRET INSIGHTS FROM YOUR 7D REPORT AND
              RECOMMENDATIONS, WE OFFER REAL-TIME ASSISTANCE AND GUIDANCE. WHETHER YOU SEEK
              CLARIFICATION ON AYURVEDIC PRINCIPLES, ADJUSTMENTS TO YOUR WELLNESS PLAN, OR SIMPLY
              ENCOURAGEMENT, OUR BOT IS HERE. EMBRACE THE CONVENIENCE AND EFFECTIVENESS OF
              AI-POWERED SUPPORT AS YOU EXPLORE HOLISTIC HEALTH COMPLEXITIES, EMPOWERED ON YOUR
              WELLNESS JOURNEY.
            </Typography>
          </motion.div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MobileView;
