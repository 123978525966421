/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { TextField, Typography, CardContent } from "@mui/material";
import { useScroll } from "../../ScrollContext";

const LifestyleInformationForm = ({ isMobile, state, dispatch }) => {
  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleChange = (field, value) => {
    dispatch({ type: field, payload: value });
  };

  const memoizedHandleChange = useMemo(() => handleChange, [dispatch]);

  return (
    <CardContent>
      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.7rem" : "2rem",
          textAlign: "center",
          mb: 3,
        }}
      >
        Lifestyle Information
      </Typography>

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
          mb: 2,
        }}
      >
        1a. Occupation
      </Typography>
      <TextField
        placeholder="Occupation"
        variant="outlined"
        value={state.occupation}
        onChange={(e) => memoizedHandleChange("set_occupation", e.target.value)}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        autoComplete="off"
        sx={{
          "& input": {
            color: "black",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
        fullWidth
      />

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
          mb: 2,
        }}
      >
        1b. Daily Work Environment
      </Typography>
      <TextField
        placeholder="Daily Work Environment"
        variant="outlined"
        multiline
        value={state.workEnvironment}
        onChange={(e) => memoizedHandleChange("set_work_environment", e.target.value)}
        rows={3}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        autoComplete="off"
        sx={{
          "& input": {
            color: "black",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
        fullWidth
      />

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
          mb: 2,
          mt: 3,
        }}
      >
        2a. Meal Times
      </Typography>
      <TextField
        placeholder="Meal Times"
        variant="outlined"
        value={state.dailyDiet?.meals?.join(",") || ""}
        onChange={(e) =>
          dispatch({
            type: "set_daily_diet",
            payload: e.target.value.split(",").map((item) => item.trim()),
          })
        }
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        autoComplete="off"
        sx={{
          "& input": {
            color: "black",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
        fullWidth
      />

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
          mb: 2,
        }}
      >
        2b. Dietary Preferences
      </Typography>
      <TextField
        placeholder="Dietary Preferences"
        variant="outlined"
        multiline
        value={state.dailyDiet?.dietaryPreferences || ""}
        onChange={(e) => memoizedHandleChange("set_dietary", e.target.value)}
        rows={2}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        autoComplete="off"
        sx={{
          "& input": {
            color: "black",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
        fullWidth
      />

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
          mb: 2,
          mt: 3,
        }}
      >
        3a. Exercise Type
      </Typography>
      <TextField
        placeholder="Exercise Type"
        variant="outlined"
        fullWidth
        multiline
        value={state.exerciseHabits?.type || ""}
        onChange={(e) => memoizedHandleChange("set_excercise_type", e.target.value)}
        rows={2}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        autoComplete="off"
        sx={{
          "& input": {
            color: "black",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
      />

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
          mb: 2,
        }}
      >
        3b. Exercise Frequency
      </Typography>
      <TextField
        placeholder="Exercise Frequency"
        variant="outlined"
        fullWidth
        multiline
        value={state.exerciseHabits?.frequency || ""}
        onChange={(e) => memoizedHandleChange("set_excercise_frequency", e.target.value)}
        rows={2}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        autoComplete="off"
        sx={{
          "& input": {
            color: "black",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
      />

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
          mb: 2,
        }}
      >
        3c. Exercise Duration
      </Typography>
      <TextField
        placeholder="Exercise Duration"
        variant="outlined"
        fullWidth
        value={state.exerciseHabits?.duration || ""}
        onChange={(e) => memoizedHandleChange("set_excercise_duration", e.target.value)}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        autoComplete="off"
        sx={{
          "& input": {
            color: "black",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
      />

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
          mb: 2,
          mt: 3,
        }}
      >
        4a. Average Sleep Hours
      </Typography>
      <TextField
        placeholder="Average Sleep Hours"
        variant="outlined"
        fullWidth
        value={state.sleepPatterns?.averageHours || ""}
        onChange={(e) => {
          const newValue = e.target.value.replace(/\D/g, "").slice(0, 2); // Allow up to 2 digits
          dispatch({
            type: "set_average_hours",
            payload: newValue,
          });
        }}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        autoComplete="off"
        sx={{
          "& input": {
            color: "black",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
      />

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
          mb: 2,
        }}
      >
        4b. Quality of Sleep
      </Typography>
      <TextField
        placeholder="Quality of Sleep"
        variant="outlined"
        fullWidth
        value={state.sleepPatterns?.quality || ""}
        onChange={(e) => memoizedHandleChange("set_quality_sleep", e.target.value)}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        autoComplete="off"
        sx={{
          "& input": {
            color: "black",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
      />

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
          mb: 2,
          mt: 3,
        }}
      >
        5. Tobacco, Alcohol or Any Substances Use
      </Typography>
      <TextField
        placeholder="Tobacco, Alcohol or Any Substances Use"
        variant="outlined"
        fullWidth
        multiline
        value={state.substanceUse}
        onChange={(e) => memoizedHandleChange("set_substance_use", e.target.value)}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        autoComplete="off"
        rows={3}
        sx={{
          "& input": {
            color: "black",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
      />
    </CardContent>
  );
};

export default LifestyleInformationForm;
