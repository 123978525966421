import React, { useEffect } from "react";
import { Typography, Grid, Container } from "@mui/material";
import { motion } from "framer-motion";

const PcView = ({
  state,
  dispatch,
  imgRef1,
  imgRef2,
  imgRef3,
  imgRef4,
  textRef1,
  textRef2,
  textRef3,
  textRef4,
  image1,
  image2,
  image3,
  image4,
}) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isVisible = entry.isIntersecting;
          const alt = entry.target.dataset.alt;

          // Determine which state to update based on the image's alt attribute
          switch (alt) {
            case "Evaluation Text" || "Evaluation Img":
              dispatch({ type: "set_isimage_visible1", payload: isVisible });
              dispatch({ type: "set_istext_visible1", payload: isVisible });
              break;
            case "7D Report Text" || "7D Report Img":
              dispatch({ type: "set_isimage_visible2", payload: isVisible });
              dispatch({ type: "set_istext_visible2", payload: isVisible });
              break;
            case "7D Recommendation Text" || "7D Recommendation Img":
              dispatch({ type: "set_isimage_visible3", payload: isVisible });
              dispatch({ type: "set_istext_visible3", payload: isVisible });
              break;
            case "Ai Assistance Text" || "Ai Assistance Img":
              dispatch({ type: "set_isimage_visible4", payload: isVisible });
              dispatch({ type: "set_istext_visible4", payload: isVisible });
              break;
            default:
              break;
          }
        });
      },
      { threshold: 0.1 },
    );

    if (imgRef1.current) observer.observe(imgRef1.current);
    if (imgRef2.current) observer.observe(imgRef2.current);
    if (imgRef3.current) observer.observe(imgRef3.current);
    if (imgRef4.current) observer.observe(imgRef4.current);

    if (textRef1.current) observer.observe(textRef1.current);
    if (textRef2.current) observer.observe(textRef2.current);
    if (textRef3.current) observer.observe(textRef3.current);
    if (textRef4.current) observer.observe(textRef4.current);

    return () => observer.disconnect();
  }, [dispatch, imgRef1, imgRef2, imgRef3, imgRef4, textRef1, textRef2, textRef3, textRef4]);

  const animationVariants = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.3 } },
    hidden: { opacity: 0, scale: 0.9, transition: { duration: 0.3 } },
  };

  return (
    <Container sx={{ mt: 5 }}>
      <Grid container spacing={2} justifyContent="center">
        {/* First Row */}
        <Grid item xs={6} sx={{ my: "auto" }}>
          <Grid container justifyContent="flex-start">
            <motion.div
              ref={imgRef1}
              initial="hidden"
              animate={state.isImgVisible1 ? "visible" : "hidden"}
              variants={animationVariants}
              data-alt="Evaluation Text" // Set a data attribute for identification
            >
              <img
                src={`${process.env.PUBLIC_URL}${image1}`}
                alt="Evaluation Img"
                style={{
                  width: "65%",
                  height: "100%",
                  borderRadius: "10px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 1)",
                  border: "2px solid #ccc",
                }}
              />
            </motion.div>
          </Grid>
        </Grid>

        <Grid item xs={4} sx={{ my: 5 }}>
          <Grid container direction="column" justifyContent="flex-end" alignItems="stretch">
            <motion.div
              ref={textRef1}
              data-alt="Evaluation Text"
              initial="hidden"
              animate={state.isTextVisible1 ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "2.5rem",
                }}
              >
                EVALUATION
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  textAlign: "justify",
                  mt: 2,
                }}
              >
                WELCOME TO OUR EVALUATION PAGE, WHERE YOU'LL BEGIN A JOURNEY TO EXPLORE YOUR HEALTH,
                GUIDED BY OUR AI BOT. THIS BOT LEVERAGES AYURVEDIC PRINCIPLES TO ASSESS YOUR UNIQUE
                CONSTITUTION AND OFFER PERSONALIZED WELLNESS STRATEGIES. AS YOUR VIRTUAL WELLNESS
                COMPANION, THE AI DELVES INTO AYURVEDIC WISDOM, PROVIDING INSIGHTS AND GUIDING YOU
                TOWARDS HOLISTIC HEALTH. EXPERIENCE THE BLEND OF ANCIENT KNOWLEDGE AND MODERN
                TECHNOLOGY IN THIS ENLIGHTENING EVALUATION PROCESS.
              </Typography>
            </motion.div>
          </Grid>
        </Grid>

        {/* Second Row */}
        <Grid item xs={4} sx={{ my: 5 }}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
            <motion.div
              ref={textRef2}
              data-alt="7D Report Text"
              initial="hidden"
              animate={state.isTextVisible2 ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "2.5rem",
                }}
              >
                YOUR 7D REPORT
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  textAlign: "justify",
                  mt: 2,
                }}
              >
                INTRODUCING OUR INNOVATIVE 7D REPORT, MERGING TRADITIONAL HEALTH ASSESSMENTS WITH
                AYURVEDIC INSIGHTS. OUR AI CONDUCTS THOROUGH ANALYSES ACROSS SEVEN WELL-BEING
                ASPECTS, OFFERING A HOLISTIC VIEW. FROM ASSESSING PHYSICAL CONSTITUTION TO MENTAL
                AND EMOTIONAL BALANCE, EMBARK ON A TRANSFORMATIVE JOURNEY TO PEAK WELLNESS.
                EXPERIENCE THE FUSION OF ANCIENT WISDOM AND MODERN TECHNOLOGY AS THE 7D REPORT
                PROVIDES PERSONALIZED RECOMMENDATIONS FOR NURTURING MIND, BODY, AND SPIRIT. EMBRACE
                THIS HOLISTIC APPROACH FOR ENDURING VITALITY.
              </Typography>
            </motion.div>
          </Grid>
        </Grid>

        <Grid item xs={6} sx={{ my: "auto" }}>
          <motion.div
            ref={imgRef2}
            initial="hidden"
            animate={state.isImgVisible2 ? "visible" : "hidden"}
            variants={animationVariants}
            data-alt="7D Report img" // Set a data attribute for identification
          >
            <Grid container justifyContent="flex-end">
              <img
                src={`${process.env.PUBLIC_URL}${image2}`}
                alt="7D Report Img"
                style={{
                  width: "65%",
                  height: "100%",
                  borderRadius: "10px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 1)",
                  border: "2px solid #ccc",
                }}
              />
            </Grid>
          </motion.div>
        </Grid>

        {/* Third Row */}
        <Grid item xs={6} sx={{ my: "auto" }}>
          <Grid container justifyContent="flex-start">
            <motion.div
              ref={imgRef3}
              initial="hidden"
              animate={state.isImgVisible3 ? "visible" : "hidden"}
              variants={animationVariants}
              data-alt="7D Recommendation Img" // Set a data attribute for identification
            >
              <img
                src={`${process.env.PUBLIC_URL}${image3}`}
                alt="7D Recommendation Img"
                style={{
                  width: "65%",
                  height: "100%",
                  borderRadius: "10px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 1)",
                  border: "2px solid #ccc",
                }}
              />
            </motion.div>
          </Grid>
        </Grid>

        <Grid item xs={4} sx={{ my: 0 }}>
          <Grid container direction="column" justifyContent="flex-end" alignItems="stretch">
            <motion.div
              ref={textRef3}
              data-alt="7D Recommendation Text"
              initial="hidden"
              animate={state.isTextVisible3 ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                7D RECOMENDATION
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "1.2rem",
                  textAlign: "justify",
                  fontWeight: 600,
                  mt: 2,
                }}
              >
                INTRODUCING OUR GROUNDBREAKING 7D REPORT, TRANSCENDING TRADITIONAL HEALTH
                EVALUATIONS WITH AYURVEDIC WISDOM. OUR AI OFFERS COMPREHENSIVE INSIGHTS INTO YOUR
                HEALTH, ANALYZING SEVEN WELL-BEING ASPECTS FROM PHYSICAL CONSTITUTION TO MENTAL
                STABILITY. EMBARK ON A TRANSFORMATIVE JOURNEY TO OPTIMAL WELLNESS, EXPERIENCING THE
                FUSION OF ANCIENT KNOWLEDGE AND MODERN TECHNOLOGY. THE 7D REPORT REVEALS
                PERSONALIZED STRATEGIES FOR NURTURING MIND, BODY, AND SPIRIT. EMBRACE THIS HOLISTIC
                APPROACH FOR ENDURING VITALITY AND WELL-BEING.
              </Typography>
            </motion.div>
          </Grid>
        </Grid>

        {/* Fourth Row */}
        <Grid item xs={4} sx={{ my: 5 }}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
            <motion.div
              ref={textRef4}
              data-alt="Ai Assistance Text"
              initial="hidden"
              animate={state.isTextVisible4 ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                AI ASSISTANCE
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "1.2rem",
                  textAlign: "justify",
                  fontWeight: 600,
                  mt: 2,
                }}
              >
                WELCOME TO OUR AI ASSISTANCE HUB, WHERE CUTTING-EDGE TECHNOLOGY MEETS PERSONALIZED
                WELLNESS SUPPORT. OUR AI BOT ACTS AS YOUR DEDICATED GUIDE ON THE PATH TO OPTIMAL
                HEALTH. USING ADVANCED ALGORITHMS TO INTERPRET INSIGHTS FROM YOUR 7D REPORT AND
                RECOMMENDATIONS, WE OFFER REAL-TIME ASSISTANCE AND GUIDANCE. WHETHER YOU SEEK
                CLARIFICATION ON AYURVEDIC PRINCIPLES, ADJUSTMENTS TO YOUR WELLNESS PLAN, OR SIMPLY
                ENCOURAGEMENT, OUR BOT IS HERE. EMBRACE THE CONVENIENCE AND EFFECTIVENESS OF
                AI-POWERED SUPPORT AS YOU EXPLORE HOLISTIC HEALTH COMPLEXITIES, EMPOWERED ON YOUR
                WELLNESS JOURNEY.
              </Typography>
            </motion.div>
          </Grid>
        </Grid>

        <Grid item xs={6} sx={{ my: "auto" }}>
          <motion.div
            ref={imgRef4}
            initial="hidden"
            animate={state.isImgVisible4 ? "visible" : "hidden"}
            variants={animationVariants}
            data-alt="Ai Assistance Img"
          >
            <Grid container justifyContent="flex-end">
              <img
                src={`${process.env.PUBLIC_URL}${image4}`}
                alt="Ai Assistance Img"
                style={{
                  maxWidth: "65%",
                  height: "100%",
                  borderRadius: "10px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 1)",
                  border: "2px solid #ccc",
                }}
              />
            </Grid>
          </motion.div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PcView;
