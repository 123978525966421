/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useRef } from "react";
import { Paper, IconButton, Box, Tooltip } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { Message } from "@mui/icons-material";
import Header from "./Header";
import Footer from "./Footer";
import { MessageBox } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useAuth } from "../../AuthContext";
import { RotatingLines } from "react-loader-spinner";
import "./ChatBot.css";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  isOpen: false,
  userText: "",
  aiText: "",
  messages: [
    {
      sender: "ai",
      text: "Hello, I'm Sanskriti, your AI assistant. How can I assist you today? I'm here to answer your questions and help with any queries you have.",
    },
  ],
  isTyping: false,
  userGender: "",
  userName: "",
  isListening: false,
  sessionId: uuidv4(),
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_is_open":
      return { ...state, isOpen: action.payload };
    case "set_user_text":
      return { ...state, userText: action.payload };
    case "set_ai_text":
      return { ...state, aiText: action.payload };
    case "set_messages":
      return { ...state, messages: action.payload };
    case "set_typing":
      return { ...state, isTyping: action.payload };
    case "set_user_gender":
      return { ...state, userGender: action.payload };
    case "set_user_name":
      return { ...state, userName: action.payload };
    case "set_is_listening":
      return { ...state, isListening: action.payload };
    case "set_session_id":
      return { ...state, sessionId: action.payload };
    default:
      return state;
  }
};

const ChatBot = ({ promptText, sessionType, isMobile }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();
  const scrollRef = useRef(null);

  useEffect(() => {
    if (user && state.isOpen) {
      if (user.userGender) {
        // const userGender = user.userGender.charAt(0).toUpperCase() + user.userGender.slice(1);

        dispatch({
          type: "set_user_gender",
          payload: user.userGender,
        });
      } else {
        dispatch({
          type: "set_user_gender",
          payload: "Male",
        });
      }

      if (user.userName) {
        // const userName = user.userName
        //   .split(" ")
        //   .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        //   .join(" ");

        dispatch({
          type: "set_user_name",
          payload: user.userName,
        });
      } else {
        dispatch({
          type: "set_user_name",
          payload: "yOU",
        });
      }
    } else {
      dispatch({
        type: "set_user_gender",
        payload: "Male",
      });

      dispatch({
        type: "set_user_name",
        payload: "You",
      });
    }
  }, [user, state.isOpen]);

  useEffect(() => {
    const handleScrollToBottom = () => {
      if (scrollRef.current) {
        const ps = scrollRef.current;
        if (ps && ps.scrollBottom) {
          ps.scrollBottom();
        } else if (ps && ps._container) {
          ps._container.scrollTop = ps._container.scrollHeight;
        }
      }
    };

    handleScrollToBottom();
  }, [state.messages]); // Depend on messages for triggering bottom scroll

  const toggleChatBot = () => {
    if (state.isOpen === true) {
      dispatch({
        type: "set_user_text",
        payload: "",
      });

      dispatch({
        type: "set_ai_text",
        payload: "",
      });

      dispatch({
        type: "set_session_id",
        payload: uuidv4(),
      });

      dispatch({
        type: "set_messages",
        payload: [
          {
            sender: "ai",
            text: "Hello, I'm Sanskriti, your AI assistant. How can I assist you today? I'm here to answer your questions and help with any queries you have.",
          },
        ],
      });
    }
    dispatch({
      type: "set_is_open",
      payload: !state.isOpen,
    });

    dispatch({
      type: "set_is_listening",
      payload: false,
    });
  };

  return (
    <div style={{ zIndex: 1 }}>
      <Tooltip title="Chat Bot" arrow>
        <IconButton
          onClick={toggleChatBot}
          style={{ position: "fixed", bottom: 16, right: 50, zIndex: 1 }}
        >
          <Message sx={{ color: "white", fontSize: 40 }} />
        </IconButton>
      </Tooltip>

      <AnimatePresence>
        {state.isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                width: isMobile ? "80%" : "40%",
                height: isMobile ? "65%" : "80%",
                position: "fixed",
                bottom: 16,
                right: 16,
                zIndex: 1,
                fontSize: "1rem",
              }}
            >
              <Box
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                }}
              >
                <Header state={state} dispatch={dispatch} isMobile={isMobile} />
              </Box>

              <Box
                sx={{
                  flex: 1,
                  overflowY: "auto",
                  my: 2,
                }}
              >
                <PerfectScrollbar
                  ref={scrollRef}
                  options={{ wheelPropagation: false }}
                  style={{ paddingRight: "7px" }}
                >
                  {state.messages &&
                    state.messages.map((message, index) => (
                      <MessageBox
                        key={index}
                        title={message.sender === "ai" ? "Sanskriti (AI)" : state.userName}
                        position={message.sender === "ai" ? "left" : "right"}
                        type="text"
                        text={message.text}
                        animateTyping
                        avatar={
                          message.sender === "ai"
                            ? `${process.env.PUBLIC_URL}/images/Sanskriti.png`
                            : `${process.env.PUBLIC_URL}/images/${state.userGender}.png`
                        }
                      />
                    ))}
                  {state.isTyping && (
                    <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
                      <RotatingLines
                        visible={true}
                        height="50"
                        width="50"
                        color="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  )}
                </PerfectScrollbar>
              </Box>

              <Box
                sx={{
                  position: "sticky",
                  bottom: 0,
                  zIndex: 2,
                }}
              >
                <Footer
                  state={state}
                  dispatch={dispatch}
                  promptText={promptText}
                  sessionType={sessionType}
                  isMobile={isMobile}
                />
              </Box>
            </Paper>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ChatBot;
