/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Paper,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { SaveAs } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { useAuth } from "../../../AuthContext";
import { DNA } from "react-loader-spinner";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { motion } from "framer-motion";

const theme = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem", // Change this to your desired font size
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          fontSize: "1.2rem", // Adjust month/year label size
        },
      },
    },
    MuiPickersToolbarText: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem", // Adjust toolbar text size
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1.2rem", // Default font size for buttons
          fontWeight: "bold",
        },
        textPrimary: {
          color: "#721F4B", // Color for primary text buttons
        },
      },
    },
  },
});

const RescheduleDialog = ({
  isMobile,
  date,
  setDate,
  isOpen,
  setIsOpen,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  fetchAppointments,
  availableSlots,
  setAvailableSlots,
  nonAvailableSlots,
  setNonAvailableSlots,
  selectedAppointment,
  setSelectedAppointment,
}) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [showLoading, setShowLoading] = useState(false);

  const fetchAvailableSlots = async () => {
    try {
      if (date) {
        setShowLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/appointment/get/available/slots/${date.format("YYYY-MM-DD")}`,
          { withCredentials: true },
        );

        if (response && response.status === 200 && response.data && response.data.data[0]) {
          setAvailableSlots(response.data.data);
          setSelectedTime(response.data.data[0]);
        } else {
          setSelectedTime("");
          setAvailableSlots([]);
        }
        setShowLoading(false);
      }
    } catch (error) {
      console.error("Error fetching time slots", error);

      if (error && error.response && [400, 404, 409, 500].includes(error.response.status)) {
        toast.error("Unfortunately, no time slots are available for the selected date");
      } else {
        toast.error("There was a problem. Please select an alternative date");
      }
      setSelectedTime("");
      setAvailableSlots([]);
      setShowLoading(false);
    }
  };

  useEffect(() => {
    fetchAvailableSlots();
  }, [date]);

  const fetchNonAvailableSlots = async () => {
    try {
      setLoading(true);
      if (selectedDate) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/appointment/get/non-available/slots/${selectedDate}`,
          { withCredentials: true },
        );

        if (response && response.status === 200 && response.data && response.data.data[0]) {
          setNonAvailableSlots(response.data.data);
        } else {
          setNonAvailableSlots([]);
        }
        setLoading(false);
      }
    } catch (error) {
      if (error && error.response && [400, 404, 409, 500].includes(error.response.status)) {
        toast.error("An error occurred while retrieving unavailable time slots");
      } else {
        console.error("Error fetching non available time slots", error);
        toast.error("An error occurred while retrieving unavailable time slots");
      }
      setNonAvailableSlots([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNonAvailableSlots();
  }, [selectedDate]);

  const handleClose = () => {
    setDate("");
    setIsOpen(false);
    setSelectedAppointment("");
    setSelectedDate("");
    setSelectedTime("");
    setAvailableSlots([]);
    setNonAvailableSlots([]);
  };

  const handleTimeSelection = (time) => {
    setSelectedTime(time);
  };

  const handleReschedule = async () => {
    try {
      if (!date || !selectedTime || !selectedAppointment) {
        toast.error("Ensure you've chosen a date and time from the calendar before proceeding");
        return;
      }

      const rescheduleData = {
        oldDate: selectedAppointment.date,
        oldTime: selectedAppointment.from,
        newDate: date.format("YYYY-MM-DD"),
        newTime: selectedTime,
        appointmentId: selectedAppointment.appointmentId,
      };

      setShowLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/appointment/post/reschedule/patient/${user.userId}`,
        rescheduleData,
        { withCredentials: true },
      );

      if (response && response.status === 200) {
        setShowLoading(false);

        handleClose();
        setSelectedAppointment(null);

        toast.success("Appointment rescheduled successfully!");
        fetchAppointments();
      } else {
        setShowLoading(false);
      }
    } catch (error) {
      if (error && error.response && [400, 404, 409, 500].includes(error.response.status)) {
        toast.error("An error occurred while rescheduling, Please try again after some time");
      }
      setShowLoading(false);
      console.error("Error Rescheduling", error);
    }
  };

  const handleMonthChange = (date) => {
    setSelectedDate(date.format("YYYY-MM-DD"));
  };

  const handleOpen = () => {
    if (!selectedDate) {
      const currentDate = new Date();

      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");

      setSelectedDate(`${year}-${month}-${day}`);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          fontSize: isMobile ? "1.3rem" : "1.5rem",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Choose a new date from the calendar below to reschedule your journey towards Ayurvedic
        wellness!
      </DialogTitle>

      <Divider sx={{ bgcolor: "black" }} />
      <PerfectScrollbar options={{ wheelPropagation: false }}>
        <Grid container direction="column" alignItems="center">
          <DialogContent sx={{ overflow: "hidden", textAlign: "center" }}>
            {showLoading ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item>
                    <DNA
                      visible={true}
                      height={isMobile ? "120" : "200"}
                      width={isMobile ? "200" : "250"}
                      color="#2B2A29"
                      ariaLabel="dna-loading"
                      wrapperStyle={{}}
                      wrapperClass="dna-wrapper"
                    />
                  </Grid>
                </Grid>
              </motion.div>
            ) : (
              <>
                <ThemeProvider theme={theme}>
                  <Grid container direction="column" alignItems="center" sx={{ p: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <MobileDatePicker
                          value={date}
                          onAccept={(date) => {
                            setDate(date);
                          }}
                          onOpen={handleOpen}
                          onMonthChange={handleMonthChange}
                          shouldDisableDate={(dateParam) => {
                            return nonAvailableSlots.includes(dateParam?.format("YYYY-MM-DD"));
                          }}
                          loading={loading}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                </ThemeProvider>
                {date && availableSlots && availableSlots.length === 0 ? (
                  <Typography variant={isMobile ? "h5" : "h6"} color="error" sx={{ my: 2 }}>
                    Unfortunately, no time slots are available for the selected date.
                  </Typography>
                ) : (
                  <Grid container spacing={2} justifyContent="center" textAlign="center">
                    {availableSlots &&
                      availableSlots.length > 0 &&
                      availableSlots.map((timeOption, index) => (
                        <Grid item key={index} sx={{ my: 2 }}>
                          <Paper
                            elevation={12}
                            variant="elevation"
                            sx={{ border: "2px solid black", borderRadius: "6px" }}
                          >
                            <Button
                              onClick={() => handleTimeSelection(timeOption)}
                              sx={{
                                borderRadius: "5px",
                                backgroundColor:
                                  selectedTime === timeOption ? "#721F4B" : "inherit",
                                color: selectedTime === timeOption ? "white" : "inherit",
                                "&:hover": {
                                  backgroundColor: "#8C3C78",
                                  color: "white",
                                },
                              }}
                              size="large"
                            >
                              {timeOption}
                            </Button>
                          </Paper>
                        </Grid>
                      ))}
                  </Grid>
                )}
              </>
            )}
            {availableSlots && availableSlots.length > 0 && (
              <Link
                component="button"
                onClick={() => {
                  window.open(`${process.env.REACT_APP_TNC_API_URL}/termsandconditions`, "_blank");
                }}
                sx={{
                  cursor: "pointer",
                  color: "black",
                  textDecoration: "underline",
                  fontSize: "0.7rem",
                  fontWeight: "600",
                }}
              >
                *Terms & Conditions Apply
              </Link>
            )}
          </DialogContent>
        </Grid>
      </PerfectScrollbar>

      <Divider sx={{ bgcolor: "black" }} />
      <DialogActions>
        <Button
          onClick={handleReschedule}
          sx={{
            backgroundColor: "#72225e",
            fontWeight: "bold",
            ":hover": {
              backgroundColor: "#8C3C78",
            },
          }}
          variant="contained"
          startIcon={<SaveAs />}
          disabled={showLoading}
        >
          ReSchedule
        </Button>
        <Button
          onClick={handleClose}
          color="error"
          variant="contained"
          disabled={showLoading}
          startIcon={<CloseIcon />}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RescheduleDialog;
