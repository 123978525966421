import React from "react";
import { Container, Tooltip, Typography, Grid } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Footer from "../FooterComponent/Footer";

const AboutUs = ({ isMobile }) => {
  const navigate = useNavigate();

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 2 } },
  };

  const handleImgClick = () => {
    navigate("/");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/others/Background.jpg`})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={8}>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 1 }}
            >
              <div onClick={handleImgClick} style={{ cursor: "pointer" }}>
                <Tooltip title="Navigate to Main Page" arrow>
                  <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
                    alt="Overlay"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: isMobile ? "110px" : "135px",
                      height: isMobile ? "110px" : "135px",
                      zIndex: 1,
                    }}
                  />
                </Tooltip>
              </div>
              <Typography
                variant={isMobile ? "h5" : "h2"}
                align="center"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  mt: isMobile ? 15 : 10,
                  px: 2,
                }}
              >
                ABOUT 7D
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontSize: isMobile ? "1rem" : "1.4rem",
                  textAlign: "justify",
                  margin: "0 auto",
                  fontWeight: 600,
                }}
                paragraph
              >
                Welcome to 7D Living, where the ancient wisdom of Ayurveda converges with
                cutting-edge AI technology to redefine the pursuit of holistic wellness. At 7D
                Living, we believe in harnessing the power of both tradition and innovation to
                inspire individuals to lead vibrant, balanced lives. Our journey began with a vision
                to bridge the gap between ancient healing practices and modern lifestyles,
                recognizing the profound insights that Ayurveda offers for nurturing mind, body, and
                spirit.
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontSize: isMobile ? "1rem" : "1.4rem",
                  textAlign: "justify",
                  margin: "0 auto",
                  fontWeight: 600,
                  mt: 2,
                }}
                paragraph
              >
                Through meticulous research and development, we've created a revolutionary platform
                that seamlessly integrates Ayurvedic principles with AI-driven analysis. Our
                flagship offering, the 7D Report, provides a comprehensive assessment of your
                holistic health profile, examining seven dimensions of well-being to unveil
                personalized insights and recommendations.
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontSize: isMobile ? "1rem" : "1.4rem",
                  textAlign: "justify",
                  margin: "0 auto",
                  fontWeight: 600,
                  mt: 2,
                }}
                paragraph
              >
                But our mission extends beyond mere assessment we're here to empower you on your
                wellness journey. Our AI Assistance page serves as your dedicated companion,
                offering real-time support and guidance as you navigate the intricacies of Ayurvedic
                living. Whether you're seeking clarification on dosha types, dietary
                recommendations, or lifestyle modifications, our AI Bot is here to assist you every
                step of the way.
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontSize: isMobile ? "1rem" : "1.4rem",
                  textAlign: "justify",
                  margin: "0 auto",
                  fontWeight: 600,
                  mt: 2,
                }}
                paragraph
              >
                At 7D Living, we're committed to fostering a community of individuals who are
                passionate about embracing the principles of Ayurveda and leveraging the latest
                technology to optimize their health and vitality. Join us as we embark on this
                transformative journey towards holistic living where the wisdom of Ayurveda meets
                the limitless potential of AI.
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={8}>
            <motion.img
              src={`${process.env.PUBLIC_URL}/images/landingpage/Yoga.jpg`}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                marginTop: "16px",
                marginBottom: "20px",
                borderRadius: "20px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 1)",
              }}
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 2 }}
            />
            <Typography
              variant={isMobile ? "h5" : "h2"}
              align="center"
              gutterBottom
              sx={{
                fontWeight: "bold",
                mt: isMobile ? 2 : 3,
                px: 2,
              }}
            >
              About Us
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontSize: isMobile ? "1rem" : "1.4rem",
                textAlign: "justify",
                fontWeight: 600,
              }}
              paragraph
            >
              Welcome to Our Story at 7D Living, where the convergence of ancient wisdom and modern
              innovation has shaped our journey towards redefining holistic wellness.
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontSize: isMobile ? "1rem" : "1.4rem",
                textAlign: "justify",
                fontWeight: 600,
              }}
              paragraph
            >
              Our story begins with a deep appreciation for the time-honored principles of Ayurveda
              a holistic system of medicine that originated in ancient India over 5,000 years ago.
              Inspired by the profound insights of Ayurveda, we set out on a mission to unlock its
              transformative potential for today's world.
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontSize: isMobile ? "1rem" : "1.4rem",
                textAlign: "justify",
                fontWeight: 600,
              }}
              paragraph
            >
              Driven by a shared passion for health and well-being, our team embarked on a journey
              of exploration and discovery. We immersed ourselves in the rich tapestry of Ayurvedic
              teachings, studying its principles, and witnessing its profound impact on individuals
              seeking harmony and balance in their lives.
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontSize: isMobile ? "1rem" : "1.4rem",
                textAlign: "justify",
                fontWeight: 600,
              }}
              paragraph
            >
              Yet, as we delved deeper into the realm of Ayurveda, we recognized a crucial
              opportunity to marry tradition with technology to harness the power of AI to make the
              timeless wisdom of Ayurveda accessible and actionable for modern audiences.
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontSize: isMobile ? "1rem" : "1.4rem",
                textAlign: "justify",
                fontWeight: 600,
              }}
              paragraph
            >
              Thus, 7D Living was born a pioneering platform that seamlessly integrates Ayurvedic
              wisdom with state-of-the-art AI technology. Our vision was to create a space where
              individuals could embark on a holistic wellness journey, guided by personalized
              insights and supported by cutting-edge assistance.
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontSize: isMobile ? "1rem" : "1.4rem",
                textAlign: "justify",
                fontWeight: 600,
              }}
              paragraph
            >
              With dedication and innovation, we developed the 7D Report a groundbreaking assessment
              tool that examines seven dimensions of well-being through the lens of Ayurveda. This
              comprehensive analysis provides individuals with a holistic understanding of their
              health profile, empowering them to make informed choices that nurture their mind,
              body, and spirit.
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontSize: isMobile ? "1rem" : "1.4rem",
                textAlign: "justify",
                fontWeight: 600,
              }}
              paragraph
            >
              But our journey doesn't end with assessment it's just the beginning. We've cultivated
              a vibrant community of individuals who share our passion for holistic living and are
              committed to supporting one another on the path to wellness.
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontSize: isMobile ? "1rem" : "1.4rem",
                textAlign: "justify",
                fontWeight: 600,
              }}
              paragraph
            >
              Together, we're rewriting the narrative of health and well-being, embracing the
              timeless wisdom of Ayurveda and harnessing the power of AI to create a world where
              vibrant living is not just a possibility but a reality for all.
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontSize: isMobile ? "1rem" : "1.4rem",
                textAlign: "justify",
                fontWeight: 600,
              }}
              paragraph
            >
              Join us as we continue to write our story a story of transformation, empowerment, and
              the endless potential of holistic living. Welcome to 7D Living, where ancient wisdom
              meets modern innovation, and where your journey to holistic wellness begins.
            </Typography>
          </Grid>
        </Grid>
        <div
          style={{
            width: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Footer isMobile={isMobile} />
        </div>
        &nbsp;
      </Container>
    </motion.div>
  );
};

export default AboutUs;
