/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import axios from "axios";
import ReactPlayer from "react-player";
import { DemoPaper } from "./DemoPaper";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";
import { useScroll } from "../../ScrollContext";
import ChatBot from "../ChatBot/ChatBot";
import { useDispatch, useSelector } from "react-redux";
import {
  setEachQuestionIds,
  setActiveFinish,
  setQuestions,
  setNextButton,
} from "../../Redux/rootSlice";
import { useParams } from "react-router-dom";

const SubCategory = ({ isMobile }) => {
  const [subCategoryData, setSubCategoryData] = useState(null);
  const [promptText, setPromptText] = useState("");
  const { subCategoryId } = useParams();
  const { scrollToTop } = useScroll();
  const dispatch = useDispatch();
  const { routingData } = useSelector((state) => state);

  useEffect(() => {
    scrollToTop();
  }, []);

  const fetchData = async () => {
    try {
      dispatch(setNextButton(true)); // Initial dispatch to set the button to true

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/ayurveda-resource/get/selected/subcategory/${subCategoryId}`,
        { withCredentials: true },
      );

      if (response && response.status === 200) {
        setSubCategoryData(response.data.data);
        console.log("subdata", response.data.data);
        let updatePrompt = {};
        updatePrompt.heading = response.data.data.subCategoryHeading;
        updatePrompt.description = response.data.data.subCategoryDescription;
        setPromptText(updatePrompt);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Optionally, set a state to show an error message to the user
    } finally {
      dispatch(setNextButton(false)); // Ensure the button is set to false after operations
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <DemoPaper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {subCategoryData ? (
              <Box>
                <Box
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#721F4B",
                    color: "white",
                    borderRadius: "3px",
                    fontWeight: "bold",
                    fontSize: isMobile ? "1.5rem" : "2rem",
                    padding: 1,
                  }}
                >
                  {subCategoryData.subCategoryHeading}
                </Box>

                {subCategoryData.subCategoryVideoLink && (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: isMobile ? "100%" : "80%",
                        height: isMobile ? "200px" : "300px",
                        borderRadius: "20px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 1)",
                        border: "2px solid #ccc",
                        overflow: "hidden",
                      }}
                    >
                      <ReactPlayer
                        url={subCategoryData.subCategoryVideoLink}
                        width="100%"
                        height="100%"
                      />
                    </Box>
                  </Grid>
                )}

                <Typography
                  sx={{
                    textAlign: "justify",
                    mt: 2,
                    fontWeight: 600,
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    textTransform: "uppercase",
                  }}
                  paragraph
                  dangerouslySetInnerHTML={{
                    __html: subCategoryData.subCategoryDescription,
                  }}
                />
                {subCategoryData.subCategoryImage && (
                  <Grid container justifyContent="center" alignItems="center">
                    <img
                      width={isMobile ? "100%" : "60%"}
                      height={isMobile ? "100%" : "60%"}
                      src={subCategoryData.subCategoryImage}
                      alt="preview"
                      loading="lazy"
                      style={{
                        marginTop: isMobile ? "8px" : "16px",
                        marginBottom: isMobile ? "8px" : "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 1)",
                        border: "2px solid #ccc",
                      }}
                    />
                  </Grid>
                )}
              </Box>
            ) : (
              <motion.div>
                <DNA
                  visible={true}
                  height={isMobile ? "120" : "200"}
                  width={isMobile ? "200" : "250"}
                  color="#2B2A29"
                  ariaLabel="dna-loading"
                  wrapperStyle={{}}
                  wrapperClass="dna-wrapper"
                />
              </motion.div>
            )}
          </Box>
        </DemoPaper>
      </Grid>
      <ChatBot promptText={promptText} sessionType={"info"} isMobile={isMobile} />
    </>
  );
};

export default SubCategory;
