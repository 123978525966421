import { styled, Paper } from "@mui/material";

export const CommonPaper = styled(Paper)(({ isMobile }) => ({
  position: "relative",
  maxWidth: isMobile ? "65%" : "60%",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: isMobile ? "30px" : "50px",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
}));
