/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import {
  Grid,
  Button,
  Box,
  Typography,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Input,
} from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { FaReact } from "react-icons/fa";
import { SiOpenaigym } from "react-icons/si";
import { PiFlowerLotusLight } from "react-icons/pi";
import { CommonPaper } from "../CommonPaper";
import { toast } from "react-toastify";
import { TbReportAnalytics } from "react-icons/tb";
import AuthContext, { useAuth } from "../../AuthContext";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { persistor } from "../../Redux/store";
import {
  SetRoutingPages,
  setActiveSteps,
  setRoutingData,
  setStep,
  setRoutingIds,
  setSelectedAnswers,
  setTestType,
  setCurrentRoute,
  setQuestions,
  resetState,
} from "../../Redux/rootSlice";
import Webcam from "react-webcam";
import { IoMdReverseCamera } from "react-icons/io";
import imageCompression from "browser-image-compression";

const Wellness = ({ isMobile }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const reduxDispatch = useDispatch();
  const state = useSelector((state) => state);
  const { routingData, routingPages, activeSteps, currentRoute } = useSelector((state) => state);

  const [doshaReportEnabled, setDoshaReportEnabled] = useState(false);
  const [physicalReportEnabled, setPhysicalReportEnabled] = useState(false);
  const [mentalReportEnabled, setMentalReportEnabled] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [facingMode, setFacingMode] = useState("user");
  const [capturedImage, setCapturedImage] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const webcamRef = React.useRef(null);
  const fileInputRef = useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [openECGDialog, setOpenECGDialog] = useState(false);
  const [ecgImage, setECGImage] = useState(null);
  const ecgFileInputRef = useRef(null);

  // Reset state and persisted data when the component mounts
  useEffect(() => {
    const resetApplicationState = () => {
      persistor.purge(); // Clear persisted state
      reduxDispatch(resetState()); // Reset Redux store to initial state
    };
    resetApplicationState();
    if (user && user.userId) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/ayurveda-resource/get/analysis/user/exists/${user.userId}`,
          { withCredentials: true },
        )
        .then((response) => {
          if (response && response.status === 200 && response.data && response.data.data) {
            const result = response.data.data;
            setDoshaReportEnabled(result.dosha || false);
            setPhysicalReportEnabled(result.physical || false);
            setMentalReportEnabled(result.mental || false);
          } else {
            setDoshaReportEnabled(false);
            setPhysicalReportEnabled(false);
            setMentalReportEnabled(false);
          }
        })
        .catch((error) => {
          setDoshaReportEnabled(false);
          setPhysicalReportEnabled(false);
          setMentalReportEnabled(false);
          console.error("Error:", error);
        });
    }
  }, [user, reduxDispatch]);

  // Handle viewing reports
  const handleViewReportClick = (reportType) => {
    switch (reportType) {
      case "dosha":
        navigate("/dosha-report");
        break;
      case "physical":
        navigate("/physical-report");
        break;
      case "mental":
        navigate("/mental-report");
        break;
      default:
        throw new Error("Invalid routing path");
    }
  };

  // Fetch step count and routing information
  const fetchStepCount = async (type) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/ayurveda-resource/post/analysis/user/progress-bar/count/${user?.userGender}/${type}`,
        { withCredentials: true },
      );

      if (response && response.status === 200 && response.data && response.data.data) {
        const result = response.data.data;
        reduxDispatch(setStep(result.step));
        reduxDispatch(setRoutingData(result?.routingPath));
        reduxDispatch(setRoutingIds(result?.routingData));
        reduxDispatch(setTestType(type));
        navigate(result?.routingPath[0].path);
        let progressPerct = {
          page: "",
          path: "",
          progress: "",
        };
        progressPerct.page = 0;
        progressPerct.path = result?.routingPath[0].path;
        progressPerct.progress = 0;
        reduxDispatch(setCurrentRoute([...currentRoute, progressPerct]));
      }
    } catch (error) {
      console.error("Error fetching step count:", error);
      toast.error("Failed to fetch step count. Please try again later.");
    }
  };

  const handleECGAnalysis = () => {
    setOpenECGDialog(true);
    setECGImage(null);
  };

  const handleCloseECGDialog = () => {
    setOpenECGDialog(false);
    setECGImage(null);
  };

  const handleECGFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const base64Image = await getBase64(file);
        setECGImage(base64Image);
      } catch (error) {
        console.error("Error processing the ECG image:", error);
        toast.error("Error processing the ECG image. Please try again.");
      }
    }
  };

  const generateECGReport = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/api/analyze/ecg", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ image: ecgImage }),
      });
      const result = await response.json();
      console.log("resultdsdsd", result);
      if (result.success) {
        localStorage.setItem(
          "ecgAnalysisReport",
          JSON.stringify({
            image: ecgImage,
            analysis: result.data.analysis,
            reportUrl: result.data.reportUrl,
          }),
        );
        navigate("/ecg-analysis-report");
      } else {
        toast.error(result.message || "Error analyzing ECG image");
      }
    } catch (error) {
      console.error("Error generating ECG report:", error);
      toast.error("Failed to analyze the ECG image. Please try again.");
    } finally {
      setIsLoading(false);
      handleCloseECGDialog();
    }
  };

  // Handle test start
  const handleTestClick = (testType) => {
    fetchStepCount(testType);
  };

  const handleTongueAnalysis = () => {
    setOpenDialog(true);
    setCapturedImage(null);
    setAnalysisResult(null);
    setShowCamera(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCapturedImage(null);
    setAnalysisResult(null);
    setShowCamera(false);
  };

  const handleOpenCamera = () => {
    setShowCamera(true);
  };

  const captureImage = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setShowCamera(false);
  }, [webcamRef]);

  const deleteImage = () => {
    setCapturedImage(null);
    setAnalysisResult(null);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Compress the image
        const options = {
          maxSizeMB: 1, // Set the maximum file size in MB
          maxWidthOrHeight: 1024, // Set the maximum width or height of the image
          useWebWorker: true, // Use a web worker for better performance
        };
        const compressedFile = await imageCompression(file, options);

        // Convert the compressed file to base64
        const base64Image = await getBase64(compressedFile);
        setCapturedImage(base64Image);
      } catch (error) {
        console.error("Error processing the image:", error);
        toast.error("Error processing the image. Please try again.");
      }
    }
  };

  const handleViewReport = () => {
    navigate("/tongue-analysis-report");
  };

  const generateReport = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/api/analyze/tongue", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ image: capturedImage }),
      });
      const result = await response.json();
      if (result.success) {
        // Store the result in localStorage for the report page
        localStorage.setItem(
          "tongueAnalysisReport",
          JSON.stringify({
            image: capturedImage,
            analysis: result.data.analysis,
            reportUrl: result.data.reportUrl,
          }),
        );
        // Navigate to the report page
        navigate("/tongue-analysis-report");
      } else {
        toast.error(result.message || "Error analyzing image");
      }
    } catch (error) {
      console.error("Error generating report:", error);
      toast.error("Failed to analyze the image. Please try again.");
    } finally {
      setIsLoading(false);
      handleCloseDialog(); // Close the dialog after generating the report
    }
  };

  const handleCloseCamera = () => {
    setShowCamera(false);
  };

  const toggleCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  return (
    <CommonPaper isMobile={isMobile} style={{ marginTop: isMobile ? "40%" : "10%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          variant={isMobile ? "h5" : "h4"}
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          7D WELLNESS TEST
        </Typography>
        <Typography
          variant={isMobile ? "body2" : "body1"}
          sx={{
            textAlign: "center",
            mt: 2,
            fontWeight: 600,
          }}
        >
          DISCOVER YOUR MENTAL HEALTH DOSHA COMPOSITION, PRIORITIZE YOUR MENTAL HEALTH, AND OPTIMIZE
          YOUR PHYSICAL HEALTH WITH OUR COMPREHENSIVE 7D WELLNESS TEST
        </Typography>
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "#721F4B",
            color: "white",
            borderRadius: "3px",
            width: isMobile ? "calc(100% + 44px)" : "calc(100% + 84px)",
            margin: isMobile ? "-30px" : "-50px",
            marginTop: "1rem",
            marginBottom: "1rem",
            padding: 1,
          }}
        >
          <Typography
            variant={isMobile ? "h5" : "h4"}
            sx={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            GET YOUR HEALTH REPORT NOW
          </Typography>
        </Box>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={7} md={6} lg={5}>
            <motion.div transition={{ duration: 0.3 }}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleTestClick("dosha")}
                sx={{
                  borderRadius: "30px",
                  backgroundImage: "linear-gradient(to right, #700076, #450163)",
                  color: "white",
                  fontSize: isMobile ? "0.9rem" : "1.2rem",
                  padding: isMobile ? "8px 0" : "15px 0",
                  height: "40px",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundImage:
                      "linear-gradient(to right, rgba(112, 0, 118, 0.8), rgba(69, 1, 99, 0.8))",
                  },
                }}
                startIcon={<FaReact size={isMobile ? 24 : 32} />}
              >
                Prakriti Analysis
              </Button>
            </motion.div>
            <Box
              sx={{
                textAlign: "center",
                mt: 3,
                cursor: "pointer",
              }}
            >
              <Tooltip title={!doshaReportEnabled && "Please complete the test first"} arrow>
                <div aria-disabled={!doshaReportEnabled}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleViewReportClick("dosha")}
                    disabled={!doshaReportEnabled}
                    sx={{
                      borderRadius: "30px",
                      fontSize: isMobile ? "0.9rem" : "1.0rem",
                      backgroundColor: "rgba(140, 4, 54, 0.8)",
                      height: "40px",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#8C0430",
                      },
                    }}
                    startIcon={<TbReportAnalytics size={isMobile ? 24 : 32} />}
                  >
                    View Report
                  </Button>
                </div>
              </Tooltip>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={7} lg={6}>
            <motion.div transition={{ duration: 0.3 }}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleTestClick("physical")}
                sx={{
                  borderRadius: "30px",
                  height: "40px",
                  fontWeight: "bold",
                  backgroundImage: "linear-gradient(to right, #700076, #450163)",
                  color: "white",
                  fontSize: isMobile ? "0.9rem" : "1.2rem",
                  padding: isMobile ? "8px 0" : "15px 0",
                  "&:hover": {
                    backgroundImage:
                      "linear-gradient(to right, rgba(112, 0, 118, 0.8), rgba(69, 1, 99, 0.8))",
                  },
                }}
                startIcon={<SiOpenaigym size={isMobile ? 24 : 32} />}
              >
                Sarvanga Health Scan
              </Button>
            </motion.div>
            <Box
              sx={{
                textAlign: "center",
                mt: 3,
                cursor: "pointer",
              }}
            >
              <Tooltip title={!physicalReportEnabled && "Please complete the test first"} arrow>
                <div aria-disabled={!physicalReportEnabled}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleViewReportClick("physical")}
                    disabled={!physicalReportEnabled}
                    sx={{
                      borderRadius: "30px",
                      fontSize: isMobile ? "0.9rem" : "1.0rem",
                      backgroundColor: "rgba(140, 4, 54, 0.8)",
                      height: "40px",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#8C0430",
                      },
                    }}
                    startIcon={<TbReportAnalytics size={isMobile ? 24 : 32} />}
                  >
                    View Report
                  </Button>
                </div>
              </Tooltip>
            </Box>
          </Grid>

          <Grid item xs={12} sm={7} md={6} lg={5}>
            <motion.div transition={{ duration: 0.3 }}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleTestClick("mental")}
                sx={{
                  borderRadius: "30px",
                  backgroundImage: "linear-gradient(to right, #700076, #450163)",
                  color: "white",
                  fontSize: isMobile ? "0.9rem" : "1.2rem",
                  padding: isMobile ? "8px 0" : "15px 0",
                  height: "40px",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundImage:
                      "linear-gradient(to right, rgba(112, 0, 118, 0.8), rgba(69, 1, 99, 0.8))",
                  },
                }}
                startIcon={<PiFlowerLotusLight size={isMobile ? 24 : 32} />}
              >
                Mental Health Check
              </Button>
            </motion.div>
            <Box
              sx={{
                textAlign: "center",
                mt: 3,
                cursor: "pointer",
              }}
            >
              <Tooltip title={!mentalReportEnabled && "Please complete the test first"} arrow>
                <div aria-disabled={!mentalReportEnabled}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleViewReportClick("mental")}
                    disabled={!mentalReportEnabled}
                    sx={{
                      borderRadius: "30px",
                      fontSize: isMobile ? "0.9rem" : "1.0rem",
                      backgroundColor: "rgba(140, 4, 54, 0.8)",
                      height: "40px",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#8C0430",
                      },
                    }}
                    startIcon={<TbReportAnalytics size={isMobile ? 24 : 32} />}
                  >
                    View Report
                  </Button>
                </div>
              </Tooltip>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                mt: 3,
                cursor: "pointer",
              }}
            >
              <Tooltip title={"Generate Report by capturing tongue image"} arrow>
                <div>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleTongueAnalysis}
                    sx={{
                      borderRadius: "30px",
                      fontSize: isMobile ? "0.9rem" : "1.0rem",
                      backgroundColor: "rgba(140, 4, 54, 0.8)",
                      height: "40px",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#8C0430",
                      },
                    }}
                    startIcon={<TbReportAnalytics size={isMobile ? 24 : 32} />}
                  >
                    Tongue Analysis
                  </Button>
                </div>
              </Tooltip>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                mt: 3,
                cursor: "pointer",
              }}
            >
              <Tooltip title={"Generate Report by uploading ECG image"} arrow>
                <div>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleECGAnalysis}
                    sx={{
                      borderRadius: "30px",
                      fontSize: isMobile ? "0.9rem" : "1.0rem",
                      backgroundColor: "rgba(140, 4, 54, 0.8)",
                      height: "40px",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#8C0430",
                      },
                    }}
                    startIcon={<TbReportAnalytics size={isMobile ? 24 : 32} />}
                  >
                    ECG Analysis
                  </Button>
                </div>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : capturedImage ? (
            <img
              src={capturedImage}
              alt="captured"
              style={{ width: "100%", borderRadius: "10px" }}
            />
          ) : showCamera ? (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                facingMode: facingMode,
              }}
              style={{ width: "100%", borderRadius: "10px" }}
            />
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "center" }}>
              <Typography variant="h6">Choose an option:</Typography>
              <Button
                variant="contained"
                onClick={handleOpenCamera}
                disabled={isLoading}
                sx={{
                  borderRadius: "30px",
                  backgroundColor: "rgba(140, 4, 54, 0.8)",
                  "&:hover": { backgroundColor: "#8C0430" },
                }}
              >
                Take Photo
              </Button>
              <Input
                type="file"
                inputRef={fileInputRef}
                onChange={handleFileUpload}
                style={{ display: "none" }}
                inputProps={{ accept: "image/*" }}
              />
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={() => fileInputRef.current.click()}
                sx={{
                  borderRadius: "30px",
                  backgroundColor: "rgba(140, 4, 54, 0.8)",
                  "&:hover": { backgroundColor: "#8C0430" },
                }}
              >
                Upload Image
              </Button>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", px: 2, pb: 2 }}>
          {capturedImage ? (
            <>
              <Button
                variant="contained"
                onClick={deleteImage}
                disabled={isLoading}
                sx={{
                  borderRadius: "30px",
                  backgroundColor: "rgba(140, 4, 54, 0.8)",
                  "&:hover": { backgroundColor: "#8C0430" },
                }}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                onClick={generateReport}
                disabled={isLoading}
                sx={{
                  borderRadius: "30px",
                  backgroundColor: "rgba(140, 4, 54, 0.8)",
                  "&:hover": { backgroundColor: "#8C0430" },
                }}
              >
                Generate Report
              </Button>
            </>
          ) : showCamera ? (
            <>
              <Button
                variant="contained"
                onClick={handleCloseCamera}
                sx={{
                  borderRadius: "30px",
                  backgroundColor: "rgba(140, 4, 54, 0.8)",
                  "&:hover": { backgroundColor: "#8C0430" },
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                onClick={captureImage}
                disabled={isLoading}
                sx={{
                  borderRadius: "30px",
                  backgroundColor: "rgba(140, 4, 54, 0.8)",
                  "&:hover": { backgroundColor: "#8C0430" },
                }}
              >
                Capture Image
              </Button>
              <Button
                variant="contained"
                onClick={toggleCamera}
                disabled={isLoading}
                sx={{
                  borderRadius: "30px",
                  backgroundColor: "rgba(140, 4, 54, 0.8)",
                  "&:hover": { backgroundColor: "#8C0430" },
                }}
                startIcon={<IoMdReverseCamera />}
              >
                Switch Camera
              </Button>
            </>
          ) : null}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openECGDialog}
        onClose={handleCloseECGDialog}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : ecgImage ? (
            <img src={ecgImage} alt="ECG" style={{ width: "100%", borderRadius: "10px" }} />
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "center" }}>
              <Typography variant="h6">Upload ECG Image:</Typography>
              <Input
                type="file"
                inputRef={ecgFileInputRef}
                onChange={handleECGFileUpload}
                disabled={isLoading}
                style={{ display: "none" }}
                inputProps={{ accept: "image/*" }}
              />
              <Button
                variant="contained"
                onClick={() => ecgFileInputRef.current.click()}
                disabled={isLoading}
                sx={{
                  borderRadius: "30px",
                  backgroundColor: "rgba(140, 4, 54, 0.8)",
                  "&:hover": { backgroundColor: "#8C0430" },
                }}
              >
                Upload ECG Image
              </Button>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", px: 2, pb: 2 }}>
          {ecgImage && (
            <>
              <Button
                variant="contained"
                onClick={() => setECGImage(null)}
                disabled={isLoading}
                sx={{
                  borderRadius: "30px",
                  backgroundColor: "rgba(140, 4, 54, 0.8)",
                  "&:hover": { backgroundColor: "#8C0430" },
                }}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                onClick={generateECGReport}
                disabled={isLoading}
                sx={{
                  borderRadius: "30px",
                  backgroundColor: "rgba(140, 4, 54, 0.8)",
                  "&:hover": { backgroundColor: "#8C0430" },
                }}
              >
                Generate ECG Report
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </CommonPaper>
  );
};

export default Wellness;
