import React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";

const Header = ({ state, dispatch, isMobile }) => {
  const handleClose = () => {
    dispatch({
      type: "set_is_open",
      payload: false,
    });

    dispatch({
      type: "set_is_listening",
      payload: false,
    });

    dispatch({
      type: "set_session_id",
      payload: uuidv4(),
    });

    dispatch({
      type: "set_user_text",
      payload: "",
    });

    dispatch({
      type: "set_ai_text",
      payload: "",
    });

    dispatch({
      type: "set_messages",
      payload: [
        {
          sender: "ai",
          text: "Hello, I'm Sanskriti, your AI assistant. How can I assist you today? I'm here to answer your questions and help with any queries you have.",
        },
      ],
    });
  };

  return (
    <Box
      sx={{
        position: "relative",
        textAlign: "center",
        backgroundColor: "#860786",
        color: "white",
        borderRadius: "3px",
        fontWeight: "bold",
        fontSize: isMobile ? "1.8rem" : "2rem",
        padding: 1,
      }}
    >
      AI Assistance
      {/* Close button */}
      <Tooltip title="Close" arrow>
        <IconButton
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            top: "50%",
            right: "8px",
            transform: "translateY(-50%)",
            color: "inherit",
          }}
        >
          <Close />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default Header;
