import React from "react";
import Carousel from "framer-motion-carousel";

const CarouselEffect = ({ isMobile }) => {
  const imageSourcesMobile = [
    {
      src: `${process.env.PUBLIC_URL}/images/mobilecarousel/Portrait-Image-1.png`,
      alt: "Portrait1",
      orientation: "Portrait",
    },
    {
      src: `${process.env.PUBLIC_URL}/images/mobilecarousel/Portrait-Image-2.jpg`,
      alt: "Portrait2",
      orientation: "Portrait",
    },
    {
      src: `${process.env.PUBLIC_URL}/images/mobilecarousel/Portrait-Image-3.jpg`,
      alt: "Portrait3",
      orientation: "Portrait",
    },
    {
      src: `${process.env.PUBLIC_URL}/images/mobilecarousel/Portrait-Image-4.jpg`,
      alt: "Portrait4",
      orientation: "Portrait",
    },
    {
      src: `${process.env.PUBLIC_URL}/images/mobilecarousel/Portrait-Image-5.jpg`,
      alt: "Portrait5",
      orientation: "Portrait",
    },
    {
      src: `${process.env.PUBLIC_URL}/images/mobilecarousel/Portrait-Image-6.png`,
      alt: "Portrait6",
      orientation: "Portrait",
    },
    {
      src: `${process.env.PUBLIC_URL}/images/mobilecarousel/Portrait-Image-7.jpg`,
      alt: "Portrait7",
      orientation: "Portrait",
    },
    {
      src: `${process.env.PUBLIC_URL}/images/mobilecarousel/Portrait-Image-8.png`,
      alt: "Portrait8",
      orientation: "Portrait",
    },
  ];

  const imageSourcesPc = [
    {
      src: `${process.env.PUBLIC_URL}/images/pccarousel/Landscape-Image-1.png`,
      alt: "Landscape1",
      orientation: "landscape",
    },
    {
      src: `${process.env.PUBLIC_URL}/images/pccarousel/Landscape-Image-2.jpg`,
      alt: "Landscape2",
      orientation: "landscape",
    },
    {
      src: `${process.env.PUBLIC_URL}/images/pccarousel/Landscape-Image-3.jpg`,
      alt: "Landscape3",
      orientation: "landscape",
    },
    {
      src: `${process.env.PUBLIC_URL}/images/pccarousel/Landscape-Image-4.jpg`,
      alt: "Landscape4",
      orientation: "landscape",
    },
    {
      src: `${process.env.PUBLIC_URL}/images/pccarousel/Landscape-Image-5.jpg`,
      alt: "Landscape5",
      orientation: "landscape",
    },
  ];

  const imageSources = isMobile ? imageSourcesMobile : imageSourcesPc;

  return (
    <Carousel autoPlay={true} interval={3500} pauseOnHover={true}>
      {imageSources.map((image, index) => (
        <div key={index}>
          <img
            src={image.src}
            alt={image.alt}
            style={{
              width: "100%",
              height: isMobile ? "95vh" : "100vh",
              verticalAlign: "bottom",
              objectPosition: isMobile ? "right center" : "center center",
              objectFit: "cover",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 1)",
            }}
          />
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselEffect;
