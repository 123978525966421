/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Paper, Typography, Box, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppointmentTable from "./AppointmentTable";
import RescheduleDialog from "./RescheduleAppointment";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useAuth } from "../../../AuthContext";
import Header from "../../Header";
import { motion } from "framer-motion";
import { DNA } from "react-loader-spinner";

const UpcomingAppointment = ({ isMobile }) => {
  const { user } = useAuth();

  const [date, setDate] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [availableSlots, setAvailableSlots] = useState([]);
  const [nonAvailableSlots, setNonAvailableSlots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAppointment, setSelectedAppointment] = useState("");

  const navigate = useNavigate();

  const fetchAppointments = async () => {
    try {
      setLoading(true);
      if (user?.userId) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/appointment/get/upcoming/patient/${user.userId}`,
          { withCredentials: true },
        );

        if (response && response.status === 200 && response.data && response.data.data[0]) {
          setAppointments(response.data.data);
          setLoading(false);
        } else {
          setAppointments([]);
          setLoading(false);
        }
      }
    } catch (error) {
      if (error && error.response && [400, 404, 409, 500].includes(error.response.status)) {
        toast.error("An error occurred while fetching appointments");
        setLoading(false);
      } else {
        toast.error(
          "Unknown error occurred while fetching the appointment, Please try again after sometime",
        );

        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, [user]);

  const handleRescheduleClick = (appointment) => {
    setSelectedAppointment(appointment);
    setIsDialogOpen(true);
  };

  const handleDeleteAppointment = async (appointment) => {
    setLoading(true);

    try {
      const { date, from, appointmentId } = appointment;
      const config = {
        data: { date, time: from, appointmentId },
        withCredentials: true,
      };

      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/appointment/delete/patient/${user.userId}`,
        config,
      );

      if (response && response.status === 200) {
        toast.success("Appointment Deleted Successfully");
        fetchAppointments();
      } else {
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 400 || status === 404 || status === 409 || status === 500) {
          toast.error("An error occurred while deleting the appointment");
        } else {
          toast.error("Unknown error occurred while deleting the appointment");
        }
      } else {
        toast.error("Network error occurred while deleting the appointment");
      }
      console.error("Error Deleting", error);

      setLoading(false);
    }
  };

  const handleImgClick = () => {
    navigate("/home");
  };

  const handleDeleteClick = (appointment) => {
    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this Appointment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteAppointment(appointment);
      } else {
        // example codes to clear up
      }
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/others/Appointment.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Header />
      </Box>
      <Tooltip title="Navigate to Home Page" arrow>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
          alt="Overlay"
          onClick={handleImgClick}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: isMobile ? "110px" : "135px",
            height: isMobile ? "110px" : "135px",
            zIndex: 1,
            cursor: "pointer",
          }}
        />
      </Tooltip>
      <Paper
        elevation={24}
        style={{
          position: "relative",
          maxWidth: isMobile ? "80%" : "70%",
          width: "100%",
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: isMobile ? "30px" : "50px",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        }}
      >
        <Typography
          variant={isMobile ? "h5" : "h3"}
          gutterBottom
          textAlign="center"
          fontWeight="bold"
          sx={{ mb: 5 }}
        >
          Upcoming Appointments
        </Typography>
        {loading ? (
          <DNA
            visible={true}
            height={isMobile ? "120" : "200"}
            width={isMobile ? "200" : "250"}
            color="#2B2A29"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        ) : appointments && appointments.length === 0 ? (
          <Typography
            variant={isMobile ? "h6" : "h5"}
            sx={{
              textAlign: "center",
              mt: 2,
              fontWeight: "bold",
            }}
          >
            No appointments are scheduled for you.
          </Typography>
        ) : (
          <AppointmentTable
            appointments={appointments}
            onRescheduleClick={handleRescheduleClick}
            onDeleteClick={handleDeleteClick}
            isMobile={isMobile}
          />
        )}
      </Paper>
      <RescheduleDialog
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        date={date}
        setDate={setDate}
        availableSlots={availableSlots}
        setAvailableSlots={setAvailableSlots}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        fetchAppointments={fetchAppointments}
        nonAvailableSlots={nonAvailableSlots}
        setNonAvailableSlots={setNonAvailableSlots}
        loading={loading}
        setLoading={setLoading}
        selectedAppointment={selectedAppointment}
        setSelectedAppointment={setSelectedAppointment}
      />
    </motion.div>
  );
};

export default UpcomingAppointment;
