import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Grid, Button } from "@mui/material";
import { styled } from "@mui/system";
import { usePDF } from "react-to-pdf";
import Swal from "sweetalert2";
import logo from "../../assets/images/logo192.png"; // Adjust the path as needed

const Header = styled(Box)({
  backgroundColor: "#8C0430",
  color: "#FFF",
  padding: "12px",
  textAlign: "center",
  width: "100vw",
});

const Footer = styled(Box)({
  backgroundColor: "#8C0430",
  color: "#FFF",
  padding: "8px",
  textAlign: "center",
  fontSize: "0.8rem",
  width: "100vw",
});

const SectionTitle = styled(Typography)({
  color: "#4A0E4E",
  fontWeight: "bold",
  marginBottom: "8px",
  fontSize: "1.2rem",
});

const SubSectionTitle = styled(Typography)({
  color: "#C77D00",
  fontWeight: "bold",
  fontSize: "1rem",
  marginTop: "16px",
  marginBottom: "8px",
});

const DownloadButton = styled(Button)({
  backgroundColor: "#4A0E4E",
  color: "#FFF",
  "&:hover": {
    backgroundColor: "#3A0B3E",
  },
  position: "fixed",
  bottom: "20px",
  left: "20px",
  fontSize: "0.9rem",
  padding: "6px 12px",
});

const TongueAnalysisReport = () => {
  const [reportData, setReportData] = useState(null);
  const { toPDF, targetRef } = usePDF({
    filename: "tongue-analysis-report.pdf",
    page: { format: "A4" },
    method: "save",
  });

  useEffect(() => {
    const storedReport = localStorage.getItem("tongueAnalysisReport");
    if (storedReport) {
      const parsedReport = JSON.parse(storedReport);
      if (isImageClear(parsedReport.analysis)) {
        setReportData(parsedReport);
      } else {
        Swal.fire({
          title: "Image Not Clear",
          text: "The tongue image is not clear. Please try again with a clearer image.",
          icon: "warning",
          confirmButtonText: "OK",
        }).then(() => {
          // Redirect to the image capture page or handle as needed
          // For example: history.push('/capture-tongue-image');
        });
      }
    }
  }, []);

  const isImageClear = (analysis) => {
    // Check if the analysis contains any indication that the image is not clear
    return (
      !analysis.toLowerCase().includes("image not clear") &&
      !analysis.toLowerCase().includes("unclear image") &&
      !analysis.toLowerCase().includes("poor image quality")
    );
  };

  if (!reportData) {
    return <Typography>No tongue report data available.</Typography>;
  }

  const { image, analysis } = reportData;

  const formatAnalysis = (analysisText) => {
    // Remove the markdown code block syntax
    const jsonString = analysisText.replace(/```json|```/g, '').trim();
    
    try {
      const analysis = JSON.parse(jsonString);
      return (
        <>
          <Box mb={2}>
            <SectionTitle>Attributes</SectionTitle>
            {Object.entries(analysis.attributes).map(([key, value]) => (
              <Box key={key} mb={1}>
                <SubSectionTitle>{key.replace(/_/g, ' ')}:</SubSectionTitle>
                <Typography
                  variant="body1"
                  fontSize="0.9rem"
                  sx={{
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    hyphens: "auto",
                  }}
                >
                  {value}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box mb={2}>
            <SectionTitle>Final Analysis</SectionTitle>
            <Typography
              variant="body1"
              fontSize="0.9rem"
              sx={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
                hyphens: "auto",
              }}
            >
              {analysis.final_analysis}
            </Typography>
          </Box>
        </>
      );
    } catch (error) {
      console.error("Error parsing analysis JSON:", error);
      return <Typography>Error parsing analysis data.</Typography>;
    }
  };

  return (
    <Box
      ref={targetRef}
      sx={{
        width: "100vw",
        minHeight: "100vh",
        backgroundColor: "#FFF9E6",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Header>
        <img src={logo} alt="7D Living Logo" style={{ height: "60px" }} />
        <Typography variant="h5" sx={{ mt: 1, fontWeight: "bold" }}>
          Tongue Analysis Report
        </Typography>
      </Header>

      <Box sx={{ flexGrow: 1, width: "100%", p: 4 }}>
        <Paper
          elevation={3}
          sx={{ p: 4, backgroundColor: "#FFFAF0", position: "relative", width: "100%" }}
        >
          <Grid container spacing={4} p={2}>
            <Grid item xs={12} md={4} p={5}>
              <img src={image} alt="Tongue" style={{ width: "100%", borderRadius: "8px" }} />
            </Grid>
            <Grid item xs={12} md={8} p={5}>
              <Box sx={{ pl: 0 }}>
                <SectionTitle>Tongue Analysis Results:</SectionTitle>
                {formatAnalysis(analysis)}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Footer>
        <Typography>&copy; 2023 7D Living. All rights reserved.</Typography>
      </Footer>

      <DownloadButton onClick={() => toPDF()}>Download PDF</DownloadButton>
    </Box>
  );
};

export default TongueAnalysisReport;
