import React from "react";
import Chart from "react-apexcharts";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const RiskChart = ({ tableData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Prepare data for the chart
  const seriesData = tableData && tableData?.map((table) => ({
    x: table.AssociatedRisks,
    y: table.graphValue,
    fillColor:
      table.graphValue === 3
        ? "#FF0000" // Red for Very High
        : table.graphValue === 2
        ? "#FF9800" // Yellow for High
        : "#2c662e", // Green for Moderate
  }));

  // Configuration options for the chart
  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%", // Adjust bar height to create space between bars
        distributed: true,
        dataLabels: {
          position: "center", // Place data labels in the center of bars
          style: {
            fontSize: isMobile ? "10px" : "14px", // Adjust font size based on screen size
            colors: ["#000"], // Adjust text color if needed
          },
        },
        colors: {
          ranges: seriesData && seriesData?.map((data) => ({
            from: data.y,
            to: data.y,
            color: data.fillColor,
          })),
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val; // Display the value
      },
      offsetX: 0,
      offsetY: 0,
    },
    xaxis: {
      title: {
        text: "Risk level",
        style: {
          fontSize: isMobile ? "14px" : "18px", // Adjust font size based on screen size
        },
      },
      labels: {
        style: {
          fontSize: isMobile ? "10px" : "14px", // Adjust font size based on screen size
        },
      },
    },
    yaxis: {
      title: {
        text: "Risk",
        style: {
          fontSize: isMobile ? "14px" : "18px", // Adjust font size based on screen size
        },
      },
      labels: {
        style: {
          fontSize: isMobile ? "10px" : "14px", // Adjust font size based on screen size
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflow: "visible",
        },
        formatter: function (val) {
          return val;
        },
        maxWidth: 300, // Increase this value to fit longer labels
      },
      tooltip: {
        enabled: true,
      },
    },
    title: {
      text: "Risk of Lifestyle disorders",
      align: "center",
      style: {
        fontSize: isMobile ? "16px" : "20px", // Adjust font size based on screen size
      },
    },
    legend: {
      show: false, // Remove automatic legends
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            bar: {
              dataLabels: {
                position: "center", // Place data labels in the center of bars for smaller screens
              },
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "10px", // Smaller font size for small screens
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "10px", // Smaller font size for small screens
              },
            },
          },
        },
      },
    ],
  };

  return (
    <div>
      <Chart
        options={chartOptions}
        series={[{ data: seriesData }]}
        type="bar"
        width="100%"
        height={isMobile ? "500px" : "700px"}
      />
    </div>
  );
};

export default RiskChart;
