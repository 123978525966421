import axios from "axios";
import Cookies from "js-cookie";
import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const login = (userData) => {
    setUser(userData);

    const jsonData = JSON.stringify(userData);
    // Encode the data in Base64
    const encodedData = btoa(jsonData);

    // Store the encoded data in localStorage
    localStorage.setItem("user", encodedData);
  };

  const logout = async (userData) => {
    if (userData && userData.userId) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/login/post/remove/existing/user/token/${userData.userId}`,
          { withCredentials: true },
        )
        .then((response) => {
          if (response && response.status === 200) {
            toast.success("You're logged out. Remember, you're always welcome back!");

            const encodedData = localStorage.getItem("user");
            if (encodedData) {
              // Decode the Base64 string
              const decodedData = atob(encodedData);

              // Convert the JSON string back to an object
              JSON.parse(decodedData);

              // Remove user information from local storage
              localStorage.removeItem("user", decodedData);
            }

            Cookies.remove("token");

            setUser(null);
          }
        })
        .catch((error) => {
          // Handle error
          console.error("Error clearing token cookie", error);
        });
    } else {
      console.error("user id not found");
    }
  };

  return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;
