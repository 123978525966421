import React, { useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, TextField, Button, FormControl, Container, Paper, Tooltip } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { DNA } from "react-loader-spinner";
import AuthContext from "../../AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const UserDetail = ({ isMobile }) => {
  const { id } = useParams();
  const [userId, setUserId] = useState(id);
  const { login, setCookieWithLimit } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);

  const handleLogin = async () => {
    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/login/post/admin/user/${userId}`)
        .then((response) => {
          if (response && response.status === 200 && response.data && response.data.data) {
            login(response.data.data);

            setCookieWithLimit("token", response.data.token);

            setShowLoading(false);

            toast.success("Great to see you again! You're now logged in");

            navigate("/home");
          }

          return response;
        })
        .catch((error) => {
          // Handle error
          if (error && error.response && error.response.status === 400) {
            setShowLoading(false);

            toast.error("User with mentiond ID not found");
          } else {
            setShowLoading(false);

            console.error("Error occurred logging in:", error);
            toast.error("An error occurred logging in. Please try again later.");
          }
        });
    } catch (error) {
      setShowLoading(false);

      console.error("Error occurred logging in:", error);
      toast.error("An error occurred logging in. Please try again later.");
    }
  };

  const handleImgClick = () => {
    navigate("/");
  };

  if (showLoading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item>
            <DNA
              visible={true}
              height={isMobile ? "120" : "200"}
              width={isMobile ? "200" : "250"}
              color="#2B2A29"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Grid>
        </Grid>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundImage: `url(${
          isMobile
            ? `${process.env.PUBLIC_URL}/images/Portrait-Image.jpg`
            : `${process.env.PUBLIC_URL}/images/Landscape-Image.jpg`
        })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Tooltip title="Navigate to Main Page" arrow>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
          alt="Overlay"
          onClick={handleImgClick}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: isMobile ? "110px" : "135px",
            height: isMobile ? "110px" : "135px",
            zIndex: 1,
            cursor: "pointer",
          }}
        />
      </Tooltip>
      <Container maxWidth="sm">
        <AnimatePresence>
          <Paper
            elevation={24}
            style={{
              padding: isMobile ? "30px" : "80px",
              zIndex: 2,
              borderRadius: "40px",
              marginTop: isMobile ? "35%" : "18%",
              marginBottom: "10%",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            }}
          >
            <FormControl>
              <Grid container spacing={4} justifyContent="center">
                <Grid item xs={10} sx={{ mt: isMobile ? 8 : undefined }}>
                  <motion.div initial="hidden" animate="visible">
                    <TextField
                      placeholder="User ID"
                      id="user-id"
                      variant="standard"
                      value={userId}
                      onChange={(e) => {
                        setUserId(e.target.value);
                      }}
                      onKeyPress={
                        isMobile
                          ? null
                          : (e) => {
                              if (e.key === "Enter") {
                                e.preventDefault(); // Prevent the default action
                                handleLogin();
                              }
                            }
                      }
                      fullWidth
                      required
                      autoComplete="off"
                      InputProps={{
                        style: { fontSize: "1.5rem", fontWeight: "600" },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "black",
                        },
                      }}
                    />
                  </motion.div>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center" // Align items vertically in the center
                  >
                    <motion.div initial="hidden" animate="visible">
                      <Button
                        variant="contained"
                        fullWidth
                        type="submit"
                        onClick={handleLogin}
                        style={{
                          background: "#2B2A29",
                          borderRadius: "40px",
                          fontSize: isMobile ? "1rem" : "1.3rem",
                          fontWeight: "bold",
                        }}
                      >
                        Login with USER ID
                      </Button>
                    </motion.div>
                  </Grid>
                </Grid>
              </Grid>
            </FormControl>
          </Paper>
        </AnimatePresence>
      </Container>
    </motion.div>
  );
};

export default UserDetail;
