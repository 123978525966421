/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { CardContent, Grid, TextField, Typography } from "@mui/material";
import { useScroll } from "../../ScrollContext";

const HealthMetrics = ({ isMobile, state, dispatch }) => {
  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleHeightWeightChange = (field, e) => {
    let newValue = e.target.value.replace(/\D/g, "").slice(0, 3); // Remove non-digit characters & Limit to 3 digits

    // Ensure the value is less than 300
    if (parseInt(newValue) < 300 || newValue === "") {
      dispatch({ type: field, payload: newValue });
    }
  };

  const memoizedHandleHeightWeightChange = useMemo(() => handleHeightWeightChange, [dispatch]);

  const handleBPChange = (field, e) => {
    let newValue = e.target.value.replace(/\D/g, "").slice(0, 6); // Limit to 6 digits

    if (newValue.length > 3) {
      // Insert "/" after the first 3 digits
      newValue = newValue.slice(0, 3) + "/" + newValue.slice(3);
    }

    dispatch({ type: field, payload: newValue });
  };

  const memoizedHandleBPChange = useMemo(() => handleBPChange, [dispatch]);

  const handleHeartRateChange = (field, e) => {
    let newValue = e.target.value.replace(/\D/g, "").slice(0, 3);

    // Ensure the value is less than 200
    if (parseInt(newValue) < 200 || newValue === "") {
      dispatch({ type: field, payload: newValue });
    }
  };

  const memoizedHandleHeartRateChange = useMemo(() => handleHeartRateChange, [dispatch]);

  return (
    <CardContent>
      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.7rem" : "2rem",
          textAlign: "center",
          mb: 5,
        }}
      >
        Health Metrics
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              color: "black",
              fontSize: isMobile ? "1.5rem" : "1.7rem",
              textAlign: isMobile ? "center" : "left",
              mb: isMobile ? 2 : 3,
            }}
          >
            1. Height
          </Typography>
          <TextField
            placeholder="000 (cm)"
            variant="outlined"
            value={state.height}
            onChange={(e) => {
              memoizedHandleHeightWeightChange("set_height", e);
            }}
            inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
            autoComplete="off"
            sx={{
              "& input": {
                color: "black",
                fontSize: isMobile ? "1.4rem" : "1.5rem",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "black",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                borderColor: "black",
                "& fieldset": {
                  borderColor: "black",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
                fontSize: isMobile ? "1.4rem" : "1.5rem",
              },
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              color: "black",
              fontSize: isMobile ? "1.5rem" : "1.7rem",
              textAlign: isMobile ? "center" : "left",
              mb: isMobile ? 2 : 3,
            }}
          >
            2. Weight
          </Typography>
          <TextField
            placeholder="00 (kg)"
            variant="outlined"
            value={state.weight}
            onChange={(e) => {
              memoizedHandleHeightWeightChange("set_weight", e);
            }}
            inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
            autoComplete="off"
            sx={{
              "& input": {
                color: "black",
                fontSize: isMobile ? "1.4rem" : "1.5rem",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "black",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                borderColor: "black",
                "& fieldset": {
                  borderColor: "black",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
                fontSize: isMobile ? "1.4rem" : "1.5rem",
              },
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              color: "black",
              fontSize: isMobile ? "1.5rem" : "1.7rem",
              textAlign: isMobile ? "center" : "left",
              mb: isMobile ? 2 : 3,
              mt: isMobile ? undefined : 1,
            }}
          >
            3. Blood Presure
          </Typography>
          <TextField
            placeholder="000/000 (mmHg)"
            variant="outlined"
            value={state.bloodPressure}
            onChange={(e) => {
              memoizedHandleBPChange("set_blood_pressure", e);
            }}
            inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
            autoComplete="off"
            sx={{
              "& input": {
                color: "black",
                fontSize: isMobile ? "1.4rem" : "1.5rem",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "black",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                borderColor: "black",
                "& fieldset": {
                  borderColor: "black",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
                fontSize: isMobile ? "1.4rem" : "1.5rem",
              },
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              color: "black",
              fontSize: isMobile ? "1.5rem" : "1.7rem",
              textAlign: isMobile ? "center" : "left",
              mb: isMobile ? 2 : 3,
              mt: isMobile ? undefined : 1,
            }}
          >
            4. Heart Rate
          </Typography>
          <TextField
            placeholder="000 (BPM)"
            variant="outlined"
            value={state.heartRate}
            onChange={(e) => {
              memoizedHandleHeartRateChange("set_heart_rate", e);
            }}
            inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
            autoComplete="off"
            sx={{
              "& input": {
                color: "black",
                fontSize: isMobile ? "1.4rem" : "1.5rem",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "black",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                borderColor: "black",
                "& fieldset": {
                  borderColor: "black",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
              },
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default HealthMetrics;
