import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography, Tooltip, CardContent, Grid, Card } from "@mui/material";
import { motion } from "framer-motion";
import Header from "../../Header";

const TnC = ({ isMobile }) => {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/others/Background.jpg`})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Header />
      </Box>

      <Tooltip title="Navigate to Home Page" arrow>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
          alt="Company Logo"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: isMobile ? "110px" : "135px",
            height: isMobile ? "110px" : "135px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
      </Tooltip>

      <Container maxWidth="xl" sx={{ mt: isMobile ? "40%" : "12%", mb: isMobile ? "15%" : "2%" }}>
        <Card sx={{ borderRadius: "20px", backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
        <Grid container>
            <Grid item xs={12}>
          <CardContent>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              Last updated: June 01, 2024
            </Typography>

            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              Hi! Welcome to 7D Living, an Health Technology Company provides online consultations
              with qualified Ayurvedic doctors. Our services include scheduling appointments,
              conducting consultations via video or phone calls, and providing personalized health
              and lifestyle advice based on Ayurvedic principles operated by and proprietary to Desi
              LLM PVT. LTD., a Limited Liability Company (doing business as : 7D Living Platform or
              Company) having registration credentials as U86901KA2024PTC186441 in Belagavi,
              Karnataka , India.
            </Typography>

            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully
              before using the
            </Typography>

            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              _____7D Living.com_____ (the “Website”) operated by Desi LLM PVT. LTD. (hereinafter
              referred to as “7D Living”)(“us”, “we”, or “our”).
            </Typography>

            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              By registering with 7D Living, you acknowledge and agree to be bound by the terms and
              conditions outlined in this User Agreement between you and 7D Living. Your acceptance
              is based on a careful reading and understanding of this Agreement. We retain the right
              to modify or change these Terms at our discretion. Your continued access or use of the
              7D Living Platform after any such modifications constitutes your acceptance of the
              revised Terms. It is important to note that specific terms and conditions may apply to
              users in particular jurisdictions, as outlined herein. The use of pronouns such as
              "we," "us," or "our" in this User Agreement refers to Desi LLM PVT. LTD. and its
              affiliates.
            </Typography>

            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "center", my: 4 }}
            >
              PLEASE READ THESE TERMS OF USE CAREFULLY.
            </Typography>

            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "center", my: 4 }}
            >
              BY CLICKING THE "CREATE ACCOUNT" BUTTON OR BY ACCESSING OR USING THE SERVICES, YOU
              AGREE TO BE LEGALLY BOUND BY THESE TERMS OF SERVICE AND ALL TERMS INCORPORATED BY
              REFERENCE.
            </Typography>

            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "center", my: 4 }}
            >
              7D Living EXPRESSLY DISCLAIMS ANY AND ALL RESPONSIBILITY FOR ANY DIRECT OR
              CONSEQUENTIAL LOSS OR DAMAGE OF ANY KIND WHATSOEVER ARISING DIRECTLY OR INDIRECTLY
              FROM: I) RELYING ON ANY INFORMATION CONTAINED IN THIS DOCUMENT; II) ANY ERROR,
              OMISSION OR INACCURACY IN ANY SUCH INFORMATION; III) ANY ACTION RESULTING THEREFROM;
              IV) USAGE OR ACQUISITION OF PRODUCTS AVAILABLE THROUGH THE WEBSITE.
            </Typography>

            {/* Sections 1 to 20 continue here */}

            {/* Section 1 */}
            <Typography variant={isMobile ? "subtitle2" : "subtitle1"} sx={{ fontWeight: "bold" }}>
              1. Acceptance of Terms of Use and Amendments
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              By accessing or using this website, you agree to be bound by the Terms of Use, which
              may be amended from time to time with or without notice. If you utilize specific
              services on this website, you are also subject to any applicable rules or guidelines,
              which are hereby incorporated by reference into these Terms of Use. Please refer to
              our Privacy Policy, as it is part of and incorporated into these Terms of Use by
              reference.
            </Typography>

            {/* Section 2 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              2. Our Service
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              The services provided to you through our website are offered on an "AS IS" basis. You
              consent to the exclusive right of the website owners to modify, discontinue, or delete
              the website and its services at any time without notice and without any liability to
              you. The owners bear no responsibility or liability for the timeliness, deletion,
              failure to store, inaccuracy, or improper delivery of any data or information you
              provide, whether temporarily or permanently.
            </Typography>

            {/* Section 3 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              3. Create an Account
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              To create an account on our website, it is imperative to furnish essential details,
              including your name, gender, contact information, and citizenship. Ensuring the
              accuracy of the provided information is crucial for the proper functioning of the
              account and to comply with regulatory requirements. We emphasize the importance of
              providing correct details to facilitate a seamless account creation process and to
              maintain the integrity of our services.
            </Typography>

            {/* Section 4 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              4. Access to The Service
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              7D Living is offering a range of services to its clients and customers through this
              platform which is designed to facilitate global expansion. Participation in the
              platform's services may incur a fee as specified in the view plan tab on the platform,
              designed to ensure ongoing development and support. This Platform grants you a
              limited, revocable, nonexclusive right to access the Service for your own personal
              use.
            </Typography>

            {/* Section 5 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              5. Payment Terms
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              By selecting a payment plan and completing the payment process through Axis Bank
              Payment or such alternative methods which may change from time to tome at the
              discretion of 7D Living, clients will be granted access to the platform's benefits as
              specified in the chosen plan. The payment plan options, along with their corresponding
              features, are outlined on the website view plan tab on the platform. Clients
              acknowledge that access to the 7D Living platform and its associated benefits is
              contingent upon the successful payment in accordance with the selected plan.
            </Typography>

            {/* Section 6 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              6. Privacy Notice
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              The information that we may collect, use and share is subject to our Privacy Policy,
              as posted on ___7D Living.com___ (Website).
            </Typography>

            {/* Section 7 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              7. Your Conduct
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              You acknowledge that any information or data, including text, photographs, graphics,
              videos, or other materials ("Content"), whether shared publicly or privately, is the
              sole responsibility of the individual providing the Content. We disclaim any
              responsibility to you for the Content displayed on this website, and we shall not be
              held liable for any errors or omissions related to the Content.
            </Typography>
            {/* Section 8 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              8. Third Party Services
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              Third-party goods and services may be promoted or accessible on this website.
              Statements made concerning products and services offered by third parties are subject
              to the policies and representations established by those third parties. We do not
              assume any liability or responsibility for your transactions or interactions with
              third parties in any way.
            </Typography>

            {/* Section 9 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              9. Indemnification
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              You consent to indemnify and protect us, along with our subsidiaries, affiliates,
              related parties, officers, directors, employees, agents, independent contractors,
              advertisers, partners, and co-branders, from any claim or demand, including reasonable
              attorney's fees, asserted by a third party. This claim or demand may arise due to or
              be associated with your activities or association with this website or Service, your
              provision of Content, or your breach of these Terms of Use or any other infringement
              of the rights of another individual or entity.
            </Typography>

            {/* Section 10 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              10. Risk Disclosure
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              By accessing and using the services provided by 7D Living through its website, you
              acknowledge and agree to the following risk disclosure: Participation in the
              activities, events, or any services offered by 7D Living Platform may involve inherent
              risks. These risks include, but are not limited to, physical injury, property damage,
              or other unforeseen consequences. Members voluntarily assume these risks and agree to
              exercise caution and prudence while engaging in any 7D Living platform-related
              activities.
            </Typography>

            {/* Section 11 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              11. Limitation of Liability
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              You explicitly comprehend and agree that we are not liable for any direct, indirect,
              special, incidental, consequential, or exemplary damages, which may include but are
              not limited to damages for loss of profits, goodwill, use, data, or other intangible
              losses. This applies even if we have been advised of the possibility of such damages.
            </Typography>

            {/* Section 12 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              12. Disclaimer of Warranties
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              Unless expressly stated otherwise in written form by us, our services are provided on
              An "As is" and "As available" basis. We explicitly disclaim, and you waive, all
              warranties of any kind, whether express or implied.
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              Including, without limitation, implied warranties of merchantability, fitness for a
              particular purpose, title, and non-infringement concerning our services, including the
              information, content, and materials contained therein.
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              You recognize that information you store or transfer through our services may be
              Irretrievably lost, corrupted, or temporarily unavailable due to various causes. These
              Causes include but are not limited to software failures, protocol changes by
              third-party providers, internet outages, force majeure events, or other disasters,
              including third-party ddos attacks, scheduled or unscheduled maintenance, or other
              causes either within or outside our control. You are solely responsible for backing up
              And maintaining duplicate copies of any information you store or transfer through our
              services.
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              Some jurisdictions do not allow the disclaimer of implied terms in contracts with
              consumers, so some or all of the disclaimers in this section may not apply to you.
            </Typography>
            {/* Section 13 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              13. Intellectual Property Rights.
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              7D Living owns the Website, including all its contents, features, and functionality
              (such as information, software, text, displays, images, video, audio, and the design,
              selection, and arrangement). The ownership extends to 7D Living, its affiliated
              entities, if applicable, or other providers of such material. These assets are
              safeguarded by the copyright, trademark, patent, trade secret, and other intellectual
              property or proprietary rights laws of the India and international jurisdictions.
            </Typography>

            {/* Sections 14 to 20 continue here */}
            {/* Section 14 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              14. Reservation of Rights
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              All rights, including copyrights, trademarks, patents, trade secrets, and any other
              proprietary rights held by us in our website, its content, and the goods and services
              offered, are reserved. Prior written consent from us is necessary for the utilization
              of our rights and property. The availability of our services does not grant you any
              implied or express licenses or rights, and you are not authorized to make any
              commercial use of our website or services without obtaining our prior written consent.
            </Typography>

            {/* Section 15 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              15. Changes to the Website.
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              We reserve the right to periodically update the content on this website; however, the
              material may not always be comprehensive or current. Any information on the website
              might become outdated, and we are not obligated to refresh such material.
            </Typography>

            {/* Section 16 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              16. Governing Law
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              These Terms will be governed and interpreted in accordance with the laws of Belagavi,
              Karnataka, India, without consideration of its conflict of law provisions. Our failure
              to enforce any right or provision of these Terms shall not be deemed a waiver of those
              rights. Should any provision of these Terms be deemed invalid or unenforceable by a
              court, the remaining provisions will remain in effect. These Terms constitute the
              entire agreement between us concerning our Service, supplanting and displacing any
              prior agreements we may have had regarding the Service.
            </Typography>

            {/* Section 17 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              17. Arbitration
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              The Parties agree that any and all controversies or claims arising out of or relating
              to this Agreement, or any alleged breach hereof, shall be resolved by arbitration as
              prescribed herein. A single arbitrator engaged in the practice of law, who is
              knowledgeable about the telecommunications industry and telecommunications law, shall
              conduct the arbitration under the current rules of the American Arbitration
              Association (“AAA”), unless otherwise provided herein. The arbitrator shall be
              selected in accordance with AAA procedures from a list of qualified people maintained
              by AAA. The arbitration shall be conducted in Belagavi, Karnataka, and all expedited
              procedures prescribed under the AAA rules shall apply. There shall be no discovery
              other than the exchange of information, which is provided to the arbitrators by the
              Parties. The arbitrator shall only have authority to award compensatory damages and
              shall not have authority to award punitive damages, other non-compensatory damages or
              any other form of relief. Each Party shall bear its own costs and attorneys’ fees and
              disbursements subject to the award of attorneys’ fees by the arbitrator. The
              arbitrator’s decision and award shall be fnal and binding, and judgment upon the award
              rendered by the arbitrator may be entered in any court having jurisdiction thereof. If
              any Party fles a judicial or administrative action (other than a Party seeking
              injunction or other equitable relief as provided in subsection 5(c) above) asserting
              claims subject to arbitration, as prescribed herein, and another Party successfully
              stays such action and/or compels arbitration of said claims, the Party fling said
              action shall pay the other Party’s costs and expenses incurred in seeking such stay
              and/or compelling arbitration, including reasonable attorneys’ fees and disbursement.
            </Typography>

            {/* Section 18 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              18. Notification of Copyright Infringement
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              If you believe that your property has been used in any way that would be considered
              copyright infringement or a violation of your intellectual property rights, our
              concerned person may be contacted at the following address: support@7dliving.com.
            </Typography>

            {/* Section 19 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              19. Miscellaneous Information
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              In the event that this Terms of Use conflicts with any law under which any provision
              may be held invalid by a court with jurisdiction over the parties, such provision will
              be interpreted to reflect the original intentions of the parties in accordance with
              applicable law, and the remainder of this Terms of Use will remain valid and intact.
            </Typography>

            {/* Section 20 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              20. Entire Agreement.
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              {" "}
              The Terms of Use and our Privacy Policy constitute the sole and entire agreement
              between you and the 7D Living platform regarding the Website and supersede all prior
              and contemporaneous understandings, agreements, representations, and warranties, both
              written and oral, regarding the Website.
            </Typography>

            {/* Section 21 */}
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "bold", textAlign: "left", my: 4 }}
            >
              21. Your Comments and Concerns.
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              This website is operated by: Desi LLM PVT. LTD., a Limited Liability Company
              incorporated in Belagavi, Karnataka.
            </Typography>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
            >
              .All notices of copyright infringement claims, other feedback, comments, requests for
              technical support, and other communications relating to the Website should be directed
              to: support@7dliving.com_ (email address)
            </Typography>
          </CardContent>
          </Grid>
          </Grid>
        </Card>
      </Container>
    </motion.div>
  );
};

export default TnC;
