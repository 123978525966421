/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { TextField, Typography, CardContent } from "@mui/material";
import { useScroll } from "../../ScrollContext";

const Complaint = ({ isMobile, state, dispatch }) => {
  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleChange = (field, value) => {
    dispatch({ type: field, payload: value });
  };

  const memoizedHandleChange = useMemo(() => handleChange, [dispatch]);

  return (
    <CardContent>
      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.7rem" : "2rem",
          textAlign: "center",
          mb: 5,
        }}
      >
        Chief Complaint: Please Describe Your Main Concern
      </Typography>

      <TextField
        placeholder="Enter your complaint"
        multiline
        variant="outlined"
        value={state.complaint}
        onChange={(e) => memoizedHandleChange("set_complaint", e.target.value)}
        fullWidth
        autoComplete="off"
        rows={7}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        sx={{
          "& input": {
            color: "black",
            fontSize: isMobile ? "1.4rem" : "1.5rem",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
            fontSize: isMobile ? "1.4rem" : "1.5rem",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
      />
    </CardContent>
  );
};

export default Complaint;
