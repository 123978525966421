/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo } from "react";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  Typography,
  Link,
  MenuItem,
  Select,
  Box,
  ListItemIcon,
  ListItemText,
  Autocomplete,
} from "@mui/material";
import { Email, Wc } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import { motion } from "framer-motion";
import countries from "../../country.json";
import { toast } from "react-toastify";
import axios from "axios";
import { DNA } from "react-loader-spinner";
import { useScroll } from "../../ScrollContext";
import AuthContext from "../../AuthContext";

const LoginFields = ({ state, dispatch, closeLoading, variants, isMobile, CustomPopper }) => {
  const { scrollToTop } = useScroll();
  useEffect(() => {
    scrollToTop();
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleGenderSelection = (event) => {
    dispatch({ type: "set_gender", payload: event.target.value });
  };

  useEffect(() => {
    dispatch({ type: "set_login_number", payload: "" });
    dispatch({ type: "set_login_otp", payload: "" });
    dispatch({
      type: "set_login_country",
      payload: countries.find((country) => country.name === "India"),
    });
    dispatch({ type: "set_login_mobile_code", payload: "+91" });
    dispatch({ type: "set_login_otp_flag", payload: false });
  }, [dispatch]);

  const handleLogin = async () => {
    const requiredFields = ["name", "countryCode", "mobileNumber", "email", "gender", "age"];

    const isAnyFieldEmpty = requiredFields.some((field) => !state[field]);

    if (isAnyFieldEmpty) {
      // Show toast message indicating the required fields are empty
      toast.error(
        "It appears that some information is missing. Please ensure all field(s) are filled out",
      );

      return;
    } else if (!validateEmail(state.email)) {
      toast.error("Oops! That doesn't look like a valid email address. Please check and try again");
      return;
    }

    closeLoading(true);

    if (
      state.mobileNumber &&
      state.mobileNumber.length >= 6 &&
      state.mobileNumber === state.mobileNumberFlag
    ) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/login/post/request/otp`,
          {
            userDetail: { countryCode: state.countryCode, mobileNumber: state.mobileNumber },
          },
          {
            withCredentials: true,
          },
        )
        .then((response) => {
          if (response && response.status === 200) {
            toast.success("The verification code has been sent to your number");

            dispatch({
              type: "set_mobile_number_flag",
              payload: "",
            });

            dispatch({
              type: "set_otp_flag",
              payload: true,
            });

            dispatch({ type: "set_component", payload: "login otp" });
          }
        })
        .catch((error) => {
          if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
            console.error("Error sending OTP:", error);

            switch (error.response.status) {
              case 400:
                toast.error(
                  "Oops! It seems like the mobile number is incorrect. Please provide a valid one",
                );
                break;
              case 404:
                toast.error(
                  "Sorry, we couldn't find any account associated with the provided mobile number. Please sign up to get started",
                );
                break;
              case 409:
                dispatch({ type: "set_component", payload: "login mobile fields" });
                toast.info(error.response.data.message);
                break;
              case 500:
                toast.error("An error occurred while sending OTP. Please try again later");
                break;
              default:
                toast.error("An error occurred while sending OTP. Please try again later");
            }
          } else {
            toast.error("An error occurred while sending OTP. Please try again later");
          }
        });
    } else if (state.mobileNumber && state.mobileNumber.length < 6) {
      toast.error("Please ensure that the mobile number is correct");
    } else if (state.otpFlag) {
      dispatch({ type: "set_component", payload: "login otp" });
    }

    closeLoading(false);
  };

  const handleLoginUsingMobile = () => {
    dispatch({ type: "set_component", payload: "login mobile fields" });
  };

  const handleChange = (field, value) => {
    dispatch({ type: field, payload: value });
  };

  const memoizedHandleChange = useMemo(() => handleChange, [dispatch]);

  const handleAgeChange = (e) => {
    let newValue = e.target.value.replace(/\D/g, "").slice(0, 3); // Remove non-digit characters & Limit to 3 digits

    // Ensure the value is less than 120
    if (parseInt(newValue) < 120 || newValue === "") {
      dispatch({ type: "set_age", payload: newValue });
    }
  };

  const memoizedHandleAgeChange = useMemo(() => handleAgeChange, [dispatch]);

  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      dispatch({ type: "set_country_code", payload: newValue.dial_code });
      dispatch({ type: "set_selected_country", payload: newValue });
    }
  };

  if (state.showLoading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item>
            <DNA
              visible={true}
              height={isMobile ? "120" : "200"}
              width={isMobile ? "200" : "250"}
              color="#2B2A29"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Grid>
        </Grid>
      </motion.div>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    handleLogin(); // Call handleLogin function when form is submitted
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default action
      handleLogin();
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <FormControl onSubmit={handleSubmit}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <motion.div variants={variants} initial="hidden" animate="visible">
              <TextField
                placeholder="Full Name"
                id="name"
                variant="standard"
                value={state.name}
                onChange={(e) =>
                  memoizedHandleChange("set_name", e.target.value.replace(/[^A-Za-z\s]/g, ""))
                }
                onKeyPress={isMobile ? undefined : handleKeyPress}
                fullWidth
                required
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon fontSize="large" sx={{ color: "black" }} />
                    </InputAdornment>
                  ),
                  style: { fontSize: "1.5rem", fontWeight: "600" },
                }}
                InputLabelProps={{ style: { color: "black" } }}
                sx={{
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black",
                  },
                }}
              />
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <motion.div variants={variants} initial="hidden" animate="visible">
              <Box display="flex">
                <FormControl sx={{ mr: 1, mt: 1.5, width: "60%" }} variant="standard">
                  <Autocomplete
                    options={countries}
                    getOptionLabel={(option) => `${option.dial_code} ${option.name}`}
                    value={state.selectedCountry || null}
                    onChange={handleCountryChange}
                    isOptionEqualToValue={(option, value) => option.dial_code === value.dial_code}
                    renderOption={(props, option) => (
                      <MenuItem {...props} divider>
                        <ListItemIcon>
                          <img src={option.flag_url} alt="" width="30" height="20" />
                        </ListItemIcon>
                        <ListItemText primary={`${option.dial_code} ${option.name}`} />
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Country"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          style: { fontWeight: "600" },
                        }}
                      />
                    )}
                    PopperComponent={CustomPopper}
                  />
                </FormControl>

                <TextField
                  placeholder="Mobile Number"
                  id="mobile number"
                  variant="standard"
                  value={state.mobileNumber}
                  onChange={(e) => {
                    memoizedHandleChange(
                      "set_mobile_number",
                      e.target.value.replace(/\D/g, "").slice(0, 13),
                    );
                    memoizedHandleChange(
                      "set_mobile_number_flag",
                      e.target.value.replace(/\D/g, "").slice(0, 13),
                    );
                  }}
                  onKeyPress={isMobile ? undefined : handleKeyPress}
                  fullWidth
                  required
                  autoComplete="off"
                  InputProps={{
                    style: { fontSize: "1.5rem", fontWeight: "600" },
                  }}
                  InputLabelProps={{ style: { color: "black" } }}
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                  }}
                />
              </Box>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <motion.div variants={variants} initial="hidden" animate="visible">
              <TextField
                placeholder="Email"
                id="email"
                variant="standard"
                value={state.email}
                onChange={(e) =>
                  memoizedHandleChange("set_email", e.target.value.toLowerCase().replace(/\s/g, ""))
                }
                onKeyPress={isMobile ? undefined : handleKeyPress}
                fullWidth
                required
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email fontSize="large" sx={{ color: "black" }} />
                    </InputAdornment>
                  ),
                  style: { fontSize: "1.5rem", fontWeight: "600" },
                }}
                InputLabelProps={{ style: { color: "black" } }}
                sx={{
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black",
                  },
                }}
              />
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <motion.div variants={variants} initial="hidden" animate="visible">
              <FormControl fullWidth variant="standard">
                <Select
                  value={state.gender}
                  onChange={handleGenderSelection}
                  displayEmpty
                  inputProps={{ "aria-label": "Gender" }}
                  InputLabelProps={{ style: { color: "black" } }}
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: "600",
                    "&:focus": {
                      borderColor: "black",
                    },
                    "&:hover": {
                      borderColor: "black",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& .MuiMenuItem-root.Mui-selected": {
                      color: "black",
                    },
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <Wc fontSize="large" sx={{ color: "black" }} />
                    </InputAdornment>
                  }
                >
                  <MenuItem
                    value=""
                    disabled
                    sx={{ mt: -1, fontSize: "1.5rem", fontWeight: "600" }}
                    divider
                  >
                    None
                  </MenuItem>
                  <MenuItem value="male" sx={{ fontSize: "1.5rem", fontWeight: "600" }} divider>
                    Male
                  </MenuItem>
                  <MenuItem
                    value="female"
                    sx={{ mb: -1, fontSize: "1.5rem", fontWeight: "600" }}
                    divider
                  >
                    Female
                  </MenuItem>
                </Select>
              </FormControl>
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={4}>
            <motion.div variants={variants} initial="hidden" animate="visible">
              <TextField
                placeholder="Age"
                id="age"
                variant="standard"
                value={state.age}
                onChange={memoizedHandleAgeChange}
                onKeyPress={isMobile ? undefined : handleKeyPress}
                fullWidth
                required
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PermContactCalendarIcon fontSize="large" sx={{ color: "black" }} />
                    </InputAdornment>
                  ),
                  style: { fontSize: "1.5rem", fontWeight: "600" },
                }}
                InputLabelProps={{ style: { color: "black" } }}
                sx={{
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black",
                  },
                }}
              />
            </motion.div>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              alignItems="center" // Align items vertically in the center
            >
              <Grid item xs={12} sm={6}>
                <motion.div variants={variants} initial="hidden" animate="visible">
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    onClick={handleLogin}
                    style={{
                      background: "#2B2A29",
                      borderRadius: "40px",
                      fontSize: isMobile ? "1rem" : "1.3rem",
                      // textTransform: "capitalize",
                      fontWeight: "bold",
                    }}
                  >
                    Send Verification Code
                  </Button>
                </motion.div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              <Typography
                sx={{
                  color: "black",
                  fontSize: "1.2rem",
                  textAlign: "center",
                  mt: isMobile ? -1 : 0.3,
                  mb: 2,
                  fontWeight: "600",
                }}
              >
                Already Provided Your Information ?
              </Typography>
              {isMobile ? undefined : <>&nbsp;&nbsp;&nbsp;</>}
              <Link
                component="button"
                onClick={handleLoginUsingMobile}
                style={{
                  cursor: "pointer",
                  color: "black",
                  textDecoration: "underline",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}
              >
                Login Quickly Using Your Mobile Number!
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </motion.div>
  );
};

export default LoginFields;
