/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo } from "react";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  Typography,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Box,
  Autocomplete,
} from "@mui/material";
import { motion } from "framer-motion";
import countries from "../../country.json";
import { toast } from "react-toastify";
import axios from "axios";
import { DNA } from "react-loader-spinner";
import { useScroll } from "../../ScrollContext";
import AuthContext from "../../AuthContext";

const LoginMobileField = ({ state, dispatch, closeLoading, variants, isMobile, CustomPopper }) => {
  const { scrollToTop } = useScroll();
  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    dispatch({ type: "set_name", payload: "" });
    dispatch({
      type: "set_selected_country",
      payload: countries.find((country) => country.name === "India"),
    });
    dispatch({ type: "set_country_code", payload: "+91" });
    dispatch({ type: "set_mobile_number", payload: "" });
    dispatch({ type: "set_email", payload: "" });
    dispatch({ type: "set_gender", payload: "" });
    dispatch({ type: "set_age", payload: "" });
    dispatch({ type: "set_otp", payload: "" });
    dispatch({ type: "set_otp_flag", payload: false });
  }, [dispatch]);

  const handleLogin = async () => {
    if (!state.loginNumber) {
      // Show toast message indicating the mobile number field is empty
      toast.error("Kindly enter your mobile number");

      return;
    }

    closeLoading(true);

    if (
      state.loginNumber &&
      state.loginNumber.length >= 6 &&
      state.loginNumber === state.loginNumberFlag
    ) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/login/post/check/existing/user/request/otp`,
          {
            userDetail: { countryCode: state.loginMobileCode, mobileNumber: state.loginNumber },
          },
          {
            withCredentials: true,
          },
        )
        .then((response) => {
          if (response && response.status === 200) {
            closeLoading(false);

            toast.success("The verification code has been sent to your number");

            dispatch({
              type: "set_login_number_flag",
              payload: "",
            });
            dispatch({
              type: "set_login_otp_flag",
              payload: true,
            });
            dispatch({ type: "set_component", payload: "login mobile otp" });
          }
          return response;
        })
        .catch((error) => {
          // Handle error
          if (error && error.response && error.response.status === 400) {
            closeLoading(false);

            toast.error(
              "Oops! It seems like the mobile number is incorrect. Please provide a valid one",
            );
          } else if (error && error.response && error.response.status === 404) {
            closeLoading(false);

            toast.error(
              "Sorry, we couldn't find any account associated with the provided mobile number. Please sign up to get started",
            );
          } else {
            closeLoading(false);

            console.error("Error sending OTP:", error);
            toast.error("An error occurred while sending OTP. Please try again later");
          }
        });
    } else if (state.loginNumber && state.loginNumber.length < 6) {
      closeLoading(false);

      toast.error("Please ensure that the mobile number is correct");
    } else if (state.loginOtpFlag) {
      closeLoading(false);

      dispatch({ type: "set_component", payload: "login mobile otp" });
    }
  };

  const handleFirstLogin = () => {
    dispatch({ type: "set_component", payload: "login fields" });
  };

  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      dispatch({ type: "set_login_mobile_code", payload: newValue.dial_code });
      dispatch({ type: "set_login_country", payload: newValue });
    }
  };

  const handleChange = (field, value) => {
    dispatch({ type: field, payload: value });
  };

  const memoizedHandleChange = useMemo(() => handleChange, [dispatch]);

  if (state.showLoading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item>
            <DNA
              visible={true}
              height={isMobile ? "120" : "200"}
              width={isMobile ? "200" : "250"}
              color="#2B2A29"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Grid>
        </Grid>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <FormControl>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={8} sx={{ mt: isMobile ? 8 : undefined }}>
            <motion.div variants={variants} initial="hidden" animate="visible">
              <Box display="flex">
                <FormControl sx={{ mr: 1, mt: 1.5, width: "60%" }} variant="standard">
                  <Autocomplete
                    options={countries}
                    getOptionLabel={(option) => `${option.dial_code} ${option.name}`}
                    value={state.loginCountry || null}
                    onChange={handleCountryChange}
                    isOptionEqualToValue={(option, value) => option.dial_code === value.dial_code}
                    renderOption={(props, option) => (
                      <MenuItem {...props} divider>
                        <ListItemIcon>
                          <img src={option.flag_url} alt="" width="30" height="20" />
                        </ListItemIcon>
                        <ListItemText primary={`${option.dial_code} ${option.name}`} />
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Country"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          style: { fontWeight: "600" },
                        }}
                      />
                    )}
                    PopperComponent={CustomPopper}
                  />
                </FormControl>

                <TextField
                  placeholder="Mobile Number"
                  id="mobile number"
                  variant="standard"
                  value={state.loginNumber}
                  onChange={(e) => {
                    memoizedHandleChange(
                      "set_login_number",
                      e.target.value.replace(/\D/g, "").slice(0, 13),
                    );
                    memoizedHandleChange(
                      "set_login_number_flag",
                      e.target.value.replace(/\D/g, "").slice(0, 13),
                    );
                  }}
                  onKeyPress={
                    isMobile
                      ? null
                      : (e) => {
                          if (e.key === "Enter") {
                            e.preventDefault(); // Prevent the default action
                            handleLogin();
                          }
                        }
                  }
                  fullWidth
                  required
                  autoComplete="off"
                  InputLabelProps={{ style: { color: "black" } }}
                  InputProps={{
                    style: { fontSize: "1.5rem", fontWeight: "600" },
                  }}
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                  }}
                />
              </Box>
            </motion.div>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              alignItems="center" // Align items vertically in the center
            >
              <motion.div variants={variants} initial="hidden" animate="visible">
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  onClick={handleLogin}
                  style={{
                    background: "#2B2A29",
                    borderRadius: "40px",
                    fontSize: isMobile ? "1rem" : "1.3rem",
                    // textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                >
                  Send Verification Code
                </Button>
              </motion.div>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              <Typography
                sx={{
                  color: "black",
                  fontSize: "1.2rem",
                  textAlign: "center",
                  mt: isMobile ? -1 : 0.3,
                  fontWeight: "600",
                }}
              >
                New User ?
              </Typography>
              {isMobile ? undefined : <>&nbsp;&nbsp;&nbsp;</>}
              <Link
                component="button"
                onClick={handleFirstLogin}
                style={{
                  cursor: "pointer",
                  color: "black",
                  textDecoration: "underline",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}
              >
                Click Here To Provide Your Information
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </motion.div>
  );
};

export default LoginMobileField;
