/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Box, Tooltip, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import { DNA } from "react-loader-spinner";
import Chart from "react-apexcharts";
import Header from "../Header";
import { useDispatch } from "react-redux";
import ChatBot from "../ChatBot/ChatBot";
import { setTopicCode } from "../../Redux/rootSlice";
import RiskChart from "./RiskChart";
import DoshaCombinationTable from "./DoshaCombinationTable";

const analysisDescriptions = {
  analysis2: "Understanding Vikrity",
  analysis3: "Understanding Sub Doshas",
  analysis4: "Understanding Malakriya",
  analysis5: "Understanding Agni",
  analysis6: "Understanding Sama Dhatus",
};

const PhysicalReport = ({ isMobile }) => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const { user } = useAuth();
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchReportData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/report/get/physical/result/${user?.userId}`,
        { withCredentials: true },
      );
      if (response.data.success) {
        setReportData(response.data.data);
      } else {
        toast.error("Failed to fetch report data.");
      }
    } catch (error) {
      toast.error("An error occurred while fetching the report.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchReportData();
    }
  }, [user]);

  const renderChart = (chartData, chartTitle) => {
    return (
      <Chart
        type="bar"
        width={isMobile ? "100%" : "80%"}
        height={isMobile ? "200px" : "400px"}
        series={[
          {
            name: chartTitle,
            data: chartData.map((item) => ({
              x: item.name.trim(),
              y: parseFloat(item.score),
            })),
          },
        ]}
        options={{
          chart: {
            toolbar: { show: false },
          },
          xaxis: {
            labels: {
              style: { fontSize: 12 },
            },
          },
          yaxis: {
            labels: {
              style: { fontSize: 12 },
            },
            title: {
              text: chartTitle,
            },
          },
          colors: ["#008FFB"], // Default color; change if needed
        }}
      />
    );
  };

  const handleChangePage = (description) => {
    if (!description) {
      console.error("Description is undefined. Check your mapping or key.");
      return;
    }

    const topicCode = analysisDescriptions[description];

    if (topicCode) {
      reduxDispatch(setTopicCode(topicCode));

      navigate("/complete-report");
    } else {
      console.error("Invalid description. No corresponding topic code.");
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/others/Report.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Header />
        </Box>
        <Tooltip title="Navigate to Home Page" arrow>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
            alt="Company Logo"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: isMobile ? "110px" : "135px",
              height: isMobile ? "110px" : "135px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/home")}
          />
        </Tooltip>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mx: 4, mb: isMobile ? 12 : 6 }}
        >
          <Paper
            elevation={3}
            style={{
              borderRadius: "20px",
              padding: isMobile ? "20px" : "40px",
              width: isMobile ? "90%" : "70%",
              height: "auto",
              marginTop: isMobile ? "45%" : "5%",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            }}
          >
            {loading ? (
              <motion.div style={{ textAlign: "center" }}>
                <DNA
                  visible
                  height={isMobile ? "120px" : "200px"}
                  width={isMobile ? "200px" : "250px"}
                />
              </motion.div>
            ) : (
              <>
                {Object.keys(reportData).filter(report=>report!=="description3")?.map((key) => {
                  const analysis = reportData[key];
                  if (Array.isArray(analysis)) {
                    return (
                      <Grid container direction="column" spacing={2} key={key} alignItems="center">
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              textAlign: "center",
                              backgroundColor: "#721F4B",
                              color: "white",
                              fontWeight: "bold",
                              borderRadius: "3px",
                              fontSize: isMobile ? "1.2rem" : "1.5rem",
                              marginBottom: "1rem",
                              padding: "1rem",
                              textTransform: "uppercase",
                            }}
                          >
                            {analysis[0]?.report?.topicHeading ||
                              analysis[0]?.report?.main?.topicHeading}
                          </Box>

                          <Typography
                            sx={{
                              textAlign: "justify",
                              textTransform: "uppercase",
                              fontWeight: 600,
                              fontSize: isMobile ? "0.9rem" : "1rem",
                            }}
                            paragraph
                            dangerouslySetInnerHTML={{
                              __html:
                                analysis[0]?.report?.topicDescription ||
                                analysis[0]?.report?.main?.topicDescription,
                            }}
                          />

                          {analysis[0]?.graph &&
                            renderChart(
                              analysis[0].graph,
                              analysis[0]?.report?.topicHeading ||
                                analysis[0]?.report?.main?.topicHeading,
                            )}

                          {key !== "analysis1" && ( // Check if the current analysis is not analysis1
                            <Box sx={{ textAlign: "right", marginTop: "20px" }}>
                              <Typography
                                onClick={() => {
                                  handleChangePage(key);
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: "black",
                                  textDecoration: "underline",
                                  fontSize: isMobile ? "1rem" : "1.2rem",
                                  mr: 2,
                                }}
                              >
                                <Tooltip title="More Details" arrow>
                                  More Details:{" "}
                                  {key === "analysis2" && analysisDescriptions.analysis2}
                                  {key === "analysis3" && analysisDescriptions.analysis3}
                                  {key === "analysis4" && analysisDescriptions.analysis4}
                                  {key === "analysis5" && analysisDescriptions.analysis5}
                                  {key === "analysis6" && analysisDescriptions.analysis6}
                                </Tooltip>
                              </Typography>
                            </Box>
                          )}

                          <Box
                            sx={{
                              textAlign: "center",
                              color: "white",
                              backgroundColor: "#B9116A",
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                textAlign: "center",
                                textTransform: "uppercase",
                                marginBottom: "10px",
                              }}
                            >
                              {analysis[0]?.report?.dosha?.topicHeading}
                            </Typography>
                          </Box>

                          {/* Display the topic description */}
                          <Typography
                            sx={{
                              textTransform: "uppercase",
                              fontWeight: 600,
                              fontSize: isMobile ? "0.9rem" : "1rem",
                            }}
                            paragraph
                            dangerouslySetInnerHTML={{
                              __html: analysis[0]?.report?.dosha?.topicDescription,
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  } else {
                    return null;
                  }
                })}

                {
                  console.log("rist",reportData?.description3)
                }
                <Box
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#721F4B",
                    color: "white",
                    mt: 2,
                    borderRadius: "3px",
                    padding: "10px",
                    marginBottom: "20px",
                    textTransform: "uppercase",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      textTransform: "uppercase",
                      marginBottom: "10px",
                    }}
                  >
                    Risk of Lifestyle disorders 
                  </Typography>
                </Box>
                {
                  // console.log("description3",tableData)
                  reportData && reportData?.description3?.length && (
                    <DoshaCombinationTable
                      isMobile={isMobile}
                      tableData={reportData && reportData?.description3}
                    />
                  )
                } 

                <br />
                <RiskChart isMobile={isMobile} tableData={reportData && reportData?.description3} />

                <Box
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#721F4B",
                    color: "white",
                    mt: 2,
                    borderRadius: "3px",
                    padding: "10px",
                    marginBottom: "20px",
                    textTransform: "uppercase",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      textTransform: "uppercase",
                      marginBottom: "10px",
                    }}
                  >
                    {reportData?.description1?.topicHeading}
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                    fontSize: isMobile ? "1rem" : "1rem",
                  }}
                  paragraph
                  dangerouslySetInnerHTML={{
                    __html: reportData?.description1?.topicDescription || "",
                  }}
                />

                <Box
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#721F4B",
                    color: "white",
                    mt: 2,
                    borderRadius: "3px",
                    padding: "10px",
                    marginBottom: "20px",
                    textTransform: "uppercase",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      textTransform: "uppercase",
                      marginBottom: "10px",
                    }}
                  >
                    {reportData?.description2?.topicHeading}
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                    fontSize: isMobile ? "1rem" : "1rem",
                  }}
                  paragraph
                  dangerouslySetInnerHTML={{
                    __html: reportData?.description2?.topicDescription || "",
                  }}
                />
              </>
            )}
          </Paper>
        </Grid>
      </motion.div>
      <ChatBot promptText={reportData} sessionType={"report-physical"} isMobile={isMobile} />
    </>
  );
};

export default PhysicalReport;
