/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useRef, useState } from "react";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Box, Typography, Grid, Button, Tooltip } from "@mui/material";
import Footer from "../FooterComponent/Footer";
import MobileView from "./MobileView";
import PcView from "./PcView";
import TopBar from "./TopBar";
import CarouselEffect from "./CarouselEffect";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  SetRoutingPages,
  setActiveSteps,
  setRoutingData,
  setStep,
  setRoutingIds,
  setSelectedAnswers,
  setTestType,
  setCurrentRoute,
  setQuestions,
  resetState,
  setRoutePath,
} from "../../Redux/rootSlice";
import axios from "axios";

const initialState = {
  isImgVisible1: false,
  isImgVisible2: false,
  isImgVisible3: false,
  isImgVisible4: false,
  isTextVisible1: false,
  isTextVisible2: false,
  isTextVisible3: false,
  isTextVisible4: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_isimage_visible1":
      return { ...state, isImgVisible1: action.payload };
    case "set_isimage_visible2":
      return { ...state, isImgVisible2: action.payload };
    case "set_isimage_visible3":
      return { ...state, isImgVisible3: action.payload };
    case "set_isimage_visible4":
      return { ...state, isImgVisible4: action.payload };
    case "set_istext_visible1":
      return { ...state, isTextVisible1: action.payload };
    case "set_istext_visible2":
      return { ...state, isTextVisible2: action.payload };
    case "set_istext_visible3":
      return { ...state, isTextVisible3: action.payload };
    case "set_istext_visible4":
      return { ...state, isTextVisible4: action.payload };
    default:
      return state;
  }
};

const LandingPage = ({ isMobile }) => {
  // Refs for each image and text
  const imgRef1 = useRef(null);
  const imgRef2 = useRef(null);
  const imgRef3 = useRef(null);
  const imgRef4 = useRef(null);
  const textRef1 = useRef(null);
  const textRef2 = useRef(null);
  const textRef3 = useRef(null);
  const textRef4 = useRef(null);
  const textRef = useRef(null);
  const divRef1 = useRef(null);
  const divRef2 = useRef(null);
  const butRef = useRef(null);
  const butRef1 = useRef(null);
  const picRef = useRef(null);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [isDivVisible1, setIsDivVisible1] = useState(false);
  const [isDivVisible2, setIsDivVisible2] = useState(false);
  const [isPictureVisible, setIsPictureVisible] = useState(false);

  const { routingData, routingPages, activeSteps, currentRoute } = useSelector((state) => state);

  const reduxDispatch = useDispatch();
  const { user } = useAuth();
  const navigate = useNavigate();

  const imageUrl = isMobile
    ? `${process.env.PUBLIC_URL}/images/Portrait-Image.jpg`
    : `${process.env.PUBLIC_URL}/images/Landscape-Image.jpg`;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          switch (entry.target) {
            case textRef.current:
              setIsInfoVisible(entry.isIntersecting);
              break;
            case divRef1.current:
              setIsDivVisible1(entry.isIntersecting);
              break;
            case divRef2.current:
              setIsDivVisible2(entry.isIntersecting);
              break;
            case picRef.current:
              setIsPictureVisible(entry.isIntersecting);
              break;
            default:
              const alt = entry.target.dataset.alt;
              const payload = {
                [`isImgVisible${alt.substring(6)}`]: entry.isIntersecting,
                [`isTextVisible${alt.substring(6)}`]: entry.isIntersecting,
              };
              dispatch({ payload });
              break;
          }
        });
      },
      { threshold: 0.2 },
    );

    if (textRef.current) observer.observe(textRef.current);
    if (divRef1.current) observer.observe(divRef1.current);
    if (divRef2.current) observer.observe(divRef2.current);
    if (butRef.current) observer.observe(butRef.current);
    if (butRef1.current) observer.observe(butRef1.current);
    if (picRef.current) observer.observe(picRef.current);

    return () => observer.disconnect();
  }, []);

  const image1 = "/images/landingpage/Evaluation.jpg";
  const image2 = "/images/landingpage/Report.jpg";
  const image3 = "/images/landingpage/Recomendations.jpg";
  const image4 = "/images/landingpage/Assistance.jpg";

  const animationVariants = {
    visible: { opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, transition: { duration: 1 } },
  };

  const fetchStepCount = async (type) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/ayurveda-resource/post/analysis/user/progress-bar/count/${user?.userGender}/${type}`,
        { withCredentials: true },
      );

      if (response && response.status === 200 && response.data && response.data.data) {
        const result = response.data.data;
        reduxDispatch(setStep(result.step));
        reduxDispatch(setRoutingData(result?.routingPath));
        reduxDispatch(setRoutingIds(result?.routingData));
        reduxDispatch(setTestType(type));
        navigate(result?.routingPath[0].path);
        let progressPerct = {
          page: "",
          path: "",
          progress: "",
        };
        progressPerct.page = 0;
        progressPerct.path = result?.routingPath[0].path;
        progressPerct.progress = 0;
        reduxDispatch(setCurrentRoute([...currentRoute, progressPerct]));
      }
    } catch (error) {
      console.error("Error fetching step count:", error);
      toast.error("Failed to fetch step count. Please try again later.");
    }
  };

  const handleRoute = (path) => {
    if (user && user.userName) {
      if (path === "new-appointment") {
        navigate(`/${path}`);
      } else {
        fetchStepCount("dosha");
      }
    } else {
      reduxDispatch(setRoutePath(path));

      toast.info("To continue, please authenticate by logging in");
      navigate("/login");
    }
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "auto",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <CarouselEffect isMobile={isMobile} />
        {/* <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        src={
          isMobile
            ? `${process.env.PUBLIC_URL}/images/Portrait-Image.jpg`
            : `${process.env.PUBLIC_URL}/images/Landscape-Image.jpg`
        }
        alt="Banner"
        style={{
          width: "100%",
          height: isMobile ? "80vh" : "120vh",
          objectPosition: isMobile ? "right center" : "center center",
          objectFit: "cover",
        }}
        loading="lazy"
      />

      {/* <LazyLoadImage
        src={imageUrl}
        alt="Banner"
        effect="blur" // Optional: Choose effect like 'blur', 'black-and-white', etc.
        width="100%"
        height="100%"
        style={{
          width: "100%",
          height: isMobile ? "80vh" : "120vh",
          objectPosition: isMobile ? "right center" : "center center",
          objectFit: "cover",
        }}

      /> */}

        <TopBar isMobile={isMobile} />

        {/* Text */}
        <Typography
          sx={{
            position: "absolute",
            top: "2%",
            right: isMobile ? "4%" : "8%",
            textAlign: "center",
            color: "#fff",
            zIndex: 1,
          }}
        >
          <motion.div
            initial="hidden"
            animate={isInfoVisible ? "visible" : "hidden"}
            exit="hidden"
            variants={animationVariants}
            ref={textRef}
          >
            <span
              style={{
                fontSize: isMobile ? "2rem" : "4rem",
                fontWeight: "bold",
                textShadow: "2px 2px 4px black",
              }}
            >
              ELEVATE
            </span>
            <br />
            <span
              style={{
                fontSize: isMobile ? "2rem" : "4rem",
                fontWeight: "bold",
                textShadow: "2px 2px 4px black",
                color: "#FFD700",
              }}
            >
              YOUR
            </span>
            <br />
            <span
              style={{
                fontSize: isMobile ? "2rem" : "4rem",
                fontWeight: "bold",
                textShadow: "2px 2px 4px black",
              }}
            >
              LIFESTYLE
            </span>
            <br />
            <span
              style={{
                fontSize: isMobile ? "1rem" : "1.5rem",
                fontWeight: "bold",
                textShadow: "2px 2px 4px black",
                lineHeight: isMobile ? "5" : "3",
              }}
            >
              WITH WISDOM OF
            </span>
            <br />
            <span
              style={{
                fontSize: isMobile ? "1rem" : "1.5rem",
                fontWeight: "bold",
                textShadow: "2px 2px 4px black",
                lineHeight: "2",
              }}
            >
              AYURVEDA
            </span>
            <br />
            <span
              style={{
                fontSize: isMobile ? "1rem" : "1.5rem",
                fontWeight: "bold",
                textShadow: "2px 2px 4px black",
                lineHeight: "2",
              }}
            >
              &
            </span>
            <br />
            <span
              style={{
                fontSize: isMobile ? "1rem" : "1.5rem",
                fontWeight: "bold",
                textShadow: "2px 2px 4px black",
                lineHeight: "2",
              }}
            >
              SMARTNESS OF AI
            </span>
            <br />
          </motion.div>
        </Typography>

        <div
          style={{
            width: "100%",
            height: isMobile ? "60%" : "30%",
            backgroundColor: "#020932",
            paddingTop: isMobile ? "20px" : "40px",
            paddingBottom: isMobile ? "20px" : "40px",
            flexDirection: "column",
            justifyContent: "center", // Center content horizontally
            alignItems: "center", // Center content vertically
            textAlign: "center",
            color: "white",
            textShadow: "2px 2px 4px black",
          }}
        >
          <motion.div
            initial="hidden"
            animate={isDivVisible1 ? "visible" : "hidden"}
            exit="hidden"
            variants={animationVariants}
            ref={divRef1}
          >
            <Typography
              variant={isMobile ? "h5" : "h4"}
              fontWeight="bold"
              gutterBottom
              sx={{ textTransform: "uppercase" }}
            >
              Unlock Your Unique Ayurvedic Profile For Free
            </Typography>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              fontWeight={600}
              sx={{
                mx: isMobile ? 2 : 5,
                textTransform: "uppercase",
              }}
            >
              Reveal Your Unique Wellness Profile and Transform Your Daily Life with Personalized
              Ayurvedic Insights. Embrace Balance, Enhance Your Energy, and Achieve Holistic Health
              and Harmony!
            </Typography>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              fontWeight={600}
              sx={{ mt: 2, mx: isMobile ? 2 : 5, textTransform: "uppercase" }}
            >
              Start Your Journey to Balance and Vitality Today!
            </Typography>

            <Grid container justifyContent="center" sx={{ mt: 2 }}>
              <motion.div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/landingpage/Background-Image-2.png`}
                  alt="Meditation"
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </motion.div>
            </Grid>

            <Grid container spacing={2} justifyContent="center" sx={{ mt: 0.2 }}>
              <Grid item xs={9} sm={7} md={5} lg={3}>
                <Tooltip title="Discover Your Ayurvedic Profile" arrow>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleRoute("analysis")}
                    sx={{
                      borderRadius: "30px",
                      backgroundImage: "linear-gradient(to right, #700076, #450163)",
                      color: "white",
                      fontSize: isMobile ? "0.9rem" : "1.2rem",
                      padding: isMobile ? "8px 0" : "15px 0",
                      height: "40px",
                      fontWeight: "bold",
                      border: "2px solid black",
                      "&:hover": {
                        backgroundImage:
                          "linear-gradient(to right, rgba(112, 0, 118, 0.8), rgba(69, 1, 99, 0.8))",
                        border: "2px solid white",
                      },
                    }}
                  >
                    Free Wellness Check
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          style={{
            backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/landingpage/Background.jpg`})`,
            backgroundSize: "cover",
            width: "100%",
          }}
        >
          {isMobile ? (
            <MobileView
              state={state}
              dispatch={dispatch}
              imgRef1={imgRef1}
              imgRef2={imgRef2}
              imgRef3={imgRef3}
              imgRef4={imgRef4}
              textRef1={textRef1}
              textRef2={textRef2}
              textRef3={textRef3}
              textRef4={textRef4}
              image1={image1}
              image2={image2}
              image3={image3}
              image4={image4}
            />
          ) : (
            <PcView
              state={state}
              dispatch={dispatch}
              imgRef1={imgRef1}
              imgRef2={imgRef2}
              imgRef3={imgRef3}
              imgRef4={imgRef4}
              textRef1={textRef1}
              textRef2={textRef2}
              textRef3={textRef3}
              textRef4={textRef4}
              image1={image1}
              image2={image2}
              image3={image3}
              image4={image4}
            />
          )}

          <div
            style={{
              width: "100%",
              height: isMobile ? "60%" : "30%",
              backgroundColor: "#020932",
              paddingTop: isMobile ? "20px" : "40px",
              paddingBottom: isMobile ? "20px" : "40px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "white",
              textShadow: "2px 2px 4px black",
            }}
          >
            <motion.div
              initial="hidden"
              animate={isDivVisible2 ? "visible" : "hidden"}
              exit="hidden"
              variants={animationVariants}
              ref={divRef2}
            >
              <Typography
                variant={isMobile ? "h5" : "h4"}
                fontWeight="bold"
                gutterBottom
                sx={{ textTransform: "uppercase" }}
              >
                Schedule Your Virtual Doctor Consultation and Transform Your Wellbeing!
              </Typography>
              <Typography
                variant={isMobile ? "body2" : "body1"}
                fontWeight={600}
                sx={{
                  mx: isMobile ? 2 : 5,
                  textTransform: "uppercase",
                }}
              >
                Unlock Your Personalized Wellness Journey and Elevate Your Daily Life with Tailored
                Ayurvedic Guidance. Embrace Balance, Boost Your Energy, and Attain Holistic Health
                and Harmony!
              </Typography>
              <Typography
                variant={isMobile ? "body2" : "body1"}
                fontWeight={600}
                sx={{ mt: 2, mx: isMobile ? 2 : 5, textTransform: "uppercase" }}
              >
                Begin Your Path to Harmony and Vitality Today!
              </Typography>

              <Grid container justifyContent="center" sx={{ mt: 2 }}>
                <motion.div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/landingpage/Background-Image-1.png`}
                    alt="Meditation"
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </motion.div>
              </Grid>

              <Grid container spacing={2} justifyContent="center" sx={{ mt: 0.2 }}>
                <Grid item xs={9} sm={7} md={5} lg={3}>
                  <Tooltip title="Schedule Your Ayurvedic Consultation" arrow>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => handleRoute("new-appointment")}
                      sx={{
                        borderRadius: "30px",
                        backgroundImage: "linear-gradient(to right, #700076, #450163)",
                        color: "white",
                        fontSize: isMobile ? "0.9rem" : "1.2rem",
                        padding: isMobile ? "8px 0" : "15px 0",
                        height: "40px",
                        fontWeight: "bold",
                        border: "2px solid black",
                        "&:hover": {
                          backgroundImage:
                            "linear-gradient(to right, rgba(112, 0, 118, 0.8), rgba(69, 1, 99, 0.8))",
                          border: "2px solid white",
                        },
                      }}
                    >
                      Schedule Consultation
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </motion.div>
          </div>

          {/* Medition */}
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={8}>
              <motion.div
                ref={picRef}
                initial="hidden"
                animate={isPictureVisible ? "visible" : "hidden"}
                exit="hidden"
                variants={animationVariants}
              >
                <Grid container justifyContent="center" alignItems="flex-start">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/landingpage/Meditation-Image.png`}
                    alt="Meditation"
                    style={{
                      width: isMobile ? "100%" : "50%",
                      height: "auto",
                    }}
                  />
                </Grid>
              </motion.div>
            </Grid>
          </Grid>

          <Footer isMobile={isMobile} />
        </motion.div>
      </Box>
    </motion.div>
  );
};

export default LandingPage;
