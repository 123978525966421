import { styled, Paper } from "@mui/material";

export const DemoPaper = styled(Paper)(() => ({
  width: "70%",
  minWidth: "50%",
  minHeight: "50%",
  height: "auto",
  borderRadius: "20px",
  padding: "30px",
  overflow: "hidden",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
}));
