/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { AuthProvider, useAuth } from "./AuthContext";
import LandingPage from "./components/LandingPage/LandingPage";
import AboutUs from "./components/LandingPage/AboutUs";
import Articles from "./components/LandingPage/Articles";
import Login from "./components/Auth/Login";
import { ToastContainer } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import Analysis from "./components/Analysis/Analysis";
import HomePage from "./components/HomePage/HomePage";
import NewAppointment from "./components/Appointment/NewAppointment/NewAppointment";
import UpcomingAppointment from "./components/Appointment/UpcomingAppointment/UpcomingAppointment";
import IntakeForm from "./components/IntakeForm/IntakeForm";
import { ScrollProvider } from "./ScrollContext";
import DoshaReport from "./components/Report/DoshaReport";
import PhysicalReport from "./components/Report/PhysicalReport";
import MentalReport from "./components/Report/MentalReport";
import CompleteReport from "./components/Report/CompleteReport";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import UserDetail from "./components/Auth/UserDetail";
import CareerList from "./components/FooterComponent/Careers/CareerList";
import CareerDetail from "./components/FooterComponent/Careers/CareerDetail";
import FAQSeries from "./components/FooterComponent/FAQ/FAQSeries";
import FeedBack from "./components/FooterComponent/FeedBack/FeedBack";
import MediaList from "./components/FooterComponent/Media/MediaList";
import Subscription from "./components/Subscription/Subscription";
import TnC from "./components/FooterComponent/T&C/TnC";
import PrivacyPolicy from "./components/FooterComponent/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "./components/FooterComponent/CookiePolicy/CookiePolicy";
import CategoryGroup from "./components/Analysis/CategoryGroup";
import Category from "./components/Analysis/Category";
import SubCategory from "./components/Analysis/SubCategory";
import Questions from "./components/Analysis/Questions";
import TongueAnalysisReport from "./components/HomePage/TongueAnalysisReport";
import ECGAnalysisReport from "./components/HomePage/ECGAnalysisReport";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat", // Apply Montserrat as the global font
  },
});

const pageTransition = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

function App() {
  return (
    <AuthProvider>
      <ScrollProvider>
        <ThemeProvider theme={theme}>
          <AppContent />
        </ThemeProvider>
      </ScrollProvider>
    </AuthProvider>
  );
}

function AppContent() {
  const { user, login } = useAuth();
  const isMobile = useMediaQuery("(max-width: 800px)");
  const scrollbarRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (scrollbarRef.current) {
      // Directly access the DOM node and set scrollTop to 0
      const container = scrollbarRef.current._container;
      if (container) {
        container.scrollTop = 0;
      }
    }
  }, [location]);

  useEffect(() => {
    const handleScrollToTop = () => {
      if (scrollbarRef.current) {
        // Accessing the PerfectScrollbar method or the container's DOM property
        const ps = scrollbarRef.current;
        if (ps && ps.scrollTop) {
          ps.scrollTop(0);
        } else if (ps && ps._container) {
          // Checking if _container is the correct reference
          ps._container.scrollTop = 0;
        }
      }
    };

    window.addEventListener("scrollToTop", handleScrollToTop);
    return () => window.removeEventListener("scrollToTop", handleScrollToTop);
  }, []);

  useEffect(() => {
    const encodedData = localStorage.getItem("user");
    if (encodedData) {
      const decodedData = atob(encodedData);

      const userData = JSON.parse(decodedData);

      login(userData);
    }
  }, []); // Empty dependency array ensures the effect runs only once on mount

  // const isLoggedIn = true;
  const isLoggedIn = !!user;

  return (
    <PerfectScrollbar ref={scrollbarRef} style={{ width: "100%", height: "100vh" }}>
      <AnimatePresence>
        <Routes>
          {/* First Page route */}
          <Route
            path="/"
            element={
              <motion.div {...pageTransition}>
                <LandingPage isMobile={isMobile} />
              </motion.div>
            }
          />

          {/* Login route */}
          <Route
            path="/login"
            element={
              <motion.div {...pageTransition}>
                <Login isMobile={isMobile} />
              </motion.div>
            }
          />

          <Route
            path="/user/:id"
            element={
              <motion.div {...pageTransition}>
                <UserDetail isMobile={isMobile} />
              </motion.div>
            }
          />

          {/* Articles route */}
          <Route
            path="/articles"
            element={
              <motion.div {...pageTransition}>
                <Articles isMobile={isMobile} />
              </motion.div>
            }
          />

          {/* About route */}
          <Route
            path="/about"
            element={
              <motion.div {...pageTransition}>
                <AboutUs isMobile={isMobile} />
              </motion.div>
            }
          />

          <Route
            path="/careers"
            element={
              <motion.div {...pageTransition}>
                <CareerList isMobile={isMobile} />
              </motion.div>
            }
          />

          <Route
            path="/career/:id"
            element={
              <motion.div {...pageTransition}>
                <CareerDetail isMobile={isMobile} />
              </motion.div>
            }
          />

          <Route
            path="/faq"
            element={
              <motion.div {...pageTransition}>
                <FAQSeries isMobile={isMobile} />
              </motion.div>
            }
          />

          <Route
            path="/feedback"
            element={
              <motion.div {...pageTransition}>
                <FeedBack isMobile={isMobile} />
              </motion.div>
            }
          />

          <Route
            path="/media"
            element={
              <motion.div {...pageTransition}>
                <MediaList isMobile={isMobile} />
              </motion.div>
            }
          />

          <Route
            path="/privacypolicy"
            element={
              <motion.div {...pageTransition}>
                <PrivacyPolicy isMobile={isMobile} />
              </motion.div>
            }
          />

          <Route
            path="/termsandconditions"
            element={
              <motion.div {...pageTransition}>
                <TnC isMobile={isMobile} />
              </motion.div>
            }
          />

          <Route
            path="/cookiepolicy"
            element={
              <motion.div {...pageTransition}>
                <CookiePolicy isMobile={isMobile} />
              </motion.div>
            }
          />

          {/* Home, Dosha Test, Dosha Report, New Appointment, Intake Form and Upcoming Appointment route if user logged in*/}

          {isLoggedIn && (
            <>
              <Route
                path="/home"
                element={
                  <motion.div {...pageTransition}>
                    <HomePage isMobile={isMobile} />
                  </motion.div>
                }
              />

              {/* <Route
                path="/analysis"
                element={
                  <motion.div {...pageTransition}>
                    <Analysis isMobile={isMobile} />
                  </motion.div>
                }
              /> */}

              <Route
                path="/dosha-report"
                element={
                  <motion.div {...pageTransition}>
                    <DoshaReport isMobile={isMobile} />
                  </motion.div>
                }
              />

              <Route
                path="/physical-report"
                element={
                  <motion.div {...pageTransition}>
                    <PhysicalReport isMobile={isMobile} />
                  </motion.div>
                }
              />

              <Route
                path="/mental-report"
                element={
                  <motion.div {...pageTransition}>
                    <MentalReport isMobile={isMobile} />
                  </motion.div>
                }
              />

              <Route
                path="/complete-report"
                element={
                  <motion.div {...pageTransition}>
                    <CompleteReport isMobile={isMobile} />
                  </motion.div>
                }
              />

              <Route
                path="/tongue-analysis-report"
                element={
                  <motion.div {...pageTransition}>
                    <TongueAnalysisReport isMobile={isMobile} />
                  </motion.div>
                }
              />

              <Route
                path="/ecg-analysis-report"
                element={
                  <motion.div {...pageTransition}>
                    <ECGAnalysisReport isMobile={isMobile} />
                  </motion.div>
                }
              />

              <Route
                path="/new-appointment"
                element={
                  <motion.div {...pageTransition}>
                    <NewAppointment isMobile={isMobile} />
                  </motion.div>
                }
              />

              <Route
                path="/intake-form"
                element={
                  <motion.div {...pageTransition}>
                    <IntakeForm isMobile={isMobile} />
                  </motion.div>
                }
              />

              <Route
                path="/upcoming-appointments"
                element={
                  <motion.div {...pageTransition}>
                    <UpcomingAppointment isMobile={isMobile} />
                  </motion.div>
                }
              />

              <Route
                path="/subscription"
                element={
                  <motion.div {...pageTransition}>
                    <Subscription isMobile={isMobile} />
                  </motion.div>
                }
              />
              <Route
                path="/analysis"
                element={
                  <motion.div {...pageTransition}>
                    <Analysis isMobile={isMobile} />
                  </motion.div>
                }
              >
                <Route
                  path="categoryGroup/:categoryGroupId"
                  element={
                    <motion.div {...pageTransition}>
                      <CategoryGroup isMobile={isMobile} />
                    </motion.div>
                  }
                />
                <Route
                  path="categoryGroup/category/:categoryId"
                  element={
                    <motion.div {...pageTransition}>
                      <Category isMobile={isMobile} />
                    </motion.div>
                  }
                />
                <Route
                  path="categoryGroup/category/subcategory/:subCategoryId"
                  element={
                    <motion.div {...pageTransition}>
                      <SubCategory isMobile={isMobile} />
                    </motion.div>
                  }
                />
                <Route
                  path="categoryGroup/category/subcategory/question/:subCategoryId"
                  element={
                    <motion.div {...pageTransition}>
                      <Questions isMobile={isMobile} />
                    </motion.div>
                  }
                />
              </Route>
            </>
          )}
        </Routes>

        <ToastContainer
          position={isMobile ? "top-center" : "top-right"}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </AnimatePresence>
    </PerfectScrollbar>
  );
}

export default App;
