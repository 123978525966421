import React, { useState } from "react";
import { motion } from "framer-motion";
import { Stack, Box, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAuth } from "../../AuthContext";
import { toast } from "react-toastify";
import Header from "../Header";

const TopBar = ({ isMobile }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const { user } = useAuth();

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    setMenuOpen(false);
  };

  const handleAboutClick = () => {
    navigate("/about");
  };

  const handleArticleClick = () => {
    navigate("/articles");
  };
  const handleHomeClick = () => {
    if (user && user.userName) {
      navigate("/home");
    } else {
      toast.info("To continue, please authenticate by logging in");
      navigate("/login");
    }
  };

  const menuVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1 },
  };

  const buttonVariants = {
    hidden: { x: 50, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  const menuItemVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  const animationVariants = {
    visible: { opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, transition: { duration: 1 } },
  };

  return (
    <>
      <motion.img
        initial="hidden"
        animate={"visible"}
        variants={animationVariants}
        transition={{ duration: 1 }}
        src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: isMobile ? "110px" : "135px",
          height: isMobile ? "110px" : "135px",
          zIndex: 1,
        }}
      />

      {/* Buttons */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <motion.div initial="hidden" animate={"visible"} exit="hidden" variants={animationVariants}>
          <Stack direction="row" spacing={1} alignItems="center">
            {isMobile ? (
              <>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={menuVariants}
                >
                  <motion.div
                    variants={buttonVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ delay: 0.4 }}
                  >
                    <Button
                      style={{
                        background: "rgba(255, 255, 255, 1)", // Semi-transparent white background
                        borderRadius: "40px",
                        color: "#000000",
                        fontSize: "1rem",
                        textTransform: "capitalize",
                        border: "1px solid #ffffff",
                        boxShadow: "0px 0px 1px #ffffff",
                        fontWeight: "bold",
                      }}
                      onClick={handleMenuClick}
                      ref={(node) => setAnchorEl(node)}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      KNOW MORE
                    </Button>
                  </motion.div>
                  <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    sx={{
                      mt: 1,
                      fontSize: "1rem",
                      "& .MuiPaper-root": {
                        backgroundColor: "rgba(255, 255, 255, 1)",
                      },
                    }}
                    onClose={() => setMenuOpen(false)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <motion.div
                      variants={menuItemVariants}
                      initial="hidden"
                      animate="visible"
                      transition={{ staggerChildren: 0.1 }}
                    >
                      <MenuItem
                        sx={{ mt: -1, fontWeight: "bold", borderBottom: "1px solid black" }}
                        onClick={handleHomeClick}
                        divider
                      >
                        HOME
                      </MenuItem>
                      <MenuItem
                        sx={{ fontWeight: "bold", borderBottom: "1px solid black" }}
                        onClick={() => handleMenuItemClick("/about")}
                        divider
                      >
                        ABOUT US
                      </MenuItem>
                      <MenuItem
                        sx={{ mb: -1, fontWeight: "bold" }}
                        onClick={() => handleMenuItemClick("/articles")}
                      >
                        ARTICLES
                      </MenuItem>
                    </motion.div>
                  </Menu>
                </motion.div>
                <Header
                  menuVariants={menuVariants}
                  buttonVariants={buttonVariants}
                  isMobile={isMobile}
                />
              </>
            ) : (
              <>
                <Tooltip title="Home" arrow>
                  <motion.div
                    variants={buttonVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ delay: 0.4 }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 28,
                        backgroundColor: "white",
                        fontWeight: "bold",
                        height: "35px",
                        color: "black",
                        fontSize: "1rem",
                        border: "2px solid black",
                        "&:hover": {
                          backgroundColor: "black",
                          color: "white",
                          border: "2px solid white",
                        },
                      }}
                      onClick={handleHomeClick}
                    >
                      HOME
                    </Button>
                  </motion.div>
                </Tooltip>
                <Tooltip title="About Us" arrow>
                  <motion.div
                    variants={buttonVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ delay: 0.4 }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 28,
                        backgroundColor: "white",
                        fontWeight: "bold",
                        height: "35px",
                        color: "black",
                        fontSize: "1rem",
                        border: "2px solid black",
                        "&:hover": {
                          backgroundColor: "black",
                          color: "white",
                          border: "2px solid white",
                        },
                      }}
                      onClick={handleAboutClick}
                    >
                      ABOUT US
                    </Button>
                  </motion.div>
                </Tooltip>
                <Tooltip title="Articles" arrow>
                  <motion.div
                    variants={buttonVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ delay: 0.4 }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 28,
                        backgroundColor: "white",
                        fontWeight: "bold",
                        height: "35px",
                        color: "black",
                        fontSize: "1rem",
                        border: "2px solid black",
                        "&:hover": {
                          backgroundColor: "black",
                          color: "white",
                          border: "2px solid white",
                        },
                      }}
                      onClick={handleArticleClick}
                    >
                      ARTICLES
                    </Button>
                  </motion.div>
                </Tooltip>
                <Header menuVariants={menuVariants} buttonVariants={buttonVariants} />
              </>
            )}
          </Stack>
        </motion.div>
      </Box>
    </>
  );
};

export default TopBar;
