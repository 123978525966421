/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Grid, Paper, Typography, Button, Box, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useScroll } from "../../ScrollContext";
import Header from "../Header";
import plans from "./SubscriptionList.json";
import axios from "axios";
import { useAuth } from "../../AuthContext";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const Subscription = ({ isMobile }) => {
  const navigate = useNavigate();
  const { scrollToTop } = useScroll();
  const { user } = useAuth();

  useEffect(() => {
    scrollToTop();
  }, []);

  const displayRazorpay = async (plan) => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    try {
      if (user && user.userName && user.userEmail && user.userNumber) {
        const apiResponse1 = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/payment/post/request/razorpay`,
          { subscription_amt: plan.amount },
          { withCredentials: true },
        );

        if (
          apiResponse1 &&
          apiResponse1.status === 200 &&
          apiResponse1.data &&
          apiResponse1.data.data
        ) {
          const data = apiResponse1.data.data;

          let phoneNumber = user.userNumber;

          const spaceIndex = user.userNumber.indexOf(" ");
          if (spaceIndex !== -1) {
            phoneNumber = user.userNumber.substring(spaceIndex + 1);
          }

          const options = {
            key: "rzp_test_1b8uLzuDgyrRDJ",
            currency: data.currency,
            amount: data.amount.toString(),
            order_id: data.id,
            name: plan.name,
            description: "Subscription Being Processed...",
            image: "https://7dstatic.s3.ap-south-1.amazonaws.com/website/7DCompanyLogo.png",
            handler: async function (response) {
              try {
                if (
                  response &&
                  response.razorpay_payment_id &&
                  response.razorpay_order_id &&
                  response.razorpay_signature &&
                  user
                ) {
                  await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/payment/post/verification/razorpay`,
                    { user, plan, response },
                    { withCredentials: true },
                  );
                }
              } catch (error) {
                // Handle error
                console.error("Error fetching confirmation:", error);
              }
            },
            prefill: {
              name: user.userName,
              email: user.userEmail,
              phone_number: phoneNumber,
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  const getUniqueFeatures = (planName) => {
    const currentPlan = plans.find((plan) => plan.name === planName);
    const uniqueFeatures = currentPlan.features.slice(); // Create a copy of the current plan's features
    if (planName !== "Premium Membership") {
      const premiumFeatures = plans.find((plan) => plan.name === "Premium Membership").features;
      premiumFeatures.forEach((feature) => {
        if (!uniqueFeatures.includes(feature)) {
          uniqueFeatures.push(feature); // Add features from Premium Membership that are not in the current plan
        }
      });
    }
    return uniqueFeatures;
  };

  const renderFeatures = (planName, allFeatures) => {
    const planFeatures = plans.find((plan) => plan.name === planName).features;
    const uniqueFeatures = getUniqueFeatures(planName);

    return allFeatures.map((feature, index) => (
      <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
        {planFeatures.includes(feature) ? (
          <CheckIcon style={{ marginRight: 5 }} />
        ) : uniqueFeatures.includes(feature) ? (
          <CloseIcon style={{ marginRight: 5 }} />
        ) : null}
        {planFeatures.includes(feature) || uniqueFeatures.includes(feature) ? (
          <Typography variant={isMobile ? "subtitle1" : "h6"} fontWeight="600">
            {feature}
          </Typography>
        ) : null}
      </div>
    ));
  };

  const renderPlans = () => {
    const allFeatures = plans.reduce((acc, plan) => {
      acc.push(...plan.features);
      return acc;
    }, []);

    const uniqueFeatures = [...new Set(allFeatures)]; // Get unique features

    return plans.map((plan, index) => (
      <Grid item key={index} xs={12} sm={6} md={4}>
        <Paper
          sx={{
            padding: 2,
            height: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              backgroundColor: "#721F4B",
              color: "white",
              borderRadius: "3px",
              fontWeight: "bold",
              fontSize: isMobile ? "1.5rem" : "2rem",
              padding: 1,
              mb: 2,
            }}
          >
            {plan.name}
          </Box>
          {renderFeatures(plan.name, uniqueFeatures)}

          <Button
            variant="contained"
            onClick={() => displayRazorpay(plan)}
            color="primary"
            sx={{ width: "100%" }}
          >
            {plan.amount} Rs
          </Button>
        </Paper>
      </Grid>
    ));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      alt="Banner"
      style={{
        backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/others/Subscription.jpg`})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        objectPosition: "center center",
        objectFit: "cover",
      }}
      loading="lazy"
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Header />
      </Box>

      <Tooltip title="Navigate to Home Page" arrow>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
          alt="Company Logo"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: isMobile ? "110px" : "135px",
            height: isMobile ? "110px" : "135px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
      </Tooltip>
      <Grid
        container
        spacing={2}
        sx={{ mt: isMobile ? "40%" : "12%", mb: isMobile ? "15%" : "2%", mx: isMobile ? 2 : 5 }}
      >
        {renderPlans()}
      </Grid>
    </motion.div>
  );
};

export default Subscription;
