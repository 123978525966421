import React from "react";
import { motion } from "framer-motion";
import { Box, Grid, Tooltip } from "@mui/material";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import Wellness from "./Wellness";
import Treatment from "./Treatment";
import ChatBot from "../ChatBot/ChatBot";

const HomePage = ({ isMobile }) => {
  const navigate = useNavigate();

  const handleImgClick = () => {
    navigate("/");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/others/Yoga-Meditation.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Header />
      </Box>

      <div onClick={handleImgClick} style={{ cursor: "pointer" }}>
        <Tooltip title="Navigate to Main Page" arrow>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
            alt="Overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: isMobile ? "110px" : "135px",
              height: isMobile ? "110px" : "135px",
              zIndex: 1,
            }}
          />
        </Tooltip>
      </div>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        {/* Welness Test */}
        <Wellness isMobile={isMobile} />

        {/* Track Your Treatment Process */}
        <Treatment isMobile={isMobile} />
      </Grid>

      <ChatBot sessionType={"home"} isMobile={isMobile} />
    </motion.div>
  );
};

export default HomePage;
