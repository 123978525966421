import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography, Tooltip, CardContent, Grid, Card } from "@mui/material";
import { motion } from "framer-motion";
import Header from "../../Header";

const PrivacyPolicy = ({ isMobile }) => {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/others/Background.jpg`})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Header />
      </Box>

      <Tooltip title="Navigate to Home Page" arrow>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
          alt="Company Logo"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: isMobile ? "110px" : "135px",
            height: isMobile ? "110px" : "135px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
      </Tooltip>

      <Container maxWidth="xl" sx={{ mt: isMobile ? "40%" : "12%", mb: isMobile ? "15%" : "2%" }}>
        <Card sx={{ borderRadius: "20px", backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
          <Grid container>
            <Grid item xs={12}>
              <CardContent sx={{ ml: 3 }}>
                <Typography variant={isMobile ? "h4" : "h2"} sx={{ fontWeight: "bold" }}>
                  Privacy Policy
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", mt: 5 }}
                >
                  This policy explains how Desi LLM (”Desi LLM PVT. LTD.”, and its affiliates and
                  subsidiaries, also referred to below as “7D Living”, “we”, “us” or “our”) handles
                  your personal data - from collecting it to protecting it. We value your privacy
                  and want you to understand how your information is treated. This includes what
                  data we gather, why we need it, what we do with it, and how you can control it.
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  This Privacy Notice was last updated and is effective on 01 June 2024
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  This Privacy Policy is applicable to our Services and any other interactions you
                  might engage in with us, including visiting our websites, making customer service
                  inquiries, attending events, and more. Your utilization of our Services or any
                  form of interaction implies your consent to the gathering and utilization of your
                  personal information as outlined in this Privacy Policy by 7D Living. Personal
                  information encompasses any data or information associated with you that can be
                  utilized to identify you, either directly or indirectly, or to establish contact
                  with you.
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  Further, we are committed to protecting the privacy of our clients, customers, and
                  website visitors. As such, we have implemented policies and procedures to ensure
                  that we comply with the GDPR and other applicable data protection laws and
                  regulations.
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  We understand that your personal data is important to you, and we take our
                  responsibility to protect it seriously. We are committed to being transparent
                  about our data processing practices, and we strive to provide clear and concise
                  information to help you understand how we use your personal data.
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  In addition to complying with the GDPR, we also adhere to best practices in data
                  protection and privacy. We have implemented a range of technical and
                  organizational measures to ensure the security and confidentiality of personal
                  data. Our employees are trained on data protection and privacy best practices, and
                  we conduct regular audits to ensure that our policies and procedures remain
                  up-to-date and effective.
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  Within our Services, you may encounter links to external websites, products, or
                  services that are not under our ownership or control. While utilizing our
                  Services, you have the option to share your data and associated personal
                  information with a third-party service of your choice. The handling of your
                  information by these third parties is regulated by their individual privacy
                  policies and terms. We advise you to thoroughly examine their privacy policies and
                  terms since we assume no responsibility for the practices of such third-party
                  services.
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  We acknowledge that safeguarding data is an evolving effort, and we are dedicated
                  to consistently enhancing our procedures to uphold the utmost standards of data
                  protection and privacy. Should you have any inquiries or reservations regarding
                  our privacy practices, please feel free to get in touch with us.
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  It is essential to carefully read this Privacy Policy. By utilizing our services,
                  visiting our website, or furnishing us with your personal information, you
                  acknowledge that you have perused, comprehended, and consent to the terms outlined
                  in this Privacy Policy
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  BY ACCESSING OR USING THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ AND
                  UNDERSTOOD THIS PRIVACY POLICY AND CONSENT TO THE COLLECTION, USE, AND SHARING OF
                  YOUR PERSONAL DATA AS DESCRIBED HEREIN. IF YOU DO NOT AGREE WITH THIS POLICY,
                  PLEASE DO NOT USE OUR SERVICES.
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", mt: 4, mb: 2 }}
                >
                  1. Introduction
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", mt: 4, mb: 2 }}
                >
                  <ul>
                    <li>Overview of the policy and its purpose </li>
                    We hold the belief that privacy is a fundamental right, and our commitment is
                    unwavering when it comes to safeguarding the privacy and security of our users'
                    personal information.
                  </ul>

                  <ul>
                    This Privacy Policy articulates our dedication to privacy, detailing the kinds
                    of information we gather, how we utilize that information, and the measures we
                    implement to ensure the protection of user privacy.
                  </ul>

                  <ul>
                    BY USING OUR PLATFORM, YOU CONSENT TO THE PRACTICES OUTLINED IN THIS PRIVACY
                    POLICY.
                  </ul>

                  <ul>
                    <li>Scope of the Policy</li>
                    This privacy policy applies to all personal data processed by 7D Living in
                    connection with the Services, including personal data collected through our
                    website, mobile applications, and other digital or offline means. It also
                    applies to personal data collected from our users, partners, vendors, and other
                    third parties.
                  </ul>

                  <ul>
                    <li>Definitions</li>
                    Throughout this privacy policy, certain terms may have specific meanings, as
                    defined by the GDPR. These include:
                  </ul>

                  <ul>
                    "Consent" means any freely given, specific, informed, and unambiguous indication
                    of the data subject's wishes by which he or she, by a statement or by a clear
                    affirmative action, signifies agreement to the processing of personal data
                    relating to him or her.
                  </ul>

                  <ul>
                    "Controller" means the natural or legal person, public authority, agency, or
                    other body which, alone or jointly with others, determines the purposes and
                    means of the processing of personal data.
                  </ul>

                  <ul>
                    "Data Breach" means a breach of security leading to the accidental or unlawful
                    destruction, loss, alteration, unauthorized disclosure of, or access to,
                    personal data transmitted, stored or otherwise processed.
                  </ul>

                  <ul>
                    "Data Portability" means the right of a data subject to receive the personal
                    data concerning him or her, which he or she has provided to a controller, in a
                    structured, commonly used and machine-readable format and have the right to
                    transmit those data to another controller without hindrance from the controller
                    to which the personal data have been provided.
                  </ul>

                  <ul>"Data Subject" means the individual to whom the personal data relates.</ul>

                  <ul>
                    "Personal data" means any information relating to an identified or identifiable
                    natural person (data subject).
                  </ul>

                  <ul>
                    "Processing" means any operation or set of operations performed on personal
                    data, such as collection, recording, organization, structuring, storage,
                    adaptation, or disclosure by transmission, dissemination or otherwise making
                    available, erasure, or destruction.
                  </ul>

                  <ul>
                    "Processor" means a natural or legal person, public authority, agency or other
                    body which processes personal data on behalf of the controller.
                  </ul>

                  <ul>
                    "Special categories of personal data" means sensitive data, such as data
                    revealing racial or ethnic origin, political opinions, religious or
                    philosophical beliefs, trade union membership, genetic data, biometric data,
                    data concerning health or data concerning a natural person's sex life or sexual
                    orientation.
                  </ul>

                  <ul>
                    "Supervisory authority" means an independent public authority responsible for
                    monitoring the application of the GDPR, including investigating complaints and
                    conducting audits and inspections.
                  </ul>
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  2. Types of Personal Data Collected
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", mt: 4, mb: 2 }}
                >
                  <ul>
                    <li>Definition of personal data</li>
                    Personal data refers to information about an identified or identifiable person,
                    encompassing a wide range of identifiers such as name, location, or genetic
                    details. Under the GDPR, this data is considered a fundamental right, and those
                    handling it must ensure lawful, fair, and transparent processing. They should
                    collect data for specific, legitimate purposes, ensure its accuracy, and protect
                    it adequately. The GDPR emphasizes the need for maintaining data security,
                    confidentiality, and restricting unnecessary transfers.
                  </ul>

                  <ul>
                    <li>Types of personal data collected</li>
                    We gather and handle various types of personal data when you utilize our
                    Services, and the details and purposes are outlined below.
                  </ul>

                  <ul>
                    i) Information provided directly: When creating an account, you may furnish
                    personal data like your name, email, phone number, and date of birth. This data
                    is used for managing your account, verifying identity, communication, and
                    personalizing your experience.
                  </ul>

                  <ul>
                    ii) Information collected automatically: While using our Services, certain
                    information is collected automatically, including IP address, device type,
                    browser, and technical details. This data aids in enhancing service performance,
                    analyzing trends, and addressing technical issues.
                  </ul>

                  <ul>
                    iii) Usage information: Data on how you interact with our Services, such as
                    feature usage, visited pages, viewed content, and actions taken, is gathered.
                    This information is used to personalize your experience, understand preferences,
                    and improve Services.
                  </ul>

                  <ul>
                    iv) Location data: With your permission, we may collect and process your
                    location information to provide location-based services, including personalized
                    content and advertising.
                  </ul>

                  <ul>
                    v) Special categories of personal data: In certain instances, we may collect
                    sensitive data, like health information, if provided voluntarily or necessary
                    for specific Services. Processing such data requires explicit consent or
                    compliance with applicable law.
                  </ul>
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  WE WILL ONLY USE YOUR PERSONAL DATA FOR THE PURPOSES FOR WHICH WE COLLECTED IT,
                  UNLESS WE REASONABLY CONSIDER THAT WE NEED TO USE IT FOR ANOTHER REASON THAT IS
                  COMPATIBLE WITH THE ORIGINAL PURPOSE. IF WE NEED TO USE YOUR PERSONAL DATA FOR AN
                  UNRELATED PURPOSE, WE WILL NOTIFY YOU AND EXPLAIN THE LEGAL BASIS WHICH ALLOWS US
                  TO DO SO.
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", mt: 4, mb: 2 }}
                >
                  <ul>
                    <li>Information collected automatically</li>
                    Information collected automatically refers to data acquired by a website or
                    application without explicit user input, utilizing methods like cookies, log
                    fles, web beacons, and tracking technologies. This data enhances the user
                    experience, delivers pertinent content, and facilitates trend analysis.
                  </ul>

                  <ul>
                    Common examples include the user's IP address, browser type, operating system,
                    device type, language preference, and referral source. Log fles, storing user
                    activity details, aid in trend analysis and performance monitoring. Cookies,
                    small text fles, record user preferences and activities, personalizing
                    experiences and analyzing trends.
                  </ul>

                  <ul>
                    Web beacons, small embedded images, track user activity, contributing to trend
                    analysis. This information, collected non-intrusively, aims to enhance user
                    experience and provide relevant content. It is crucial to adhere to data
                    protection laws like GDPR when collecting and utilizing this information.
                  </ul>
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  3. How Data is Collected
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", mt: 4, mb: 2 }}
                >
                  <ul>
                    Personal data is collected through direct user interactions, such as signing up
                    for services, and indirectly via partners and third-party sources like social
                    media platforms. The information gathered includes details like name, email,
                    phone number, and job title, relevant to the services provided. The data
                    collection is purpose-driven, ensuring only necessary information is obtained,
                    and measures are in place to maintain accuracy and compliance with data
                    protection laws.
                  </ul>

                  <ul>
                    WE ONLY COLLECT DATA THAT IS NECESSARY FOR THE PURPOSES FOR WHICH IT IS
                    PROCESSED, AND WE TAKE APPROPRIATE MEASURES TO ENSURE THAT THE DATA WE COLLECT
                    IS ACCURATE, UP-TO-DATE, AND RELEVANT TO THE SERVICE WE PROVIDE. WE ALSO ENSURE
                    THAT ALL DATA IS COLLECTED AND PROCESSED IN COMPLIANCE WITH APPLICABLE DATA
                    PROTECTION LAWS.
                  </ul>
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  4. Legal Basis for Processing Data
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", mt: 4, mb: 2 }}
                >
                  <ul>
                    <li>Consent</li>
                    Individuals provide explicit and informed consent for the processing of their
                    personal data, allowing 7D Living to use their data for specific purposes.
                    Consent can be withdrawn at any time, particularly for activities like
                    marketing.
                  </ul>

                  <ul>
                    <li>Contractual Necessity</li>
                    Processing personal data may be necessary for fulfilling a contract or taking
                    pre-contractual steps as requested by the individual. For example, processing
                    data is essential to provide online services.
                  </ul>

                  <ul>
                    <li>Legal Obligation</li>
                    7D Living may process personal data to fulfil legal obligations, such as
                    retaining specific data for a mandated period. This processing is limited to
                    what is necessary to comply with legal requirements.
                  </ul>

                  <ul>
                    <li>Vital Interests</li>
                    Processing may occur when it's crucial to protect the vital interests of the
                    data subject or others, such as providing medical assistance during a medical
                    emergency.
                  </ul>

                  <ul>
                    <li>Legitimate Interests</li>
                    Processing is permitted when it's necessary for the legitimate interests of 7D
                    Living or a third party, unless overridden by the rights of the data subject. A
                    balancing of interests is required, for example, in fraud prevention.
                  </ul>
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  7D LIVING COMMITS TO PROCESSING PERSONAL DATA SOLELY FOR THE PURPOSES IT WAS
                  COLLECTED, REFRAINING FROM USING THE DATA FOR ANY OTHER PURPOSE WITHOUT ADDITIONAL
                  CONSENT OR UNLESS PERMITTED BY LAW. IF A NEW PURPOSE ARISES THAT ISN'T COMPATIBLE
                  WITH THE ORIGINAL ONE, 7D LIVING INFORMS DATA SUBJECTS AND SEEKS THEIR CONSENT IF
                  NECESSARY.
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  5. Use of Data
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", mt: 4, mb: 2 }}
                >
                  <ul>
                    <li>Service Provision</li>
                    Processing personal data is essential for delivering products and services,
                    including creating user accounts, facilitating content creation and sharing,
                    ofering customer support, and enhancing overall product and service quality.
                  </ul>

                  <ul>
                    <li>User Communication</li>
                    Personal data is processed to engage with users, addressing their inquiries,
                    sending newsletters or promotional messages, and providing updates on products
                    and services.
                  </ul>

                  <ul>
                    <li>Personalization</li>
                    Processing personal data is employed to tailor products and services to
                    individual user interests, preferences, and usage patterns. This involves
                    analysing user behaviour and interactions for a personalized experience.
                  </ul>

                  <ul>
                    <li>Legal Compliance</li>
                    Personal data is processed to fulfil legal obligations, responding to legal
                    requests, preventing fraud or illegal activities, and ensuring compliance with
                    terms of service.
                  </ul>

                  <ul>
                    <li>Product and Service Improvement</li>
                    Personal data is utilized for research and development, testing new features,
                    analysing usage patterns, and enhancing the overall quality and performance of
                    products and services.
                  </ul>

                  <ul>
                    <li>Sharing and International Data Transfers:</li>
                    Personal data may be shared with third-party service providers for service
                    provision and with business partners for marketing, subject to user consent
                    where required. International data transfers, even to countries with lower data
                    protection levels, will have appropriate safeguards in place, such as standard
                    contractual clauses or other legal mechanisms.
                  </ul>

                  <ul>
                    Users have the right to object to direct marketing and can exercise other data
                    subject rights under GDPR. For questions or concerns about personal data use,
                    users can refer to Section 10 for contact information.
                  </ul>
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  6. Consent
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", mt: 4, mb: 2 }}
                >
                  <ul>
                    <li>Requirements</li>
                    Consent is one of the legal bases for processing personal data under this
                    Policy. In order for consent to be valid, it must meet the following
                    requirements:
                  </ul>

                  <ul>
                    Freely given: Consent must be given voluntarily and without coercion. Users must
                    not be required to give consent in order to access a service, and consent must
                    not be a condition of a contract.
                  </ul>

                  <ul>
                    Specific: Consent must be specific to the purpose for which it is given. Users
                    must be informed of the exact purpose for which their data will be processed,
                    and must explicitly agree to that purpose.
                  </ul>

                  <ul>
                    Informed: Consent must be informed, meaning users must be provided with clear
                    and understandable information about the processing of their personal data,
                    including the identity of the data controller, the purposes of processing, and
                    any third-party recipients.
                  </ul>

                  <ul>
                    Unambiguous: Consent must be unambiguous, meaning it must be given through a
                    clear affirmative action. Pre-ticked boxes or inactivity do not constitute valid
                    consent.
                  </ul>

                  <ul>
                    Revocable: Users must have the right to withdraw their consent at any time, and
                    withdrawing consent must be as easy as giving it.
                  </ul>

                  <ul>
                    <li>How consent will be obtained</li>
                    We will obtain consent from users in a clear and transparent manner, and will
                    ensure that the consent meets the above requirements. We will use the following
                    methods to obtain consent:
                  </ul>

                  <ul>
                    Opt-in mechanisms: We will use clear and conspicuous opt-in mechanisms, such as
                    checkboxes or buttons, to obtain users' consent.
                  </ul>

                  <ul>
                    Granular consent: We will obtain separate and specific consent for each distinct
                    purpose of processing, where appropriate.
                  </ul>

                  <ul>
                    Records of consent: We will keep records of users' consent, including the
                    purpose of processing, the method of obtaining consent, and the date and time of
                    consent.
                  </ul>

                  <ul>
                    Withdrawal of consent: We will provide users with an easy and accessible way to
                    withdraw their consent, and will honour all requests to withdraw consent in a
                    timely manner.
                  </ul>

                  <ul>
                    If you have any questions or concerns about our use of consent or wish to
                    withdraw your consent, please contact us at the contact information provided in
                    Section 7.
                  </ul>
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  7. Rights of Data Subjects
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", mt: 4, mb: 2 }}
                >
                  <ul>
                    <li>Right to Access</li>
                    Allows individuals to confirm if their personal data is being processed and
                    obtain details about the categories, purposes, recipients, and retention
                    periods. They can request a copy in a commonly used electronic format.
                  </ul>

                  <ul>
                    <li>Right to Rectification</li>
                    Permits individuals to request correction of inaccurate or incomplete personal
                    data, considering the purposes for which it was processed.
                  </ul>

                  <ul>
                    <li>Right to Erasure</li>
                    Also known as the "right to be forgotten," allows individuals to request
                    deletion of personal data in specific circumstances, though legal or other
                    reasons may necessitate data retention.
                  </ul>

                  <ul>
                    <li>Right to Restrict Processing</li>
                    Enables individuals to limit the processing of their personal data in certain
                    situations. While data storage continues, processing ceases without consent
                    unless legally required or to protect another person's rights.
                  </ul>

                  <ul>
                    <li>Right to Data Portability</li>
                    Grants individuals the right to receive a structured, commonly used, and
                    machine-readable copy of their personal data for transfer to another data
                    controller, applicable to data provided with consent or based on a contract.
                  </ul>

                  <ul>
                    <li>Right to Object</li>
                    Allows individuals to object to personal data processing, particularly for
                    direct marketing or research purposes. Processing stops unless compelling
                    legitimate grounds override the individual's interests, rights, and freedoms, or
                    if needed for legal claims.
                  </ul>

                  <ul>
                    <li>Right to Withdraw Consent</li>
                    Permits individuals to withdraw consent for processing personal data based on
                    their consent. Withdrawal does not affect prior lawful processing. To exercise
                    any of these rights, please contact us using the details provided in the
                    "Contact Information" section below. We may need to verify your identity before
                    we can respond to your request. We will respond to your request as soon as
                    possible.
                  </ul>
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  8. Data Retention
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", mt: 4, mb: 2 }}
                >
                  <ul>
                    We retain personal data only for as long as necessary to fulfil the purposes for
                    which it was collected, unless a longer retention period is required by law or
                    our legitimate interests. The retention periods for different types of personal
                    data depend on various factors, including the nature of the data and the
                    purposes for which it was collected.
                  </ul>

                  <ul>
                    <li>Retention Periods</li>
                    Different types of personal data have varied retention periods determined by
                    factors like data nature and collection purposes.
                  </ul>

                  <ul>
                    Contractual data: Retained for the contract duration and a required
                    post-contract period. Marketing data: Retained until consent withdrawal or
                    objection exercise. Legal compliance data: Retained for the period mandated by
                    applicable law.
                  </ul>

                  <ul>
                    <li>Criteria for Determining Retention</li>
                    Periods Retention periods are decided based on:
                  </ul>

                  <ul>Purpose of data collection.</ul>
                  <ul>Nature of personal data.</ul>
                  <ul>Legal or regulatory obligations.</ul>
                  <ul>Our legitimate interests in retaining the data. .</ul>

                  <ul>
                    <li>Exceptions to Retention Periods</li>
                    Personal data may be retained longer than specified:
                  </ul>

                  <ul>To comply with legal obligations.</ul>
                  <ul>
                    To protect legitimate interests, e.g., resolving disputes, preventing fraud, or
                    enforcing agreements.
                  </ul>
                  <ul>
                    If we no longer need personal data for any purpose and are not required by law
                    to retain it, we will securely delete or destroy it in accordance with our data
                    retention and disposal policies.
                  </ul>
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  9. Changes to the Policy
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", mt: 4, mb: 2 }}
                >
                  <ul>
                    We reserve the right to modify this privacy policy at any time. If we make any
                    material changes to the policy, we will provide notice to data subjects through
                    our website, email, or other appropriate means. We will also indicate the date
                    of the most recent update to the policy.
                  </ul>
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  10. Contact Information
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", mt: 4, mb: 2 }}
                >
                  <ul>
                    <li>Data Controller Information</li>
                    As the data controller, 7D Living is responsible for collecting, processing, and
                    storing personal data in compliance with this Policy.
                  </ul>

                  <ul>
                    <li>Contact Information for Data Subject Requests</li>
                    If you would like to make a request to access, rectify, erase, restrict
                    processing, or port your personal data, you can contact us using the following
                    methods:
                  </ul>

                  <ul>Contact information:</ul>
                  <ul>
                    <Typography
                      variant={isMobile ? "subtitle2" : "subtitle1"}
                      sx={{ fontWeight: "600", textAlign: "left" }}
                    >
                      Desi LLM PVT. LTD.
                    </Typography>
                    <Typography
                      variant={isMobile ? "subtitle2" : "subtitle1"}
                      sx={{ fontWeight: "600", textAlign: "left" }}
                    >
                      CTS NO. 93, Krishnai Building, Raghuvendra Society Vinayak Nagar, Belagavi,
                      Karnataka 591108
                    </Typography>
                    <Typography
                      variant={isMobile ? "subtitle2" : "subtitle1"}
                      sx={{ fontWeight: "600", textAlign: "left" }}
                    >
                      support@7dliving.com
                    </Typography>
                    <Typography
                      variant={isMobile ? "subtitle2" : "subtitle1"}
                      sx={{ fontWeight: "600", textAlign: "left" }}
                    >
                      Mobile: +91 7975827078
                    </Typography>
                    <Typography
                      variant={isMobile ? "subtitle2" : "subtitle1"}
                      sx={{ fontWeight: "600", textAlign: "left" }}
                    >
                      https://www.7dliving.com
                    </Typography>
                  </ul>

                  <ul>
                    <li>Verification of identity:</li>
                    To protect the security and privacy of your personal data, we will ask you to
                    provide proof of your identity before we can process your request. We will
                    respond to your request within fifteen days of verifying your identity.
                  </ul>
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", my: 4 }}
                >
                  11. Complaints
                </Typography>

                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{ fontWeight: "600", textAlign: "left", mt: 4, mb: 2 }}
                >
                  <ul>
                    <li>Complaints Procedure</li>
                    We take all complaints about our handling of personal data seriously and are
                    committed to addressing them promptly and effectively.
                  </ul>

                  <ul>
                    Data subjects can submit a complaint about our data handling practices by
                    contacting us using the information provided in the Contact Information section
                    of this Privacy Policy.
                  </ul>

                  <ul>
                    We will acknowledge receipt of the complaint and provide the data subject with a
                    reference number for tracking purposes.
                  </ul>

                  <ul>
                    <li>Investigation and Resolution</li>
                    We will investigate the complaint promptly and thoroughly, taking into account
                    all relevant circumstances and any applicable legal requirements.
                  </ul>

                  <ul>
                    We may request additional information or documentation from the data subject or
                    any third parties involved in order to assist with the investigation.
                  </ul>

                  <ul>
                    We will notify the data subject of the outcome of the investigation and any
                    remedial action taken as a result of the complaint.
                  </ul>

                  <ul>
                    <li>Timeframes</li>
                    We aim to respond to all complaints within 30 days of receipt. However, in some
                    cases, we may require additional time to investigate the complaint fully. In
                    such cases, we will notify the data subject of the reason for the delay and
                    provide an estimated timeframe for resolution.
                  </ul>

                  <ul>
                    <li>No Retaliation</li>
                    We will not retaliate against any data subject for submitting a complaint about
                    our data handling practices.
                  </ul>

                  <ul>
                    We will take appropriate measures to prevent any retaliation by employees,
                    service providers or contractors against data subjects who submit a complaint.
                  </ul>
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </motion.div>
  );
};

export default PrivacyPolicy;
