import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import { IoIosSave } from "react-icons/io";
import { useAuth } from "../../AuthContext";
import { useDispatch, useSelector } from "react-redux";
import {
  SetRoutingPages,
  setActiveSteps,
  setCurrentRoute,
  setQuestions,
  setSelectedAnswers,
  setNextButton,
} from "../../Redux/rootSlice";

const AnalysisButton = ({ isMobile }) => {
  const state = useSelector((state) => state);
  const categoryId = state.routingIds?.categories?.categoryIds[0];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { step, activeSteps, routingData, routingPages, testType, currentRoute, nextButton } =
    state;

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  };

  const updateResults = async (newRouting) => {
    const userResults = {
      userResults: {
        categoryId: categoryId,
        questions: Object.entries(state.selectedAnswers).map(([questionId, answerId]) => {
          const qna = state.questions.find((qna) => qna._id === questionId);
          const selectedAnswer = qna.answers.find((answer) => answer.answer === answerId);
          return {
            questionId,
            answerId: selectedAnswer._id,
            results: selectedAnswer.results.map((result) => ({
              result: result.result,
              value: result.value,
            })),
          };
        }),
      },
    };
    try {
      if (user && user.userId && user.role) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/ayurveda-resource/post/partial/user/result/${user.userId}/${testType}`,
          userResults,
          { withCredentials: true },
        );
        if (response.status === 200) {
          toast.success("Answers saved successfully.");
          dispatch(setQuestions([]));
          dispatch(setSelectedAnswers({}));
          navigate(routingData[newRouting].path);
        }
      } else {
        toast.error("Failed to save answers. Please try again.");
        return null;
      }
    } catch (error) {
      console.error("Error saving answers:", error);
      toast.error("Failed to save answers. Please try again.");
    }
  };

  const saveUserResults = async (newRouting) => {
    const userResults = {
      userResults: {
        categoryId: categoryId,
        questions: Object.entries(state.selectedAnswers).map(([questionId, answerId]) => {
          const qna = state.questions.find((qna) => qna._id === questionId);
          const selectedAnswer = qna.answers.find((answer) => answer.answer === answerId);
          return {
            questionId,
            answerId: selectedAnswer._id,
            results: selectedAnswer.results.map((result) => ({
              result: result.result,
              value: result.value,
            })),
          };
        }),
      },
    };

    const unansweredQuestions = state.questions.filter((qna) => !state.selectedAnswers[qna._id]);
    try {
      if (!(unansweredQuestions.length > 0)) {
        if (user && user.userId && user.role) {
          console.log("userResults",userResults)
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/ayurveda-resource/post/partial/user/result/${user.userId}/${testType}`,
            userResults,
            { withCredentials: true },
          );
          if (response.status === 200) {
            toast.success("Answers saved successfully.");
            dispatch(setQuestions([]));
            dispatch(setSelectedAnswers({}));
            if (newRouting) {
              let progressPerct = {
                page: "",
                path: "",
                progress: "",
              };
              progressPerct.page = newRouting;
              progressPerct.path = routingData[newRouting].path;
              progressPerct.progress = 0;
              dispatch(SetRoutingPages(newRouting));
              if (
                !currentRoute?.map((route) => route.path)?.includes(routingData[newRouting]?.path)
              ) {
                dispatch(setCurrentRoute([...currentRoute, progressPerct]));
              }
              navigate(routingData[newRouting].path);
            } 
            else {
              const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/ayurveda-resource/post/finish/user/result/${user.userId}/${state.testType}`,
                userResults,
                { withCredentials: true },
              );
              console.log("responseResult",response)
              if (response.status !== 200) {
                throw new Error("Failed to submit the analysis");
              } else {
                switch (String(state.testType)) {
                  case "dosha":
                    navigate("/dosha-report");
                    break;
                  case "physical":
                    navigate("/physical-report");
                    break;
                  case "mental":
                    navigate("/mental-report");
                    break;
                  default:
                    toast.error("The submission of your analysis failed, please try again later");
                    throw new Error(`Invalid categoryGroupNumber: ${state.testType}`);
                }
                toast.success("Your analysis has been successfully submitted");
              }
            }
          } else {
            toast.error("Failed to save answers. Please try again.");
            return null;
          }
        }
      } else {
        toast.error(
          `Answered ${state.questions.length - unansweredQuestions.length} out of ${state.questions.length} Please answer all questions before submitting.`,
        );
      }
    } catch (error) {
      console.error("Error saving answers:", error);
      toast.error("Failed to save answers. Please try again.");
    }
  };

  const addQuestionStepsFn = async (subCategoryId) => {
    try {
      const { userId, role } = user;

      if (userId && role && subCategoryId) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/ayurveda-resource/get/selected/subcategory/questions/${subCategoryId}/${userId}/${testType}/${role}`,
          { withCredentials: true },
        );
        if (response && response.status === 200 && response.data && response.data.data) {
          if (response.data?.result) {
            let questions = response.data.data.map((ele) => ele._id);
            let answers = Object.keys(response.data?.result);
            if (questions.every((item) => answers.includes(item))) {
              return questions.length;
            } else {
              return null;
            }
          }
        }
      } else {
        console.error("Missing required parameters:", {
          userId,
          role,
          subCategoryId,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNext = async () => {
    try {
      dispatch(setNextButton(true)); // Initial dispatch to set the button to true

      if (routingPages < routingData?.length) {
        if (!(routingPages === routingData.length - 1)) {
          let newRouting = routingPages + 1;

          if (routingData[routingPages].path.includes("question")) {
            let subCategoryId = routingData[routingPages].path.split("/").pop();
            let addQuestionSteps = await addQuestionStepsFn(subCategoryId);

            if (addQuestionSteps) {
              console.log("addQuestionSteps",addQuestionSteps)
              let progressPerct = {
                page: newRouting,
                path: routingData[newRouting].path,
                progress: (addQuestionSteps / step) * 100,
              };

              dispatch(SetRoutingPages(newRouting));

              if (
                !currentRoute?.map((route) => route.path)?.includes(routingData[newRouting]?.path)
              ) {
                dispatch(setCurrentRoute([...currentRoute, progressPerct]));
              }

              updateResults(newRouting);
            } else {
              saveUserResults(newRouting);
            }
          } else {
            let progressPerct = {
              page: newRouting,
              path: routingData[newRouting].path,
              progress: (1 / step) * 100,
            };

            dispatch(SetRoutingPages(newRouting));

            if (
              !currentRoute?.map((route) => route.path)?.includes(routingData[newRouting]?.path)
            ) {
              dispatch(setCurrentRoute([...currentRoute, progressPerct]));
            }

            navigate(routingData[newRouting].path);
          }
        }
      }
    } catch (error) {
      console.error("Error in handleNext:", error);
    } finally {
      dispatch(setNextButton(false)); // Ensure the button is set to false after operations
    }
  };

  const handleFinish = async () => {
    try {
      if (user && user.userId) {
        if (state.questions) {
          if (user.role === "admin") {
            return;
          }
          saveUserResults(null);
        }
      } else {
        throw new Error("User details are missing");
      }
    } catch (error) {
      console.error("Error in handleFinish:", error.message);
      toast.error("The submission of your analysis failed, please try again later");
    } finally {
      dispatch(setNextButton(false));
    }
  };

  const handlePrevious = () => {
    if (routingPages > 0) {
      let newRouting = routingPages - 1;
      dispatch(SetRoutingPages(newRouting));
      navigate(routingData[newRouting].path);
    }
  };
  return (
    <motion.div variants={variants} initial="hidden" animate="visible">
      <Stack direction="row" spacing={isMobile ? 5 : 30} sx={{ my: isMobile ? 10 : 5 }}>
        <Button
          variant="contained"
          fullWidth
          onClick={handlePrevious}
          disabled={!(routingPages > 0)}
          startIcon={<MdSkipPrevious size={isMobile ? 24 : 32} />}
          sx={{
            backgroundColor: "rgba(140, 4, 54, 0.8)",
            "&:hover": { backgroundColor: "#8C0430" },
            "&:disabled": {
              backgroundColor: "#bcbcbc",
              color: "#666666",
              cursor: "not-allowed",
            },
            borderRadius: "30px",
            height: "40px",
            fontSize: isMobile ? "0.9rem" : "1.2rem",
            fontWeight: "bold",
          }}
          size="large"
        >
          Previous
        </Button>
        <Button
          variant="contained"
          fullWidth
          endIcon={
            routingPages === routingData.length-1 ? (
              <IoIosSave size={isMobile ? 24 : 32} />
            ) : (
              <MdSkipNext size={isMobile ? 24 : 32} />
            )
          }
          onClick={routingPages === routingData.length-1 ? handleFinish : handleNext}
          sx={{
            backgroundColor: "rgba(140, 4, 54, 0.8)",
            "&:hover": { backgroundColor: "#8C0430" },
            borderRadius: "30px",
            height: "40px",
            fontSize: isMobile ? "0.9rem" : "1.2rem",
            fontWeight: "bold",
            "&:disabled": {
              backgroundColor: "#bcbcbc",
              color: "#666666",
              cursor: "not-allowed",
            },
          }}
          disabled={nextButton}
        >
          {routingPages === routingData.length-1 ? "Submit" : "Next"}
        </Button>
      </Stack>
    </motion.div>
  );
};

export default AnalysisButton;
