/* eslint-disable no-unused-vars */
import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Box, Grid, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { MdSkipNext } from "react-icons/md";
import { MdSkipPrevious } from "react-icons/md";
import { IoIosSave } from "react-icons/io";
import { useAuth } from "../../AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { setPatientId } from "../../Redux/rootSlice";

const IntakeFormButton = ({ state, dispatch, activeStep, setActiveStep, isMobile }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const patientId = useSelector((state) => state.patientId);
  const reduxDispatch = useDispatch();

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  };

  const handleNext = () => {
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = async () => {
    try {
      if (user && user.userId) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/patient/post/inTakeForm/${user.userId}/${patientId}`,
          { patientIntakeForm: state },
          { withCredentials: true },
        );

        if (response && response.status === 200) {
          toast.success("Your information has been successfully submitted. Well done!");

          navigate("/home");

          reduxDispatch(setPatientId(""));
        } else {
          toast.error("Oops! It seems there's an error. Please try again after some time");
        }
      }
    } catch (error) {
      if (error && error.response && [400, 404, 409, 500].includes(error.response.status)) {
        toast.error("Oops! It seems there's an error. Please try again after some time");
      } else {
        console.error("error saving data:", error);
        toast.error("An error occurred while saving data");
      }
    }
  };

  return (
    <motion.div variants={variants} initial="hidden" animate="visible">
      <Grid container spacing={isMobile ? undefined : 15} justifyContent="center">
        <Grid item xs={8} sm={6}>
          <Button
            variant="contained"
            fullWidth
            disabled={activeStep === 0}
            onClick={handleBack}
            startIcon={<MdSkipPrevious size={isMobile ? 24 : 32} />}
            style={{
              background: activeStep === 0 ? "#D3D3D3" : "#2B2A29", // Use grey for disabled state
              borderRadius: "40px",
              fontSize: isMobile ? "1rem" : "1.3rem",
              textTransform: "capitalize",
              marginTop: 20,
              color: activeStep === 0 ? "#A9A9A9" : "#FFFFFF",
            }}
          >
            Previous
          </Button>
        </Grid>
        <Grid item xs={8} sm={6}>
          <Button
            variant="contained"
            fullWidth
            endIcon={
              activeStep === 3 ? (
                <IoIosSave size={isMobile ? 24 : 32} />
              ) : (
                <MdSkipNext size={isMobile ? 24 : 32} />
              )
            }
            onClick={activeStep === 3 ? handleFinish : handleNext}
            style={{
              background: "#2B2A29",
              borderRadius: "40px",
              fontSize: isMobile ? "1rem" : "1.3rem",
              textTransform: "capitalize",
              marginTop: 20,
            }}
          >
            {activeStep === 3 ? "Submit" : "Next"}
          </Button>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default IntakeFormButton;
