import React, { useEffect } from "react";
import { TextField, IconButton, Box, Tooltip } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import SendIcon from "@mui/icons-material/Send";
import { toast } from "react-toastify";
import axios from "axios";
import { useAuth } from "../../AuthContext";

let recognition = null;

const supportedLanguages = {
  Assamese: "as-IN",
  Bengali: "bn-IN",
  Gujarati: "gu-IN",
  Hindi: "hi-IN",
  Kannada: "kn-IN",
  Kashmiri: "ks-IN",
  Konkani: "kok-IN",
  Malayalam: "ml-IN",
  Marathi: "mr-IN",
  Nepali: "ne-IN",
  Punjabi: "pa-IN",
  Sanskrit: "sa-IN",
  Sindhi: "sd-IN",
  Tamil: "ta-IN",
  Telugu: "te-IN",
  Urdu: "ur-IN",
  English: "en-IN",
};

if ("webkitSpeechRecognition" in window) {
  const WebkitSpeechRecognition = window.webkitSpeechRecognition;
  recognition = new WebkitSpeechRecognition();
  recognition.continuous = true;
  recognition.interimResults = true;
  recognition.lang = supportedLanguages.English;
} else {
  recognition = null; // Speech recognition not supported
}

const Footer = ({ state, dispatch, promptText, sessionType, isMobile }) => {
  const { user } = useAuth();

  const toggleListening = () => {
    if (!recognition) {
      toast.error("Speech recognition is not supported in this browser.");
      return;
    }

    if (state.isListening) {
      recognition.stop(); // Stop listening if it's currently running
      dispatch({ type: "set_is_listening", payload: false });
    } else {
      try {
        recognition.start(); // Start listening if it's not running
        dispatch({ type: "set_is_listening", payload: true });
      } catch (e) {
        toast.error("Speech recognition couldn't start. Please try again.");
      }
    }
  };

  const handleSend = async () => {
    if (state.userText.trim()) {
      dispatch({ type: "set_typing", payload: true });

      const newUserMessage = { sender: "user", text: state.userText };

      dispatch({
        type: "set_messages",
        payload: [...state.messages, newUserMessage],
      });

      if (recognition && state.isListening) {
        recognition.stop(); // Stop listening when sending a message
        dispatch({ type: "set_is_listening", payload: false });
      }

      try {
        if (user && user.userId) {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/chat-bot/post/ai/message/${user.userId}`,
            {
              message: state.userText,
              sessionId: state.sessionId,
              sessionType: sessionType,
              prompt: promptText,
            },
            { withCredentials: true },
          );

          if (response && response.status === 200 && response.data && response.data.data) {
            const aiContent = response.data.data;

            dispatch({
              type: "set_messages",
              payload: [...state.messages, newUserMessage, { sender: "ai", text: aiContent }],
            });

            dispatch({ type: "set_user_text", payload: "" });
          } else {
            dispatch({ type: "set_user_text", payload: "" });

            dispatch({
              type: "set_messages",
              payload: [
                ...state.messages,
                newUserMessage,
                { sender: "ai", text: "Failed, please try after some time" },
              ],
            });
          }
        }
      } catch (error) {
        dispatch({
          type: "set_messages",
          payload: [
            ...state.messages,
            newUserMessage,
            { sender: "ai", text: "Failed, please try after some time" },
          ],
        });
        dispatch({ type: "set_user_text", payload: "" });
      }

      dispatch({ type: "set_typing", payload: false });
    }
  };

  useEffect(() => {
    if (recognition) {
      recognition.onresult = (event) => {
        let finalText = "";
        let interimTextResult = ""; // Used to store current interim text

        for (let i = 0; i < event.results.length; i++) {
          const result = event.results[i];
          if (result.isFinal) {
            finalText += result[0].transcript; // Final text
          } else {
            interimTextResult += result[0].transcript; // Interim results
          }
        }

        // Add interim text to the current user text
        const newText = finalText + interimTextResult;

        if (newText !== state.userText) {
          dispatch({ type: "set_user_text", payload: newText });
        }
      };

      recognition.onend = () => {
        dispatch({ type: "set_is_listening", payload: false });
      };
    }
  }, [state.userText, dispatch]);

  const handleTextChange = (e) => {
    dispatch({ type: "set_user_text", payload: e.target.value });
  };

  return (
    <Box display="flex" alignItems="center">
      <TextField
        color="secondary"
        variant="outlined"
        fullWidth
        autoComplete="off"
        value={state.userText}
        onChange={handleTextChange}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSend();
          }
        }}
        InputProps={{
          endAdornment: (
            <Tooltip title={state.isListening ? "Stop Listening" : "Start Listening"} arrow>
              <IconButton onClick={toggleListening}>
                {state.isListening ? <MicIcon /> : <MicOffIcon />}
              </IconButton>
            </Tooltip>
          ),
          style: { fontSize: isMobile ? "0.8rem" : "1rem", fontWeight: 600 },
        }}
      />
      <Tooltip title="Send Message" arrow>
        <IconButton color="secondary" onClick={handleSend}>
          <SendIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default Footer;
