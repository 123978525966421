/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../Header";
import { motion } from "framer-motion";
import { Box, Typography, Grid, Tooltip, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import ReactPlayer from "react-player";
import { DNA } from "react-loader-spinner";
import { useScroll } from "../../ScrollContext";
import ChatBot from "../ChatBot/ChatBot";

const CompleteReport = ({ isMobile }) => {
  const [topicData, setTopicData] = useState([]);
  const [promptText, setPromptText] = useState("");

  const navigate = useNavigate();

  const topicCode = useSelector((state) => state.topicCode);

  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleImgClick = () => {
    navigate("/home");
  };

  const fetchData = async () => {
    try {
      if (topicCode) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/report/get/complete/${topicCode}`,
          { withCredentials: true },
        );

        if (response && response.status === 200) {
          if (response.data && response.data.data) {
            setTopicData(response.data.data);
            let updatePrompt = {};
            updatePrompt.heading = response.data.data.topicHeading;
            updatePrompt.description = response.data.data.topicDescription;
            setPromptText(updatePrompt);
          } else {
            setTopicData(null);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setTopicData(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, [topicCode]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/others/Report.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Header />
      </Box>
      <Tooltip title="Navigate to Home Page" arrow>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
          alt="Overlay"
          style={{
            position: "absolute",
            cursor: "pointer",
            top: 0,
            left: 0,
            width: isMobile ? "110px" : "135px",
            height: isMobile ? "110px" : "135px",
            zIndex: 1,
          }}
          onClick={handleImgClick}
        />
      </Tooltip>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mx: 4, mb: isMobile ? 12 : 6 }}
      >
        <Paper
          elevation={3}
          style={{
            borderRadius: "20px",
            padding: isMobile ? "20px" : "40px",
            width: isMobile ? "90%" : "70%",
            height: "auto",
            marginTop: isMobile ? "45%" : "5%",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
          }}
        >
          {topicData ? (
            <Box>
              <Box
                sx={{
                  textAlign: "center",
                  backgroundColor: "#721F4B",
                  color: "white",
                  borderRadius: "3px",
                  fontWeight: "bold",
                  fontSize: isMobile ? "1.2rem" : "1.5rem",
                  padding: 1,
                }}
              >
                {topicData.topicHeading}
              </Box>

              {topicData.topicVideoLink && (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: isMobile ? "100%" : "80%",
                      height: isMobile ? "200px" : "300px",
                      borderRadius: "20px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 1)",
                      border: "2px solid #ccc",
                      overflow: "hidden",
                    }}
                  >
                    <ReactPlayer url={topicData.topicVideoLink} width="100%" height="100%" />
                  </Box>
                </Grid>
              )}

              <Typography
                sx={{
                  textAlign: "justify",
                  fontWeight: 600,
                  fontSize: isMobile ? "0.9rem" : "1rem",
                  textTransform: "uppercase",
                }}
                paragraph
                dangerouslySetInnerHTML={{
                  __html: topicData.topicDescription,
                }}
              />
              {topicData.topicImage && (
                <Grid container justifyContent="center" alignItems="center">
                  <img
                    width={isMobile ? "100%" : "60%"}
                    height={isMobile ? "100%" : "60%"}
                    src={`https://7dstatic.s3.ap-south-1.amazonaws.com/7dimages/${topicData.topicImage}`}
                    alt="preview"
                    loading="lazy"
                    style={{
                      marginTop: isMobile ? "8px" : "16px",
                      marginBottom: isMobile ? "8px" : "5px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 1)",
                      border: "2px solid #ccc",
                    }}
                  />
                </Grid>
              )}
            </Box>
          ) : (
            <motion.div>
              <DNA
                visible={true}
                height={isMobile ? "120" : "200"}
                width={isMobile ? "200" : "250"}
                color="#2B2A29"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </motion.div>
          )}
        </Paper>
      </Grid>
      <ChatBot promptText={promptText} sessionType={"info"} isMobile={isMobile} />
    </motion.div>
  );
};

export default CompleteReport;
