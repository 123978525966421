import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";

const DoshaCombinationTable = ({ isMobile, tableData }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const rowsWithHandlers = tableData.map((entry, index) => ({
    id: index,
    DoshaCombination: entry.SubdoshaCombination,
    Risk: entry.LifestyleDisorders,
    graphValue: entry.graphValue || "",
    Reasoning: entry.reasoning || "",
    RiskLevel:
      entry.graphValue === 1
        ? "Moderate"
        : entry.graphValue === 2
        ? "High"
        : entry.graphValue === 3
        ? "Very High"
        : "",
  }));

  return (
    <div style={{ width: "100%" }}>
      <TableContainer component={Paper} style={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              {[
                { id: "DoshaCombination", label: "Imbalance" },
                { id: "Risk", label: "Risk" },
                { id: "RiskLevel", label: "Risk Level" },
                { id: "Reasoning", label: "Reasoning" },
              ].map((headCell) => (
                <TableCell
                  key={headCell.id}
                  style={{ backgroundColor: "#721F4B", color: "#fff" }}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsWithHandlers.map((row, index) => (
              <TableRow key={index} style={{ borderBottom: "1px solid #ccc" }}>
                <TableCell
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    lineHeight: "1.5",
                  }}
                >
                  {row.DoshaCombination}
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    lineHeight: "1.5",
                  }}
                >
                  {row.Risk}
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    lineHeight: "1.5",
                  }}
                >
                  {row.RiskLevel}
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    lineHeight: "1.5",
                  }}
                >
                  {row.Reasoning}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rowsWithHandlers.length === 0 && (
        <Typography
          variant={isMobile ? "body1" : "body2"}
          align="center"
          sx={{ mt: "3%", textTransform: "uppercase" }}
        >
          No Data Found
        </Typography>
      )}
    </div>
  );
};

export default DoshaCombinationTable;
