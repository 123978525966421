import React, { useReducer } from "react";
import { Container, Paper, Popper, Tooltip } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import LoginFields from "./LoginFields";
import LoginOtp from "./LoginOtp";
import LoginMobileField from "./LoginMobileField";
import LoginMobileOtp from "./LoginMobileOtp";
import countries from "../../country.json";

const initialState = {
  component: "login fields",
  name: "",
  selectedCountry: countries.find((country) => country.name === "India"),
  countryCode: "+91",
  mobileNumber: "",
  email: "",
  gender: "",
  age: "",
  otp: "",
  mobileNumberFlag: "",
  otpFlag: false,
  loginNumber: "",
  loginCountry: countries.find((country) => country.name === "India"),
  loginMobileCode: "+91",
  loginNumberFlag: "",
  loginOtp: "",
  loginOtpFlag: false,
  showLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_component":
      return { ...state, component: action.payload };
    case "set_name":
      return { ...state, name: action.payload };
    case "set_selected_country":
      return { ...state, selectedCountry: action.payload };
    case "set_country_code":
      return { ...state, countryCode: action.payload };
    case "set_mobile_number":
      return { ...state, mobileNumber: action.payload };
    case "set_email":
      return { ...state, email: action.payload };
    case "set_gender":
      return { ...state, gender: action.payload };
    case "set_age":
      return { ...state, age: action.payload };
    case "set_otp":
      return { ...state, otp: action.payload };
    case "set_mobile_number_flag":
      return { ...state, mobileNumberFlag: action.payload };
    case "set_otp_flag":
      return { ...state, otpFlag: action.payload };
    case "set_login_number":
      return { ...state, loginNumber: action.payload };
    case "set_login_country":
      return { ...state, loginCountry: action.payload };
    case "set_login_mobile_code":
      return { ...state, loginMobileCode: action.payload };
    case "set_login_number_flag":
      return { ...state, loginNumberFlag: action.payload };
    case "set_login_otp":
      return { ...state, loginOtp: action.payload };
    case "set_login_otp_flag":
      return { ...state, loginOtpFlag: action.payload };
    case "set_show_loading":
      return { ...state, showLoading: action.payload };
    default:
      return state;
  }
};

const Login = ({ isMobile }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  };

  const handleImgClick = () => {
    navigate("/");
  };

  const handleCloseLoading = (logic) => {
    dispatch({
      type: "set_show_loading",
      payload: logic,
    });
  };

  const CustomPopper = (props) => {
    return (
      <Popper
        {...props}
        modifiers={[
          {
            name: "setWidth",
            enabled: true,
            phase: "beforeWrite",
            requires: ["computeStyles"],
            fn: ({ state }) => {
              state.styles.popper.width = "300px"; // Custom width
            },
          },
        ]}
        placement="bottom-start" // Custom placement if needed
      />
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundImage: `url(${
          isMobile
            ? `${process.env.PUBLIC_URL}/images/Portrait-Image.jpg`
            : `${process.env.PUBLIC_URL}/images/Landscape-Image.jpg`
        })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Tooltip title="Navigate to Main Page" arrow>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
          alt="Overlay"
          onClick={handleImgClick}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: isMobile ? "110px" : "135px",
            height: isMobile ? "110px" : "135px",
            zIndex: 1,
            cursor: "pointer",
          }}
        />
      </Tooltip>

      <Container maxWidth={isMobile ? "sm" : "md"}>
        <AnimatePresence>
          <Paper
            elevation={24}
            style={{
              padding: isMobile ? "30px" : "80px",
              zIndex: 2,
              borderRadius: "40px",
              marginTop: isMobile ? "35%" : "18%",
              marginBottom: "10%",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            }}
          >
            {state.component === "login fields" && (
              <LoginFields
                state={state}
                dispatch={dispatch}
                closeLoading={handleCloseLoading}
                variants={variants}
                isMobile={isMobile}
                CustomPopper={CustomPopper}
              />
            )}
            {state.component === "login otp" && (
              <LoginOtp
                state={state}
                dispatch={dispatch}
                closeLoading={handleCloseLoading}
                variants={variants}
                isMobile={isMobile}
              />
            )}
            {state.component === "login mobile fields" && (
              <LoginMobileField
                state={state}
                dispatch={dispatch}
                closeLoading={handleCloseLoading}
                variants={variants}
                isMobile={isMobile}
                CustomPopper={CustomPopper}
              />
            )}
            {state.component === "login mobile otp" && (
              <LoginMobileOtp
                state={state}
                dispatch={dispatch}
                closeLoading={handleCloseLoading}
                variants={variants}
                isMobile={isMobile}
              />
            )}
          </Paper>
        </AnimatePresence>
      </Container>
    </motion.div>
  );
};

export default Login;
