/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Box, Button, Grid, Paper, Tooltip, Typography, Link } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../Header";
import { useNavigate } from "react-router";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import { toast } from "react-toastify";
import { DNA } from "react-loader-spinner";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { setPatientId, setRoutePath } from "../../../Redux/rootSlice";

const theme = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem", // Change this to your desired font size
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          fontSize: "1.2rem", // Adjust month/year label size
        },
      },
    },
    MuiPickersToolbarText: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem", // Adjust toolbar text size
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1.2rem", // Default font size for buttons
          fontWeight: "bold",
        },
        textPrimary: {
          color: "#721F4B", // Color for primary text buttons
        },
      },
    },
  },
});

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const NewAppointment = ({ isMobile }) => {
  const routePath = useSelector((state) => state.routePath);
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [nonAvailableDates, setNonAvailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [date, setDate] = useState("");
  const [timings, setTimings] = useState([]);
  const [time, setTime] = useState("");
  const [appointment, setAppointment] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [buttonState, setButtonState] = useState(false);

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  };

  useEffect(() => {
    if (routePath) {
      reduxDispatch(setRoutePath(""));
    }
  }, []);

  const handleImgClick = () => {
    navigate("/home");
  };

  const fetchNonAvailableSlots = async () => {
    try {
      if (selectedDate) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/appointment/get/non-available/slots/${selectedDate}`,
          { withCredentials: true },
        );

        if (response && response.status === 200 && response.data && response.data.data[0]) {
          setLoading(false);

          setNonAvailableDates(response.data.data);
        } else {
          setLoading(false);

          setNonAvailableDates([]);
        }
      }
    } catch (error) {
      setNonAvailableDates([]);
      if (error && error.response && [400, 404, 409, 500].includes(error.response.status)) {
        setLoading(false);

        toast.error("An error occurred while retrieving unavailable time slots");
      } else {
        setLoading(false);

        console.error("Error fetching non available time slots", error);
        toast.error("An error occurred while retrieving unavailable time slots");
      }
    }
  };

  useEffect(() => {
    fetchNonAvailableSlots();
  }, [selectedDate]);

  const fetchAvailableSlots = async () => {
    try {
      if (date) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/appointment/get/available/slots/${date.format("YYYY-MM-DD")}`,
          { withCredentials: true },
        );

        if (response.status === 200 && response.data.data[0]) {
          setTimings(response.data.data);
          setTime(response.data.data[0]);
          setShowButton(true);
          setAppointment(null);
        } else {
          setTimings("");
          setShowButton(false);
          setTime("");
          setAppointment("Unfortunately, no time slots are available for the selected date");
        }
      }
    } catch (error) {
      if (error && error.response && [400, 404, 409, 500].includes(error.response.status)) {
        setTime("");
        setShowButton(false);

        setTimings("");

        setAppointment("Unfortunately, no time slots are available for the selected date");

        toast.error("Unfortunately, no time slots are available for the selected date");
      } else {
        console.error("Error fetching time slots", error);

        toast.error("There was a problem. Please select an alternative date");
      }
    }
  };

  useEffect(() => {
    fetchAvailableSlots();
  }, [date]);

  const handleOpen = () => {
    if (!selectedDate) {
      const currentDate = new Date(); // Date object with the current date and time

      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");

      setSelectedDate(`${year}-${month}-${day}`);
    }
  };

  const handleMonthChange = (date) => {
    setLoading(true);

    setSelectedDate(date.format("YYYY-MM-DD"));
  };

  const handleTimeClick = (selectedTime) => {
    setTime(selectedTime);
  };

  const displayRazorpay = async () => {
    setButtonState(true);
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      setButtonState(true);
      return;
    }

    try {
      if (user && user.userName && user.userEmail && user.userNumber) {
        const apiResponse1 = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/payment/post/request/razorpay`,
          {
            userId: user.userId,
            subscription_amt: 500,
            date: date.format("YYYY-MM-DD"),
            startTime: time,
          },
          { withCredentials: true },
        );

        if (
          apiResponse1 &&
          apiResponse1.status === 200 &&
          apiResponse1.data &&
          apiResponse1.data.data
        ) {
          const data = apiResponse1.data.data;

          const options = {
            key: "rzp_test_1b8uLzuDgyrRDJ",
            currency: data.currency,
            amount: data.amount.toString(),
            order_id: data.id,
            name: "Appointment Consultation",
            description: "Subscription Being Processed...",
            image: "https://7dstatic.s3.ap-south-1.amazonaws.com/website/7DCompanyLogo.png",
            handler: async function (response) {
              try {
                if (
                  response &&
                  response.razorpay_payment_id &&
                  response.razorpay_order_id &&
                  response.razorpay_signature &&
                  user
                ) {
                  setShowLoading(true);
                  const apiResponse2 = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/payment/post/verification/razorpay`,
                    {
                      user,
                      plan: { name: "Appointment Consultation", amount: 500 },
                      response,
                      date: date.format("YYYY-MM-DD"),
                      startTime: time,
                    },
                    { withCredentials: true },
                  );

                  if (apiResponse2?.status === 200 && apiResponse2?.data?.data) {
                    toast.success("You're all set! Appointment booked successfully");
                    toast.success(
                      "Please complete the intake form to provide your personal medical details.",
                    );

                    reduxDispatch(setPatientId(apiResponse2.data.data));

                    navigate("/intake-form");
                  } else {
                    toast.error("Failed to schedule appointment consultation.");
                  }

                  setShowLoading(false);
                }
              } catch (error) {
                if (
                  error &&
                  error.response &&
                  [400, 404, 409, 500].includes(error.response.status)
                ) {
                  toast.error(
                    "Sorry, we couldn't schedule your appointment for the selected date. Please choose a different one",
                  );
                } else {
                  console.error("Error occured while scheduling appointment", error);

                  toast.error(
                    "Sorry, we couldn't schedule your appointment for the selected date. Please choose a different one",
                  );
                }

                setShowLoading(false);
              }
            },
            prefill: {
              name: user.userName,
              email: user.userEmail,
              contact: user.userNumber.replace(/\s/g, ""),
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
      }
      setButtonState(false);
    } catch (error) {
      // Handle error
      setButtonState(false);
      if (error && error.response) {
        if ([400, 404, 500].includes(error.response.status)) {
          toast.error(
            "Sorry, we couldn't schedule your appointment for the selected date. Please choose a different one",
          );
        } else if (error.response.status === 409) {
          toast.error(
            "An appointment has already been scheduled for the selected date and time. Please choose a different slot.",
          );
        } else {
          console.error("Error occurred while scheduling appointment", error);
          toast.error(
            "Sorry, we couldn't schedule your appointment for the selected date. Please choose a different one",
          );
        }
      } else {
        console.error("Error occurred while scheduling appointment", error);
        toast.error(
          "Sorry, we couldn't schedule your appointment for the selected date. Please choose a different one",
        );
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/others/Appointment.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Header />
      </Box>

      <Tooltip title="Navigate to Home Page" arrow>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
          alt="Overlay"
          onClick={handleImgClick}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: isMobile ? "110px" : "135px",
            height: isMobile ? "110px" : "135px",
            zIndex: 1,
            cursor: "pointer",
          }}
        />
      </Tooltip>
      <Paper
        elevation={24}
        style={{
          position: "relative",
          maxWidth: isMobile ? "80%" : "50%",
          width: "100%",
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: isMobile ? "30px" : "50px",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        }}
      >
        {showLoading ? (
          <motion.div>
            <DNA
              visible={true}
              height={isMobile ? "120" : "200"}
              width={isMobile ? "200" : "250"}
              color="#2B2A29"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </motion.div>
        ) : (
          <>
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                mt: 2,
                fontWeight: "bold",
                fontSize: isMobile ? "1.2rem" : "1.4rem",
              }}
            >
              Select a date from the calendar below to begin your Ayurvedic healing journey with a
              consultation
            </Typography>
            <ThemeProvider theme={theme}>
              <Grid container justifyContent="center" textAlign="center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <MobileDatePicker
                      value={date}
                      onAccept={(date) => {
                        setDate(date);
                      }}
                      onOpen={handleOpen}
                      onMonthChange={handleMonthChange}
                      shouldDisableDate={(dateParam) => {
                        return nonAvailableDates.includes(dateParam?.format("YYYY-MM-DD"));
                      }}
                      loading={loading}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </ThemeProvider>

            {appointment ? (
              <Typography variant="subtitle1" color="red" align="center">
                {appointment}
              </Typography>
            ) : (
              <Grid container spacing={2} justifyContent="center" textAlign="center">
                {timings &&
                  timings.length > 0 &&
                  timings.map((timeOption, index) => (
                    <Grid item xs={4} sm={4} md={2} key={index} sx={{ mt: 2 }}>
                      <Paper
                        elevation={12}
                        variant="elevation"
                        sx={{ border: "2px solid black", borderRadius: "6px" }}
                      >
                        <Button
                          fullWidth
                          onClick={() => handleTimeClick(timeOption)}
                          sx={{
                            borderRadius: "5px",
                            backgroundColor: time === timeOption ? "#721F4B" : "inherit",
                            color: time === timeOption ? "white" : "inherit",
                            "&:hover": {
                              backgroundColor: "#8C3C78",
                              color: "white",
                            },
                          }}
                        >
                          {timeOption}
                        </Button>
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
            )}
            {showButton && (
              <>
                <motion.div variants={variants} initial="hidden" animate="visible">
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    onClick={displayRazorpay}
                    style={{
                      background: "#2B2A29",
                      borderRadius: "40px",
                      fontSize: isMobile ? "1rem" : "1.3rem",
                      textTransform: "capitalize",
                      marginTop: 20,
                    }}
                    disabled={buttonState}
                  >
                    Proceed Payment (500 Rs)
                  </Button>
                </motion.div>

                <Link
                  component="button"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_TNC_API_URL}/termsandconditions`,
                      "_blank",
                    );
                  }}
                  sx={{
                    cursor: "pointer",
                    color: "black",
                    textDecoration: "underline",
                    fontSize: "0.7rem",
                    fontWeight: "600",
                    mt: 2,
                  }}
                >
                  *Terms & Conditions Apply
                </Link>
              </>
            )}
          </>
        )}
      </Paper>
    </motion.div>
  );
};

export default NewAppointment;
