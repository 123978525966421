/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../Header";
import { motion } from "framer-motion";
import { Box, Tooltip, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import { DNA } from "react-loader-spinner";
import Chart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import ChatBot from "../ChatBot/ChatBot";
import { setTopicCode } from "../../Redux/rootSlice";

const MentalReport = ({ isMobile }) => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const { user } = useAuth();

  const [showLoading, setShowLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [topicCodes, setTopicCodes] = useState("");
  const [topicDescription, setTopicDescription] = useState("");
  const [promptText, setPromptText] = useState("");

  const handleImgClick = () => {
    navigate("/home");
  };

  const fetchReport = async () => {
    try {
      setShowLoading(true);
      if (user && user.userId) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/report/get/mental/result/${user.userId}`,
          { withCredentials: true },
        );

        if (response && response.status === 200) {
          if (response.data && response.data.data) {
            setPromptText(response.data.data);
            const responseData = response.data.data[0]; // Assuming the response contains an array with a single object
            const {
              graph,
              report: { topicCode, topicDescription },
            } = responseData;

            setGraphData(graph);
            setTopicCodes(topicCode);
            setTopicDescription(topicDescription);

            setShowLoading(false);
          } else {
            console.error("Response data or data array is missing.");
            setShowLoading(false);
          }
        } else {
          // Handle the case where fetching report data was unsuccessful
          console.error("Failed to fetch report data.");
          setShowLoading(false);
        }
      } else {
        console.error("User ID not available.");
        setShowLoading(false);
      }
    } catch (error) {
      toast.error("Error while fetching report data");
      console.error("Error fetching report:", error);
      setShowLoading(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, [user]);

  const handleChangePage = (description) => {
    if (!description) {
      console.error("Description is undefined. Check your mapping or key.");
      return;
    }

    if (description) {
      reduxDispatch(setTopicCode(description));

      navigate("/complete-report");
    } else {
      console.error("Invalid description. No corresponding topic code.");
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/others/Report.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Header />
        </Box>
        <div onClick={handleImgClick} style={{ cursor: "pointer" }}>
          <Tooltip title="Navigate to Home Page" arrow>
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
              alt="Overlay"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: isMobile ? "110px" : "135px",
                height: isMobile ? "110px" : "135px",
                zIndex: 1,
              }}
            />
          </Tooltip>
        </div>

        {/* Prakriti Paper */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mx: 4, mb: isMobile ? 12 : 6 }}
        >
          <Paper
            elevation={3}
            style={{
              borderRadius: "20px",
              padding: isMobile ? "20px" : "40px",
              width: isMobile ? "80%" : "70%",
              height: "auto",
              marginTop: isMobile ? "45%" : "5%",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            }}
          >
            {showLoading ? (
              <motion.div style={{ textAlign: "center" }}>
                <DNA
                  visible={true}
                  height={isMobile ? "120" : "200"}
                  width={isMobile ? "200" : "250"}
                />
              </motion.div>
            ) : (
              <>
                <>
                  <>
                    {/* horizontal bar  */}
                    <Box
                      sx={{
                        textAlign: "center",
                        backgroundColor: "#721F4B",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: isMobile ? "1.2rem" : "1.5rem",
                        borderRadius: "3px",
                        marginBottom: "1rem",
                        padding: "1rem",
                        textTransform: "uppercase",
                      }}
                    >
                      {topicCodes}
                    </Box>
                    <Typography
                      sx={{
                        textAlign: "justify",
                        fontWeight: 600,
                        fontSize: isMobile ? "0.9rem" : "1rem",
                        textTransform: "uppercase",
                      }}
                      paragraph
                      dangerouslySetInnerHTML={{
                        __html: topicDescription,
                      }}
                    />
                    <Chart
                      type="bar"
                      width={isMobile ? "130%" : "80%"}
                      height={isMobile ? "300px" : "400px"}
                      series={[
                        {
                          data: graphData.map((item) => ({
                            x: item.name,
                            y: parseFloat(item.score),
                          })),
                        },
                      ]}
                      options={{
                        chart: {
                          type: "bar",
                          horizontal: true,
                          toolbar: {
                            show: false,
                          },
                        },
                        plotOptions: {
                          bar: {
                            distributed: true,
                            horizontal: true,
                          },
                        },
                        xaxis: {
                          title: {
                            text: "Scores",
                          },
                        },
                        yaxis: {
                          labels: {
                            formatter: (val) => val, // Ensure the labels are not modified
                            style: {
                              fontSize: "14px",
                            },
                          },
                          title: {
                            text: "Doshas",
                          },

                          categories: graphData.map((item) => item.name), // Map dosha names to Y-axis categories
                        },
                        title: {
                          text: "Dosha Scores",
                          align: "center",
                          style: {
                            fontSize: "20px",
                          },
                        },
                      }}
                    />

                    <Box sx={{ textAlign: "right", marginTop: "20px" }}>
                      <Typography
                        onClick={() => {
                          handleChangePage("Prasanna Atma");
                        }}
                        style={{
                          cursor: "pointer",
                          color: "black",
                          textDecoration: "underline",
                          fontSize: isMobile ? "1rem" : "1.2rem",
                          mr: 2,
                        }}
                      >
                        <Tooltip title="Prasanna Atma Recommendations" arrow>
                          More Details: Prasanna Atma Recommendations
                        </Tooltip>
                      </Typography>
                      <Typography
                        onClick={() => {
                          handleChangePage("Prasanna Manaha");
                        }}
                        style={{
                          cursor: "pointer",
                          color: "black",
                          textDecoration: "underline",
                          fontSize: isMobile ? "1rem" : "1.2rem",
                          mr: 2,
                        }}
                      >
                        <Tooltip title="Prasanna Manaha Recommendations" arrow>
                          More Details: Prasanna Manaha Recommendations
                        </Tooltip>
                      </Typography>
                      <Typography
                        onClick={() => {
                          handleChangePage("Prasanna Endriya");
                        }}
                        style={{
                          cursor: "pointer",
                          color: "black",
                          textDecoration: "underline",
                          fontSize: isMobile ? "1rem" : "1.2rem",
                          mr: 2,
                        }}
                      >
                        <Tooltip title="Prasanna Endriya Recommendations" arrow>
                          More Details: Prasanna Endriya Recommendations
                        </Tooltip>
                      </Typography>
                    </Box>
                  </>
                </>
              </>
            )}
          </Paper>
        </Grid>
      </motion.div>
      <ChatBot promptText={promptText} sessionType={"report-mental"} isMobile={isMobile} />
    </>
  );
};

export default MentalReport;
