/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import {
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
} from "@mui/material";
import axios from "axios";
import { DemoPaper } from "./DemoPaper";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";
import { useScroll } from "../../ScrollContext";
import { useAuth } from "../../AuthContext";
import ChatBot from "../ChatBot/ChatBot";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveSteps,
  setCurrentRoute,
  setQuestions,
  setSelectedAnswers,
  setNextButton,
} from "../../Redux/rootSlice";
import { useParams } from "react-router-dom";

const Questions = ({ isMobile }) => {
  const { user } = useAuth();
  const { scrollToTop } = useScroll();

  const dispatch = useDispatch();
  const { questions, selectedAnswers, testType, currentRoute, step } = useSelector((state) => state);
  const { activeSteps } = useSelector((state) => state);

  const { subCategoryId } = useParams();

  useEffect(() => {
    scrollToTop();
  }, []);

  const fetchData = async () => {
    dispatch(setQuestions([]));

    try {
      dispatch(setNextButton(true)); // Initial dispatch to set the button to true

      const { userId, role } = user;

      if (userId && role && subCategoryId) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/ayurveda-resource/get/selected/subcategory/questions/${subCategoryId}/${userId}/${testType}/${role}`,
          { withCredentials: true },
        );

        if (response && response.status === 200 && response.data && response.data.data) {
          dispatch(setQuestions(response.data.data));

          if (response.data?.result) {
            let questions = response.data.data.map((qnt) => qnt._id);
            let answers = Object.keys(response.data?.result);

            if (questions.every((qsnt) => answers.includes(qsnt))) {
              dispatch(setSelectedAnswers(response.data.result));
            }
          }
        }
      } else {
        dispatch(setQuestions(""));

        console.error("Missing required parameters:", {
          userId,
          role,
          subCategoryId,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      dispatch(setNextButton(false)); // Ensure the button is set to false after operations
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAnswerChange = (questionId, answer) => {
    const currentSelectedAnswers = { ...selectedAnswers };
    const updatedSelectedAnswers = { ...selectedAnswers, [questionId]: answer };
    dispatch(setSelectedAnswers(updatedSelectedAnswers));
    if (!Object.keys(currentSelectedAnswers).includes(questionId)) {
      let _currentRoute = [...currentRoute];
      let _currentRouteIndex = _currentRoute.findIndex(
        (route) => route.path.split("/").pop() === subCategoryId,
      );
      _currentRoute[_currentRouteIndex + 1] = {
        ..._currentRoute[_currentRouteIndex + 1],
        progress: _currentRoute[_currentRouteIndex + 1].progress + (1 / step) * 100,
      };
      dispatch(setCurrentRoute(_currentRoute));
    }
  };

  // Create a single prompt text by concatenating all the prompt texts from the list of questions
  const combinedPromptText = questions
    ? questions.map((q, i) => {
      let promt = {}
        promt.question = `${i + 1}. ${q.questiontext}\n`;
        promt.options= q.answers.map(ans=>ans.answer);
        return promt;
      })
    : '';

  console.log("combinedPromptText", combinedPromptText);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <DemoPaper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {questions?.length ? (
              questions.map((qna, index) => (
                <Box
                  key={index}
                  ml={1}
                  my={1}
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  alignItems={isMobile ? "center" : "flex-start"}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box
                    flex={1}
                    sx={{
                      my: 2,
                      mx: 2,
                      minWidth: 0, // Allow text to wrap instead of stretching the box
                    }}
                    textAlign={isMobile ? undefined : "left"}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: isMobile ? "1.2rem" : "1.5rem",
                        textTransform: "uppercase",
                      }}
                    >
                      {index + 1}.{"    "}
                      {qna.questiontext}
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label={`answers-${index}`}
                        name={`answers-${index}`}
                        value={(selectedAnswers && selectedAnswers[qna._id]) || ""}
                        onChange={(e) => handleAnswerChange(qna._id, e.target.value)}
                        sx={{ fontWeight: 600, textTransform: "uppercase" }}
                      >
                        {qna.answers.map((answer, ansIndex) => (
                          <Box
                            key={ansIndex}
                            display="flex"
                            flexDirection="column" // Display each answer option and its image in a column
                            alignItems={isMobile ? "center" : "flex-start"}
                            sx={{ marginTop: "8px" }}
                          >
                            {/* Display the answer label with the radio button */}
                            <Box display="flex" alignItems={isMobile ? "left" : "center"}>
                              <FormControlLabel
                                value={answer.answer}
                                control={<Radio />}
                                label={answer.answer}
                                sx={{
                                  width: "auto", // Ensure the width of the label adjusts according to content
                                }}
                              />
                            </Box>
                            {/* Display the answer image below the answer label */}
                            {answer.answerimage && (
                              <Box display="flex" justifyContent="center">
                                <img
                                  width={isMobile ? "70%" : "50%"}
                                  height={isMobile ? "70%" : "50%"}
                                  src={`${answer.answerimage}`}
                                  alt="answer image"
                                  loading="lazy"
                                  style={{ margin: isMobile ? 5 : undefined }}
                                />
                              </Box>
                            )}
                          </Box>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {qna.questionimage && (
                    <img
                      width={isMobile ? "50%" : "25%"}
                      height={isMobile ? "50%" : "25%"}
                      src={qna.questionimage}
                      alt="question image"
                      loading="lazy"
                    />
                  )}
                </Box>
              ))
            ) : (
              <motion.div>
                <DNA
                  visible={true}
                  height={isMobile ? "120" : "200"}
                  width={isMobile ? "200" : "250"}
                  color="#2B2A29"
                  ariaLabel="dna-loading"
                  wrapperStyle={{}}
                  wrapperClass="dna-wrapper"
                />
              </motion.div>
            )}
          </Box>
        </DemoPaper>
      </Grid>
      <ChatBot promptText={combinedPromptText} sessionType={"questions"} isMobile={isMobile} />
    </>
  );
};

export default Questions;
