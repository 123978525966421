/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  Typography,
  Link,
} from "@mui/material";
import PhonelinkLockRoundedIcon from "@mui/icons-material/PhonelinkLockRounded";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DNA } from "react-loader-spinner";
import Cookies from "js-cookie";
import AuthContext, { useAuth } from "../../AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { useScroll } from "../../ScrollContext";
import {
  SetRoutingPages,
  setActiveSteps,
  setRoutingData,
  setStep,
  setRoutingIds,
  setSelectedAnswers,
  setTestType,
  setCurrentRoute,
  setQuestions,
  resetState,
  setRoutePath,
} from "../../Redux/rootSlice";

const LoginMobileOtp = ({ state, dispatch, closeLoading, variants, isMobile }) => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const { routePath, routingData, routingPages, activeSteps, currentRoute } = useSelector(
    (state) => state,
  );
  const reduxDispatch = useDispatch();

  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleResendOTP = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/login/post/check/existing/user/request/otp`,
          {
            userDetail: {
              countryCode: state.loginMobileCode,
              mobileNumber: state.loginNumber,
            },
          },
          {
            withCredentials: true,
          },
        )
        .then((response) => {
          if (response && response.status === 200) {
            toast.success("The verification code has been sent to your mobile number");

            dispatch({ type: "set_login_otp", payload: "" });
          }

          return response;
        })
        .catch((error) => {
          // Handle error
          console.error("Error sending OTP:", error);
          toast.error("An error occurred while sending OTP. Please try again later.");
        });
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const fetchStepCount = async (type) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/ayurveda-resource/post/analysis/user/progress-bar/count/${type}`,
        { withCredentials: true },
      );

      if (response && response.status === 200 && response.data && response.data.data) {
        reduxDispatch(setRoutePath(""));

        const result = response.data.data;
        reduxDispatch(setStep(result.step));
        reduxDispatch(setRoutingData(result?.routingPath));
        reduxDispatch(setRoutingIds(result?.routingData));
        reduxDispatch(setTestType(type));
        navigate(result?.routingPath[0].path);
        let progressPerct = {
          page: "",
          path: "",
          progress: "",
        };
        progressPerct.page = 0;
        progressPerct.path = result?.routingPath[0].path;
        progressPerct.progress = 0;
        reduxDispatch(setCurrentRoute([...currentRoute, progressPerct]));
      }
    } catch (error) {
      console.error("Error fetching step count:", error);
      toast.error("Failed to fetch step count. Please try again later.");
    }
  };

  const handleLogin = async () => {
      if (!state.loginOtp) {
        toast.error("Please enter the valid verification code sent");

        return;
      }

      if (!/^\d{6}$/.test(state.loginOtp)) {
        toast.error("Please ensure the verification code consists of 6 digits.");

        return;
      }

      closeLoading(true);

      try {
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/login/post/existing/user/otp/verification`,
            {
              userDetail: {
                countryCode: state.loginMobileCode,
                mobileNumber: state.loginNumber,
                otpCode: state.loginOtp,
              },
            },
            {
              withCredentials: true,
            },
          )
          .then((response) => {
            if (response && response.status === 200) {
              if (response.data && response.data.data) {
                login(response.data.data);

                closeLoading(false);

                toast.success("Great to see you again! You're now logged in");

                if (routePath) {
                  if (routePath === "new-appointment") {
                    navigate(`/${routePath}`);
                  } else {
                    fetchStepCount("dosha");
                  }
                } else {
                  navigate("/home");
                }
              }
            }

            return response;
          })
          .catch((error) => {
            // Handle error
            if (error && error.response && error.response.status === 400) {
              closeLoading(false);

              toast.error(
                "Verification code did not match. Please try again and consider resending",
              );
            } else {
              closeLoading(false);

              console.error("Error sending OTP:", error);
              toast.error("An error occurred while sending OTP. Please try again later.");
            }
          });
      } catch (error) {
        closeLoading(false);

        console.error("Error sending OTP:", error);
        toast.error("An error occurred while sending OTP. Please try again later.");
      }
  };

  const handlePreviousStep = () => {
    dispatch({ type: "set_component", payload: "login mobile fields" });
  };

  if (state.showLoading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item>
            <DNA
              visible={true}
              height={isMobile ? "120" : "200"}
              width={isMobile ? "200" : "250"}
              color="#2B2A29"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Grid>
        </Grid>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <FormControl>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={10} sm={6} sx={{ mt: isMobile ? 8 : undefined }}>
            <motion.div variants={variants} initial="hidden" animate="visible">
              <TextField
                placeholder="Verification Code"
                id="name"
                variant="standard"
                value={state.loginOtp}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/\D/g, "").slice(0, 6);
                  dispatch({ type: "set_login_otp", payload: newValue });
                }}
                onKeyPress={
                  isMobile
                    ? null
                    : (e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent the default action
                          handleLogin();
                        }
                      }
                }
                fullWidth
                required
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhonelinkLockRoundedIcon fontSize="large" sx={{ color: "black" }} />
                    </InputAdornment>
                  ),
                  style: { fontSize: "1.5rem", fontWeight: "600" },
                }}
                InputLabelProps={{ style: { color: "black" } }}
                sx={{
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black",
                  },
                }}
              />
            </motion.div>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              <Link
                component="button"
                onClick={handleResendOTP}
                style={{
                  cursor: "pointer",
                  color: "black",
                  textDecoration: "underline",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}
              >
                Resend Verification Code
              </Link>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              alignItems="center" // Align items vertically in the center
            >
              <motion.div variants={variants} initial="hidden" animate="visible">
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  onClick={handleLogin}
                  style={{
                    background: "#2B2A29",
                    borderRadius: "40px",
                    fontSize: isMobile ? "1rem" : "1.3rem",
                    // textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                >
                  verify code and proceed
                </Button>
              </motion.div>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: isMobile ? 2 : 5 }}>
            <Grid container justifyContent="center" alignItems="center">
              <Typography
                sx={{
                  color: "black",
                  fontSize: "1.2rem",
                  textAlign: "center",
                  mt: isMobile ? -2 : -8,
                  fontWeight: "600",
                }}
              >
                If You Believe You've Entered Incorrect Number, You Can Go Back.
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ mt: isMobile ? 1 : -2 }}
            >
              <Link
                component="button"
                onClick={handlePreviousStep}
                style={{
                  cursor: "pointer",
                  color: "black",
                  textDecoration: "underline",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}
              >
                Revisit previous step !!!
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </motion.div>
  );
};

export default LoginMobileOtp;
