/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { TextField, Typography, CardContent } from "@mui/material";
import { useScroll } from "../../ScrollContext";

const MedicalHistory = ({ isMobile, state, dispatch }) => {
  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleChange = (field, value) => {
    dispatch({ type: field, payload: value });
  };

  const memoizedHandleChange = useMemo(() => handleChange, [dispatch]);

  return (
    <CardContent>
      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.7rem" : "2rem",
          textAlign: "center",
          mb: 3,
        }}
      >
        Medical History
      </Typography>

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        1. Past History of Any Disease, Condition, etc.
      </Typography>
      <TextField
        id="pastHistory"
        placeholder="Past History of Any Disease, Condition, etc."
        variant="outlined"
        fullWidth
        margin="normal"
        multiline
        value={state.pastHistory}
        onChange={(e) => memoizedHandleChange("set_past_history", e.target.value)}
        autoComplete="off"
        rows={3}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
      />

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        2. Past Surgeries or Hospitalization
      </Typography>
      <TextField
        id="pastSurgeries"
        placeholder="Past Surgeries or Hospitalization"
        variant="outlined"
        fullWidth
        margin="normal"
        multiline
        value={state.pastSurgeries}
        onChange={(e) => memoizedHandleChange("set_surgeries", e.target.value)}
        autoComplete="off"
        rows={3}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
      />

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        3. Chronic Illness or Condition
      </Typography>
      <TextField
        id="chronicIllness"
        placeholder="Chronic Illness or Condition"
        variant="outlined"
        fullWidth
        margin="normal"
        multiline
        value={state.chronicIllness}
        onChange={(e) => memoizedHandleChange("set_illness", e.target.value)}
        autoComplete="off"
        rows={3}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
      />

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        4. List of Medical Conditions and Supplements
      </Typography>
      <TextField
        id="medicalConditions"
        placeholder="List of Medical Conditions and Supplements"
        variant="outlined"
        fullWidth
        margin="normal"
        multiline
        value={state.medicalConditions}
        onChange={(e) => memoizedHandleChange("set_medical_conditions", e.target.value)}
        autoComplete="off"
        rows={3}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
      />

      <Typography
        sx={{
          color: "black",
          fontSize: isMobile ? "1.5rem" : "1.7rem",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        5. Allergies (Including Food and Drug)
      </Typography>
      <TextField
        id="allergies"
        placeholder="Allergies (Including Food and Drug)"
        variant="outlined"
        fullWidth
        margin="normal"
        multiline
        value={state.allergies}
        onChange={(e) => memoizedHandleChange("set_allergies", e.target.value)}
        autoComplete="off"
        rows={3}
        inputProps={{ style: { fontSize: isMobile ? "1.4rem" : "1.5rem" } }}
        sx={{
          mb: 4,
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "black",
            "& fieldset": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiInputBase-input::placeholder": {
            textAlign: isMobile ? "center" : "left",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
        }}
      />
    </CardContent>
  );
};

export default MedicalHistory;
