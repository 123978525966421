/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Box, Tooltip, Typography, Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import { DNA } from "react-loader-spinner";
import Header from "../Header";
import ChatBot from "../ChatBot/ChatBot";

const DoshaReport = ({ isMobile }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [prakritiData, setPrakritiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [promptText, setPromptText] = useState("");

  const fetchReport = async () => {
    try {
      setLoading(true);

      if (user && user.userId) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/report/get/dosha/result/${user.userId}`,
          { withCredentials: true },
        );

        if (response && response.status === 200 && response.data.success) {
          const data = response.data.data;

          const prakriti = data.filter(
            (item) =>
              item.topicDescription && item.topicDescription.topicHeading.includes("Prakriti"),
          );

          if (prakriti.length > 0) {
            setPrakritiData(prakriti);
            let updatePrompt = {};
            updatePrompt.heading = prakriti[0]?.topicDescription?.topicHeading;
            updatePrompt.description = prakriti[0]?.topicDescription?.topicDescription;
            setPromptText(updatePrompt);
          } else {
            toast.error("Prakriti data not found.");
          }
        } else {
          toast.error("Failed to fetch report data.");
        }
      } else {
        toast.error("User ID not available.");
      }
    } catch (error) {
      toast.error("Error while fetching report data.");
      console.error("Error fetching report:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchReport();
    }
  }, [user]);

  const handleImgClick = () => {
    navigate("/home");
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/others/Report.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Header />
        </Box>
        <Tooltip title="Navigate to Home Page" arrow>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
            alt="Company Logo"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: isMobile ? "110px" : "135px",
              height: isMobile ? "110px" : "135px",
              cursor: "pointer",
            }}
            onClick={handleImgClick}
          />
        </Tooltip>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mx: 4, mb: isMobile ? 12 : 6 }}
        >
          <Paper
            elevation={3}
            style={{
              borderRadius: "20px",
              padding: isMobile ? "20px" : "40px",
              width: isMobile ? "90%" : "70%",
              height: "auto",
              marginTop: isMobile ? "45%" : "5%",
              overflow: "hidden",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            }}
          >
            {loading ? (
              <motion.div style={{ textAlign: "center" }}>
                <DNA
                  visible
                  height={isMobile ? "120px" : "200px"}
                  width={isMobile ? "200px" : "250px"}
                />
              </motion.div>
            ) : (
              <>
                {prakritiData.map((data, index) => (
                  <div key={index}>
                    {data.topicDescription && (
                      <>
                        <Box
                          sx={{
                            textAlign: "center",
                            backgroundColor: "#721F4B",
                            color: "white",
                            borderRadius: "3px",
                            fontWeight: "bold",
                            fontSize: isMobile ? "1.2rem" : "1.5rem",
                            marginBottom: "1rem",
                            padding: "1rem",
                            textTransform: "uppercase",
                          }}
                        >
                          {data.topicDescription.topicHeading}
                        </Box>

                        {data.topicDescription.topicImage && (
                          <Grid container justifyContent="center" alignItems="center">
                            <img
                              width={isMobile ? "100%" : "60%"}
                              height={isMobile ? "100%" : "60%"}
                              src={`https://7dstatic.s3.ap-south-1.amazonaws.com/7dimages/${data.topicDescription.topicImage}`}
                              alt="preview"
                              loading="lazy"
                              style={{
                                marginTop: isMobile ? "8px" : "16px",
                                marginBottom: isMobile ? "8px" : "5px",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 1)",
                                border: "2px solid #ccc",
                              }}
                            />
                          </Grid>
                        )}

                        <Typography
                          fontSize={isMobile ? "0.9rem" : "1rem"}
                          textTransform="uppercase"
                          fontWeight="600"
                          dangerouslySetInnerHTML={{
                            __html: data.topicDescription.topicDescription,
                          }}
                        />
                      </>
                    )}

                    {data.signature && (
                      <Typography
                        fontSize={isMobile ? "0.9rem" : "1.1rem"}
                        fontWeight="600"
                        textTransform="uppercase"
                      >
                        Signature: {data.signature}
                      </Typography>
                    )}
                  </div>
                ))}
              </>
            )}
          </Paper>
        </Grid>
      </motion.div>
      <ChatBot promptText={promptText} sessionType={"report-dosha"} isMobile={isMobile} />
    </>
  );
};

export default DoshaReport;
